.admin-settings {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.admin-settings h1 {
  font-family: monospace;
  color: #00ff00;
  margin-bottom: 2rem;
}

.settings-section {
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.settings-section h2 {
  font-family: monospace;
  color: #00ff00;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-family: monospace;
  color: #00ff00;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  background: #1a1a1a;
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
  font-family: monospace;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-group input[type="checkbox"] {
  margin-right: 0.5rem;
}

.error-message,
.notification-message {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  font-family: monospace;
}

.error-message {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: #ff0000;
}

.notification-message {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
}

.form-actions {
  margin-top: 2rem;
  text-align: right;
}

.save-btn {
  padding: 0.5rem 1rem;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
  cursor: pointer;
  font-family: monospace;
}

.save-btn:hover {
  background: rgba(0, 255, 0, 0.2);
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #00ff00;
  font-family: monospace;
}

.price-input-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.price-input-group input {
  width: 120px;
}

.price-input-group select {
  width: 100px;
}
