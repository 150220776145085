.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(0, 255, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
}

.nav-menu {
  display: flex;
  gap: 2.5rem;
  align-items: center;
}

.nav-section {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  position: relative;
}

.nav-section:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -1.25rem;
  height: 1.5rem;
  width: 1px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 255, 0, 0.3),
    transparent
  );
}

.nav-link {
  position: relative;
  color: rgba(0, 255, 0, 0.7);
  text-decoration: none;
  font-family: 'Anonymous Pro', monospace;
  font-size: 0.95rem;
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  letter-spacing: 0.05em;
  font-weight: 500;
}

.menu-icon {
  font-family: monospace;
  font-size: 1.1rem;
  opacity: 0.8;
  transition: transform 0.3s ease;
}

.nav-link:hover {
  color: #33FF33;
}

.nav-link:hover .menu-icon {
  transform: scale(1.1);
  opacity: 1;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #00FF00;
  transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

.nav-link.active {
  color: #00FF00;
  text-shadow: 0 0 8px rgba(0, 255, 0, 0.3);
}





@media (max-width: 768px) {
  .nav-section {
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .nav-section::after {
    display: none;
  }
  
  .nav-menu {
    padding: 1rem;
  }
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #00FF00;
  font-family: 'Anonymous Pro', monospace;
  cursor: pointer;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
  
  .nav-menu {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: rgba(18, 18, 18, 0.95);
    backdrop-filter: blur(10px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-20px);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 100;
  }
  
  .nav-menu.open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0);
    padding: 2rem;
  }

  .nav-section {
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .nav-menu.open .nav-section {
    opacity: 1;
    transform: translateY(0);
  }
} 
.auth-button {
  background: transparent;
  border: none;
  color: rgba(0, 255, 0, 0.7);
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 1rem;
}

.auth-button:hover {
  color: #00ff00;
  text-shadow: 0 0 8px rgba(0, 255, 0, 0.5);
}

.admin-link {
  padding: 0.5rem 1rem;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 4px;
  color: #ff4444;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-left: 1rem;
}

.admin-link:hover {
  background: rgba(255, 0, 0, 0.2);
  border-color: rgba(255, 0, 0, 0.5);
}

.logo-image {
  height: 30px;
  width: auto;
  filter: brightness(1.2);
  transition: filter 0.2s ease;
}

.logo-image:hover {
  filter: brightness(1.5);
}

.logo-link {
  display: flex;
  align-items: center;
  padding: 0.5rem;
} 
