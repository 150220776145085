/* Импорт шрифтов */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&family=VT323&family=Fira+Code:wght@400;600&display=swap');

:root {
  /* Основная палитра */
  --terminal-bg-primary: #000D00;
  --terminal-bg-secondary: rgba(0, 17, 0, 0.95);
  --terminal-text-primary: #00FF66;
  --terminal-text-secondary: #00CC44;
  --terminal-accent-cyan: #00FFFF;
  --terminal-accent-yellow: #FFE164;
  --terminal-error: #FF3131;
  --terminal-success: #00FF66;
  --terminal-warning: #FFD700;
  
  /* Эффекты */
  --terminal-glow: 0 0 10px rgba(0, 255, 102, 0.3);
  --terminal-glow-strong: 0 0 20px rgba(0, 255, 102, 0.5);
  --terminal-border: 1px solid rgba(0, 255, 102, 0.3);
}

/* Основной контейнер */
.terminal-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: var(--terminal-bg-primary);
  display: grid;
  grid-template-columns: 1fr 600px;
  overflow: hidden;
  z-index: 1;
}

/* Добавляем CRT-изгиб */
.terminal-container::before {
  content: '';
  position: fixed;
  inset: 0;
  background: radial-gradient(
    circle at center,
    transparent 0%,
    rgba(0, 0, 0, 0.1) 90%,
    rgba(0, 0, 0, 0.2) 100%
  );
  pointer-events: none;
  z-index: 10;
}

/* Добавляем CRT-линии */
.terminal-container::after {
  content: '';
  position: fixed;
  inset: 0;
  background: linear-gradient(
    transparent 0%,
    rgba(0, 0, 0, 0.1) 50%,
    transparent 100%
  );
  background-size: 100% 4px;
  animation: scanlines 8s linear infinite;
  pointer-events: none;
  z-index: 11;
  opacity: 0.3;
}

/* Левая часть с анимацией */
.terminal-animation-area {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, 
    var(--terminal-bg-primary),
    var(--terminal-bg-secondary)
  );
  overflow: hidden;
}

/* Голографическая сфера */
.holographic-sphere {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border: 2px solid rgba(0, 255, 102, 0.3);
  border-radius: 50%;
  box-shadow: inset 0 0 50px rgba(0, 255, 102, 0.2),
              0 0 50px rgba(0, 255, 102, 0.2);
  animation: spherePulse 4s ease-in-out infinite;
}

.holographic-sphere::before,
.holographic-sphere::after {
  content: '';
  position: absolute;
  inset: -2px;
  border: 2px solid rgba(0, 255, 102, 0.3);
  border-radius: 50%;
  animation: sphereRotate 10s linear infinite;
}

.holographic-sphere::after {
  animation: sphereRotate 15s linear infinite reverse;
}

/* Правая часть - терминал */
.auth-terminal-wrapper {
  position: sticky;
  top: 0;
  right: 0;
  height: 100vh;
  background: var(--terminal-bg-secondary);
  border-left: var(--terminal-border);
  box-shadow: -10px 0 30px rgba(0, 255, 102, 0.1);
  backdrop-filter: blur(10px);
  animation: terminalSlideIn 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  animation: crtFlicker 0.15s infinite;
}

/* Фоновая сетка */
.auth-terminal-wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  background: 
    linear-gradient(rgba(0, 255, 102, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 255, 102, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
  opacity: 0.1;
  animation: gridMove 20s linear infinite;
  pointer-events: none;
}

.auth-terminal-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

/* Заголовок терминала */
.auth-terminal-header {
  padding: 20px;
  background: rgba(0, 40, 0, 0.95);
  border-bottom: var(--terminal-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

/* Контролы терминала */
.auth-terminal-controls-group {
  display: flex;
  gap: 8px;
}

.auth-terminal-control-btn {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-terminal-control-btn.close {
  background: #FF5F56;
  border: 1px solid #E0443E;
}

.auth-terminal-control-btn.minimize {
  background: #FFBD2E;
  border: 1px solid #DEA123;
}

.auth-terminal-control-btn.maximize {
  background: #27C93F;
  border: 1px solid #1AAB29;
}

.auth-terminal-control-btn::before {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  background: radial-gradient(circle at center,
    rgba(255, 255, 255, 0.8) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.auth-terminal-control-btn:hover::before {
  opacity: 0.5;
}

/* Заголовок */
.auth-terminal-title-text {
  font-family: 'Share Tech Mono', monospace;
  color: var(--terminal-text-primary);
  font-size: 16px;
  letter-spacing: 1px;
  text-shadow: var(--terminal-glow);
  position: relative;
  padding: 0 15px;
}

.auth-terminal-title-text::before,
.auth-terminal-title-text::after {
  content: '[';
  position: absolute;
  left: 0;
  color: var(--terminal-accent-cyan);
}

.auth-terminal-title-text::after {
  content: ']';
  left: auto;
  right: 0;
}

/* Статус индикатор */
.auth-terminal-status-indicator {
  padding: 4px 12px;
  background: rgba(0, 255, 102, 0.1);
  border: var(--terminal-border);
  border-radius: 4px;
  color: var(--terminal-text-primary);
  font-family: 'Fira Code', monospace;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.auth-terminal-status-indicator::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
    transparent,
    rgba(0, 255, 102, 0.2),
    transparent
  );
  animation: statusScan 2s linear infinite;
}

/* Основной контент */
.auth-terminal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 60px); /* Вычитаем высоту хедера */
  overflow: hidden; /* Важно! */
}

/* Область вывода */
.auth-terminal-output-area {
  flex: 1;
  overflow-y: auto;
  padding: 30px;
  padding-bottom: 100px; /* Добавляем отступ снизу для контента */
  margin-bottom: 80px; /* Место для кнопки */
  font-family: 'Fira Code', monospace;
}

/* Строки вывода */
.auth-terminal-line-item {
  margin: 12px 0;
  padding: 15px;
  background: rgba(0, 255, 102, 0.02);
  border-left: 3px solid transparent;
  border-radius: 2px;
  position: relative;
  animation: glitchIn 0.4s ease-out forwards;
  transform-origin: left;
}

/* Добавляем разные стили для разных типов сообщений */
.auth-terminal-line-item.system {
  border-left-color: var(--terminal-accent-cyan);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 255, 0.05),
    transparent
  );
}

.auth-terminal-line-item.success {
  border-left-color: var(--terminal-success);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 102, 0.1),
    transparent 50%
  );
  position: relative;
  overflow: hidden;
}

.auth-terminal-line-item.error {
  border-left-color: var(--terminal-error);
  background: linear-gradient(
    90deg,
    rgba(255, 49, 49, 0.05),
    transparent
  );
}

/* Добавляем декоративные элементы для строк */
.auth-terminal-line-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 50%,
    rgba(0, 255, 102, 0.02) 50%,
    rgba(0, 255, 102, 0.02) 100%
  );
  background-size: 4px 100%;
  opacity: 0.3;
  pointer-events: none;
}

/* Анимация появления строк */
@keyframes glitchIn {
  0% {
    opacity: 0;
    transform: translateX(-10px) skewX(20deg);
    clip-path: inset(0 100% 0 0);
  }
  60% {
    opacity: 1;
    transform: translateX(0) skewX(-10deg);
    clip-path: inset(0 30% 0 0);
  }
  100% {
    transform: skewX(0);
    clip-path: inset(0 0 0 0);
  }
}

.auth-terminal-prefix {
  color: var(--terminal-accent-cyan);
  margin-right: 10px;
  font-weight: bold;
}

.auth-terminal-text {
  color: var(--terminal-text-primary);
}

.auth-terminal-timestamp {
  color: var(--terminal-text-secondary);
  opacity: 0.7;
  margin-left: 10px;
  font-size: 0.9em;
}

/* Кнопки */
.auth-terminal-input-area {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px;
  background: rgba(0, 20, 0, 0.95);
  border-top: var(--terminal-border);
  backdrop-filter: blur(10px);
  z-index: 10;
}

/* Добавляем градиент-затемнение перед кнопкой */
.auth-terminal-input-area::before {
  content: '';
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(
    to bottom,
    transparent,
    var(--terminal-bg-secondary)
  );
  pointer-events: none;
}

/* Обновляем стили кнопок */
.auth-terminal-connect-btn,
.auth-button {
  --button-height: 64px;
  width: 100%;
  padding: 20px;
  background: linear-gradient(
    165deg,
    rgba(0, 40, 0, 0.95),
    rgba(0, 60, 0, 0.95)
  );
  border: 1px solid var(--terminal-text-primary);
  color: var(--terminal-text-primary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  clip-path: polygon(
    0 10px,
    10px 0,
    calc(100% - 10px) 0,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0 calc(100% - 10px)
  );
  overflow: visible;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: var(--button-height);
  text-transform: uppercase;
  z-index: 1;
}

/* Добавляем неоновый контур */
.auth-terminal-connect-btn::before,
.auth-button::before {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(
    45deg,
    var(--terminal-text-primary),
    var(--terminal-accent-cyan),
    var(--terminal-text-primary)
  );
  clip-path: inherit;
  filter: blur(1px);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Добавляем сканирующую линию */
.auth-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--terminal-accent-cyan),
    transparent
  );
  animation: scanLine 2s linear infinite;
}

/* Анимация для кнопки в состоянии connecting */
.auth-button.connecting {
  animation: pulseGlow 2s infinite;
  border-color: var(--terminal-accent-cyan);
}

.auth-button.connecting .auth-button-content {
  animation: glitchText 0.3s infinite;
}

/* Анимация для кнопки в состоянии awaiting */
.auth-button.awaiting {
  animation: breathe 2s infinite;
  border-color: var(--terminal-warning);
}

/* Новые анимации */
@keyframes scanLine {
  0% { left: -100%; }
  100% { left: 200%; }
}

@keyframes pulseGlow {
  0%, 100% {
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.4);
    transform: scale(1.02);
  }
}

@keyframes glitchText {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
}

@keyframes breathe {
  0%, 100% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.02);
    filter: brightness(1.2);
  }
}

/* Эффекты при наведении */
.auth-terminal-connect-btn:hover,
.auth-button:hover {
  transform: translateY(-2px);
  border-width: 2px;
}

.auth-terminal-connect-btn:hover::before,
.auth-button:hover::before {
  opacity: 1;
}

/* Добавляем декоративные углы */
.auth-button-corner {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid var(--terminal-text-primary);
  opacity: 0.5;
}

.corner-top-left { top: 5px; left: 5px; border-right: 0; border-bottom: 0; }
.corner-top-right { top: 5px; right: 5px; border-left: 0; border-bottom: 0; }
.corner-bottom-left { bottom: 5px; left: 5px; border-right: 0; border-top: 0; }
.corner-bottom-right { bottom: 5px; right: 5px; border-left: 0; border-top: 0; }

/* Обновляем стили иконок */
.auth-button-icon {
  font-size: 1.2em;
  position: relative;
  z-index: 2;
}

/* Контейнер для анимации смены кнопок */
.auth-button-container {
  position: relative;
  height: 70px;
  perspective: 2000px;
}

/* Анимация смены кнопок */
.auth-button-container .auth-terminal-connect-btn,
.auth-button-container .auth-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.auth-button-container .auth-button {
  transform: rotateX(180deg);
}

.auth-button-container.flipped .auth-terminal-connect-btn {
  transform: rotateX(-180deg);
}

.auth-button-container.flipped .auth-button {
  transform: rotateX(0);
}

/* Дополнительные анимации */
@keyframes iconPulse {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.2) rotate(15deg);
    filter: brightness(1.5);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

/* Анимация для активного состояния */
.auth-terminal-connect-btn:focus,
.auth-button:focus {
  outline: none;
  animation: buttonFocus 1s infinite;
}

@keyframes buttonFocus {
  0%, 100% {
    box-shadow: 0 0 10px var(--terminal-text-primary);
  }
  50% {
    box-shadow: 0 0 20px var(--terminal-accent-cyan);
  }
}

/* Добавляем подсветку текста */
.auth-terminal-connect-btn span,
.auth-button span {
  position: relative;
  z-index: 1;
  text-shadow: 0 0 5px currentColor;
}

/* Статусы */
.success {
  color: var(--terminal-success);
  position: relative;
  padding-left: 25px;
}

.error {
  color: var(--terminal-error);
  position: relative;
  padding-left: 25px;
}

.error::before {
  content: '✗';
  position: absolute;
  left: 0;
  animation: errorShake 0.5s ease-in-out;
}

.pending {
  color: var(--terminal-warning);
  position: relative;
  padding-left: 25px;
}

.pending::before {
  content: '⟳';
  position: absolute;
  left: 0;
  animation: pendingSpin 1s linear infinite;
}

/* Анимации */
@keyframes terminalSlideIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes gridMove {
  from { transform: translateY(0); }
  to { transform: translateY(20px); }
}

@keyframes spherePulse {
  0% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0.3;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.05);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0.3;
  }
}

@keyframes sphereRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes statusScan {
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
}

@keyframes statusPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
}

@keyframes errorShake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-3px) rotate(-5deg); }
  75% { transform: translateX(3px) rotate(5deg); }
}

@keyframes pendingSpin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Новые анимации */
@keyframes scanlines {
  0% { transform: translateY(0); }
  100% { transform: translateY(4px); }
}

@keyframes successGlow {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes successIconPulse {
  0%, 100% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.2);
    filter: brightness(1.5) drop-shadow(0 0 5px var(--terminal-success));
  }
}

/* Адаптивность */
@media (max-width: 1400px) {
  .terminal-container {
    grid-template-columns: 1fr 500px;
  }
}

@media (max-width: 1200px) {
  .terminal-container {
    grid-template-columns: 1fr 450px;
  }
}

@media (max-width: 1024px) {
  .terminal-container {
    grid-template-columns: 1fr;
    min-height: 100vh;
  }
  
  .terminal-animation-area {
    display: none;
  }
  
  .auth-terminal-wrapper {
    position: relative;
    width: 100%;
  }
}

/* Контейнер для текста кнопки */
.auth-button-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 15px;
  mix-blend-mode: difference;
}

/* Прогресс-бар для кнопки */
.button-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: linear-gradient(
    90deg,
    var(--terminal-text-primary),
    var(--terminal-accent-cyan),
    var(--terminal-text-primary)
  );
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  box-shadow: 0 0 10px currentColor;
}

/* Эффект пульсации при клике */
@keyframes buttonPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 102, 0.7);
  }
  70% {
    transform: scale(0.98);
    box-shadow: 0 0 0 10px rgba(0, 255, 102, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 102, 0);
  }
}

/* Эффект ряби при клике */
.button-ripple-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background: radial-gradient(
    circle,
    rgba(0, 255, 102, 0.3) 0%,
    transparent 70%
  );
  border-radius: 50%;
  animation: rippleEffect 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
}

@keyframes rippleEffect {
  0% {
    width: 0;
    height: 0;
    opacity: 0.8;
  }
  100% {
    width: 300%;
    height: 300%;
    opacity: 0;
  }
}

/* Обновляем стили для текста кнопки */
.auth-button-text {
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.auth-terminal-connect-btn:hover .auth-button-text,
.auth-button:hover .auth-button-text {
  letter-spacing: 3px;
  text-shadow: 0 0 8px currentColor;
}

/* Добавляем эффект свечения при загрузке */
.auth-terminal-connect-btn[style*="--progress"]:not([style*="--progress: 0%"]) {
  animation: buttonGlow 2s infinite;
}

@keyframes buttonGlow {
  0%, 100% {
    box-shadow: 0 0 10px rgba(0, 255, 102, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 255, 102, 0.5);
  }
}

/* Обновляем стили для разных состояний кнопки */
.auth-button.connecting {
  animation: connectingPulse 2s infinite;
  border-color: var(--terminal-accent-cyan);
  background: linear-gradient(
    45deg,
    rgba(0, 60, 0, 0.95),
    rgba(0, 40, 0, 0.95)
  );
}

.auth-button.awaiting {
  animation: awaitingPulse 3s infinite;
  border-color: var(--terminal-warning);
}

/* Эффект сканирования */
.scanning-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--terminal-accent-cyan),
    transparent
  );
  opacity: 0;
  filter: blur(1px);
}

.auth-button.connecting .scanning-effect {
  animation: scanningLine 2s ease-in-out infinite;
  opacity: 1;
}

/* Эффект подключения */
.connection-effect {
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  opacity: 0;
}

.auth-button.connecting .connection-effect {
  animation: connectionBorder 3s linear infinite;
  opacity: 1;
}

/* Кольца пульсации */
.pulse-rings {
  position: absolute;
  inset: -2px;
  border: 2px solid var(--terminal-accent-cyan);
  opacity: 0;
  pointer-events: none;
}

.auth-button.awaiting .pulse-rings::before,
.auth-button.awaiting .pulse-rings::after {
  content: '';
  position: absolute;
  inset: -2px;
  border: 2px solid var(--terminal-warning);
  animation: pulseRing 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation-delay: calc(var(--delay, 0) * 1s);
}

.auth-button.awaiting .pulse-rings::after {
  --delay: 1;
}

/* Анимации */
@keyframes connectingPulse {
  0%, 100% {
    box-shadow: 0 0 15px var(--terminal-accent-cyan);
  }
  50% {
    box-shadow: 0 0 30px var(--terminal-accent-cyan);
  }
}

@keyframes awaitingPulse {
  0%, 100% {
    box-shadow: 0 0 15px var(--terminal-warning);
  }
  50% {
    box-shadow: 0 0 30px var(--terminal-warning);
  }
}

@keyframes scanningLine {
  0% {
    transform: translateY(0) scaleX(0);
    opacity: 0;
  }
  50% {
    transform: translateY(50vh) scaleX(1);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) scaleX(0);
    opacity: 0;
  }
}

@keyframes connectionBorder {
  0% {
    border-color: var(--terminal-accent-cyan);
    clip-path: inset(0 0 100% 0);
  }
  25% {
    clip-path: inset(0 0 0 0);
  }
  50% {
    border-color: var(--terminal-warning);
  }
  75% {
    clip-path: inset(100% 0 0 0);
  }
  100% {
    border-color: var(--terminal-accent-cyan);
    clip-path: inset(0 0 100% 0);
  }
}

@keyframes pulseRing {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* Обновляем стили для текста во время подключения */
.auth-button.connecting .auth-button-text,
.auth-button.awaiting .auth-button-text {
  background: linear-gradient(
    90deg,
    var(--terminal-text-primary),
    var(--terminal-accent-cyan),
    var(--terminal-text-primary)
  );
  -webkit-background-clip: text;
  color: transparent;
  animation: textGlow 2s linear infinite;
}

@keyframes textGlow {
  0%, 100% {
    filter: drop-shadow(0 0 8px var(--terminal-accent-cyan));
  }
  50% {
    filter: drop-shadow(0 0 15px var(--terminal-accent-cyan));
  }
}

/* Анимация для кнопки отмены */
@keyframes cancelPulse {
  0%, 100% {
    box-shadow: 0 0 15px var(--terminal-error);
  }
  50% {
    box-shadow: 0 0 30px var(--terminal-error);
  }
}

/* Анимация для кнопки повтора */
@keyframes retryPulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 15px var(--terminal-text-primary);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 0 30px var(--terminal-text-primary);
  }
}

/* Обновляем стили для иконки повтора */
.auth-button.awaiting .auth-button-icon {
  animation: rotateIcon 2s linear infinite;
}

@keyframes rotateIcon {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Добавляем эффект перехода для текста */
.auth-button-text {
  transition: all 0.3s ease;
}

.auth-button.connecting:hover .connection-effect,
.auth-button.connecting:hover .scanning-effect {
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Добавляем искажение текста при успешном сообщении */
.auth-terminal-line-item.success .auth-terminal-text {
  position: relative;
  animation: textDistortion 0.1s steps(2) infinite;
  color: var(--terminal-success);
  text-shadow: 
    2px 0 2px rgba(0, 255, 102, 0.3),
    -2px 0 2px rgba(0, 255, 255, 0.3);
}

@keyframes textDistortion {
  0%, 100% { transform: translate(0); }
  50% { transform: translate(0.5px); }
}

/* Добавляем стили для контейнера карты */
.ascii-map-container {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.ascii-map-container canvas {
  display: block;
  width: 100% !important;
  height: 100% !important;
}

.ascii-map-container::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    transparent 0px,
    transparent 1px,
    rgba(0, 255, 102, 0.03) 2px,
    rgba(0, 255, 102, 0.03) 3px
  );
  pointer-events: none;
  animation: scanEffect 10s linear infinite;
}

.ascii-map-container::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    transparent 0%,
    rgba(0, 13, 0, 0.7) 100%
  );
  pointer-events: none;
}

@keyframes scanEffect {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(3px);
  }
}

.auth-button.telegram-auth {
  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
  border: 1px solid var(--terminal-text-primary);
  position: relative;
  overflow: hidden;
}

.auth-button.telegram-auth:hover {
  transform: scale(1.02);
}

.auth-button.telegram-auth .button-pulse-effect {
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    var(--terminal-text-primary) 0%,
    transparent 70%
  );
  opacity: 0;
  animation: buttonPulse 2s ease-in-out infinite;
}

@keyframes buttonPulse {
  0%, 100% { opacity: 0; }
  50% { opacity: 0.2; }
}

/* Обновляем состояния в левой анимации */
.state-ready_to_auth .ascii-art {
  color: #00ffcc;
  text-shadow: 0 0 15px #00ffcc;
} 