/* Импорт шрифтов */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&family=VT323&display=swap');

:root {
  /* Обновленная цветовая палитра */
  --guides-bg-primary: #0A1B0A;
  --guides-bg-secondary: #0F2D0F;
  --guides-bg-hover: #132D13;
  --guides-border: #00FF66;
  --guides-text-primary: #A8FFA8;
  --guides-text-secondary: #7FFF00;
  --guides-text-dim: #3D8C3D;
  --guides-heading: #00FFC8;
  --guides-accent: #00FFFF;
  --guides-warning: #FFD700;
  --guides-error: #FF3131;
  --guides-hot: #FF3131;
  --guides-private: #0080FF;
  
  /* Неоновые эффекты */
  --neon-glow: 0 0 10px var(--guides-border);
  --neon-text-glow: 0 0 5px currentColor;
  
  /* Размеры и отступы */
  --guides-spacing-xs: 4px;
  --guides-spacing-sm: 8px;
  --guides-spacing-md: 12px;
  --guides-spacing-lg: 20px;
  
  /* Анимации */
  --guides-transition: 0.3s;
  --scanline-opacity: 0.15;
}

/* Основной контейнер */
.guides-list-page {
  background: var(--guides-bg-primary);
  color: var(--guides-text-primary);
  font-family: 'Share Tech Mono', monospace;
  min-height: 100vh;
  padding: var(--guides-spacing-lg);
  position: relative;
  overflow: hidden;
}

/* Эффекты фона */
.guides-list-page::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    0deg,
    transparent 0%,
    rgba(0, 255, 102, 0.03) 50%,
    transparent 100%
  );
  background-size: 100% 4px;
  pointer-events: none;
  animation: scanlines 10s linear infinite;
}

.guides-list-page::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    transparent 0%,
    rgba(10, 27, 10, 0.4) 70%,
    rgba(10, 27, 10, 0.8) 100%
  );
  pointer-events: none;
}

/* Заголовок и системные сообщения */
.guides-list-header {
  border: 1px solid var(--guides-border);
  box-shadow: var(--neon-glow);
  padding: var(--guides-spacing-md);
  margin-bottom: var(--guides-spacing-lg);
  position: relative;
  background: var(--guides-bg-secondary);
}

.guides-list-title {
  font-family: 'VT323', monospace;
  font-size: 32px;
  margin: 0;
  padding-bottom: var(--guides-spacing-md);
  border-bottom: 1px solid var(--guides-text-primary);
  position: relative;
  display: inline-block;
  color: var(--guides-heading);
  text-shadow: var(--neon-text-glow);
  font-weight: bold;
  letter-spacing: 2px;
}

.guides-list-title::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  text-shadow: var(--neon-glow);
}

.guides-list-title::after {
  content: '_';
  animation: cursor-blink 1s step-end infinite;
}

.guides-list-title:hover {
  animation: glitch 0.3s infinite;
}

.guides-list-description {
  color: #A8FFA8;
  opacity: 0.8;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 2px;
}

@keyframes pulse {
  0% { text-shadow: 0 0 15px #00FFFF, 0 0 25px #00FFFF; }
  50% { text-shadow: 0 0 25px #00FFFF, 0 0 35px #00FFFF; }
  100% { text-shadow: 0 0 15px #00FFFF, 0 0 25px #00FFFF; }
}

/* Командная строка и поиск */
.guides-list-search-bar {
  border: 1px solid var(--guides-border);
  padding: var(--guides-spacing-md);
  margin-bottom: var(--guides-spacing-md);
  display: flex;
  align-items: center;
  background: var(--guides-bg-secondary);
  position: relative;
}

.guides-list-search-bar::before {
  content: '>';
  color: var(--guides-text-primary);
  margin-right: var(--guides-spacing-sm);
  animation: cursor-blink 1s step-end infinite;
}

.guides-list-search-input {
  background: transparent;
  border: none;
  color: var(--guides-text-primary);
  font-family: 'Share Tech Mono', monospace;
  width: 100%;
  font-size: 14px;
}

.guides-list-search-input:focus {
  outline: none;
}

/* Структура директории */
.guides-directory-path {
  color: var(--guides-text-secondary);
  margin-bottom: var(--guides-spacing-md);
  padding: var(--guides-spacing-sm) var(--guides-spacing-md);
  background: var(--guides-bg-secondary);
  border: 1px solid var(--guides-text-primary);
  font-size: 14px;
}

.guides-directory-path::before {
  content: '[DIR]';
  margin-right: var(--guides-spacing-sm);
  color: var(--guides-text-dim);
}

/* Вкладки-директории */
.guides-list-tabs {
  display: flex;
  gap: var(--guides-spacing-md);
  margin-bottom: var(--guides-spacing-lg);
  border-bottom: 1px solid var(--guides-text-primary);
  padding-bottom: var(--guides-spacing-md);
  position: relative;
}

.guides-list-tab {
  background: transparent;
  border: 1px solid var(--guides-border);
  color: var(--guides-text-primary);
  padding: var(--guides-spacing-xs) var(--guides-spacing-md);
  font-family: 'Share Tech Mono', monospace;
  cursor: pointer;
  position: relative;
  transition: all var(--guides-transition);
  font-size: 14px;
}

.guides-list-tab::before {
  content: '[DIR]';
  color: var(--guides-text-dim);
  margin-right: var(--guides-spacing-sm);
}

.guides-list-tab.active {
  background: var(--guides-border);
  color: var(--guides-bg-primary);
  box-shadow: var(--neon-glow);
}

.guides-list-tab .tab-status {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 12px;
  opacity: 0;
  transition: opacity var(--guides-transition);
}

.guides-list-tab.active .tab-status {
  opacity: 1;
  color: var(--guides-text-secondary);
}

/* Список файлов */
.guides-list {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--guides-border);
  background: var(--guides-bg-secondary);
  box-shadow: var(--neon-glow);
  width: 100%;
  overflow-x: auto;
}

.guides-list-item {
  position: relative;
  padding: 4px var(--guides-spacing-md);
  border: none;
  border-bottom: 1px solid rgba(0, 255, 102, 0.1);
  margin: 0;
  display: grid;
  grid-template-columns: 100px minmax(300px, 2fr) 100px 140px 100px 100px;
  gap: var(--guides-spacing-sm);
  align-items: center;
  transition: all var(--guides-transition);
  min-height: 48px;
}

.guides-list-item::before {
  content: attr(data-id);
  position: absolute;
  left: 2px;
  top: 2px;
  color: var(--guides-text-dim);
  font-size: 8px;
  opacity: 0.5;
}

.guides-list-item:hover::before {
  opacity: 0.8;
  color: var(--guides-text-secondary);
}

.guides-list-icon {
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 90px;
  max-width: 90px;
  align-items: flex-start;
  justify-content: center;
  padding: 6px;
}

.guides-list-icon[data-ascii] {
  white-space: pre;
  font-family: monospace;
}

.guides-list-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.guides-list-item-title {
  font-size: 25px;
  font-weight: bold;
  color: #FFE164;
  text-shadow: 0 0 10px rgba(255, 225, 100, 0.7);
  display: flex;
  align-items: center;
  gap: var(--guides-spacing-xs);
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.guides-list-item:hover .guides-list-item-title {
  color: #FFF1A0;
  text-shadow: 0 0 15px rgba(255, 241, 160, 0.8);
}

.guides-list-meta {
  color: var(--guides-text-secondary);
  font-size: 13px;
  opacity: 0.8;
}
/* Бейджи */
.guides-list-badge {
  font-size: 9px;
  padding: 1px 4px;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: var(--guides-spacing-xs);
}

.guides-list-badge.hot {
  color: var(--guides-hot);
  border: 1px solid var(--guides-hot);
  text-shadow: 0 0 5px var(--guides-hot);
}

.guides-list-badge.private {
  color: var(--guides-private);
  border: 1px solid var(--guides-private);
  text-shadow: 0 0 5px var(--guides-private);
}

.guides-list-badge.premium {
  color: var(--guides-warning);
  border: 1px solid var(--guides-warning);
  text-shadow: 0 0 5px var(--guides-warning);
}

/* Кнопка */
.guides-list-button {
  background: transparent;
  border: 1px solid var(--guides-border);
  color: var(--guides-text-primary);
  padding: 6px var(--guides-spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: all var(--guides-transition);
  white-space: nowrap;
  text-shadow: 0 0 3px var(--guides-text-primary);
  position: relative;
  overflow: hidden;
  min-width: 100px;
  text-align: center;
  letter-spacing: 1px;
}

.guides-list-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 102, 0.2),
    transparent
  );
  transition: transform 0.5s;
}

.guides-list-button:hover {
  background: var(--guides-border);
  color: var(--guides-bg-primary);
  box-shadow: 0 0 10px var(--guides-border);
}

.guides-list-button:hover::before {
  transform: translateX(200%);
}

/* Заголовок таблицы */
.guides-list-header-row {
  display: grid;
  grid-template-columns: 100px minmax(300px, 2fr) 100px 140px 100px 100px;
  gap: var(--guides-spacing-sm);
  padding: 12px var(--guides-spacing-md);
  background: var(--guides-bg-hover);
  border-bottom: 2px solid var(--guides-border);
  position: relative;
  overflow: hidden;
}

.guides-list-header-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--guides-border),
    transparent
  );
  box-shadow: 0 0 10px var(--guides-border);
}

.guides-list-header-cell {
  color: var(--guides-heading);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  text-shadow: 0 0 8px var(--guides-heading);
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: var(--guides-spacing-xs);
}

.guides-list-header-cell::after {
  content: '';
  flex-grow: 1;
  height: 1px;
  background: var(--guides-heading);
  opacity: 0.3;
  box-shadow: 0 0 5px var(--guides-heading);
}

/* Теги */
.guides-list-tags {
  display: flex;
  gap: var(--guides-spacing-xs);
  margin: 0;
  flex-wrap: nowrap;
  overflow: hidden;
}

.guides-list-tag {
  font-size: 10px;
  opacity: 0.6;
  white-space: nowrap;
}

/* Анимации */
@keyframes cursor-blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes file-appear {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scanlines {
  0% { transform: translateY(0); }
  100% { transform: translateY(100vh); }
}

@keyframes glitch {
  0% {
    transform: translate(0);
    text-shadow: none;
  }
  20% {
    transform: translate(-2px, 2px);
    text-shadow: 2px 0 var(--guides-accent), -2px 0 var(--guides-hot);
  }
  40% {
    transform: translate(2px, -2px);
    text-shadow: -2px 0 var(--guides-private), 2px 0 var(--guides-warning);
  }
  60% {
    transform: translate(-2px, 2px);
    text-shadow: 2px 0 var(--guides-warning), -2px 0 var(--guides-accent);
  }
  80% {
    transform: translate(2px, -2px);
    text-shadow: -2px 0 var(--guides-hot), 2px 0 var(--guides-private);
  }
  100% {
    transform: translate(0);
    text-shadow: none;
  }
}

/* Состояния загрузки */
.guides-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Share Tech Mono', monospace;
  color: var(--guides-text-primary);
  gap: var(--guides-spacing-md);
}

.guides-loading-progress {
  display: flex;
  gap: var(--guides-spacing-xs);
}

.guides-loading-progress span {
  animation: loading-dot 1s infinite;
}

@keyframes loading-dot {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
}

/* Сообщения об ошибках */
.guides-error {
  color: var(--guides-error);
  border: 1px solid var(--guides-error);
  padding: var(--guides-spacing-md);
  margin: var(--guides-spacing-lg);
  font-family: 'Share Tech Mono', monospace;
  background: var(--guides-bg-secondary);
}

.guides-error-details {
  margin-top: var(--guides-spacing-md);
  color: var(--guides-text-dim);
}

/* Системный лог */
.guides-system-log {
  position: fixed;
  bottom: var(--guides-spacing-lg);
  left: var(--guides-spacing-lg);
  right: var(--guides-spacing-lg);
  background: var(--guides-bg-primary);
  border: 1px solid var(--guides-border);
  padding: var(--guides-spacing-md);
  font-size: 11px;
  color: var(--guides-text-secondary);
  max-height: 80px;
  overflow-y: auto;
  z-index: 100;
}

.guides-system-log-entry {
  margin-bottom: var(--guides-spacing-xs);
}

.guides-system-log-entry::before {
  content: '[LOG]';
  color: var(--guides-text-secondary);
  margin-right: var(--guides-spacing-sm);
}

/* Адаптивность */
@media (max-width: 1024px) {
  .guides-list-header-row,
  .guides-list-item {
    grid-template-columns: 100px minmax(250px, 1fr) 100px 100px;
  }

  .guides-list-meta:nth-child(4),
  .guides-list-meta:nth-child(5),
  .guides-list-header-cell:nth-child(4),
  .guides-list-header-cell:nth-child(5) {
    display: none;
  }
}

@media (max-width: 768px) {
  .guides-list-page {
    padding: var(--guides-spacing-md);
    overflow-x: hidden;
  }

  .guides-list {
    border: none;
    background: transparent;
    box-shadow: none;
  }

  .guides-list-header-row {
    display: none;
  }

  .guides-list-item {
    display: flex;
    flex-direction: column;
    gap: var(--guides-spacing-md);
    padding: var(--guides-spacing-md);
    background: var(--guides-bg-secondary);
    border: 1px solid var(--guides-border);
    margin-bottom: var(--guides-spacing-md);
    min-height: auto;
    position: relative;
  }

  .guides-list-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--guides-spacing-md);
    min-width: auto;
    font-size: 14px;
  }

  .ascii-art {
    font-size: 13px;
  }

  .system-info {
    font-size: 12px;
  }

  .guides-list-text {
    width: 100%;
  }

  .guides-list-item-title {
    font-size: 16px;
    margin-bottom: var(--guides-spacing-xs);
  }

  .guides-list-description {
    font-size: 14px;
    margin-bottom: var(--guides-spacing-sm);
    opacity: 0.9;
    max-height: none;
    display: block;
    -webkit-line-clamp: none;
  }

  .guides-list-meta {
    font-size: 13px;
    position: static;
    margin-bottom: 2px;
    color: var(--guides-text-secondary);
    opacity: 0.9;
  }

  .guides-list-badge {
    font-size: 11px;
    padding: 2px var(--guides-spacing-sm);
    margin-left: var(--guides-spacing-sm);
  }

  .guides-list-button {
    width: 100%;
    min-height: 44px;
    font-size: 15px;
    margin-top: var(--guides-spacing-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--guides-bg-hover);
    border-width: 2px;
  }

  .guides-list-button:active {
    transform: scale(0.98);
  }

  .file-info {
    display: block;
    margin: var(--guides-spacing-xs) 0;
    font-size: 12px;
  }

  /* Улучшаем табы */
  .guides-list-tabs {
    display: flex;
    flex-direction: column;
    gap: var(--guides-spacing-sm);
    margin: var(--guides-spacing-md) 0;
    border: none;
    padding: 0;
  }

  .guides-list-tab {
    width: 100%;
    padding: var(--guides-spacing-md);
    font-size: 15px;
    text-align: left;
    min-height: 44px;
    display: flex;
    align-items: center;
    background: var(--guides-bg-secondary);
  }

  .guides-list-tab.active {
    border-width: 2px;
  }
}

@media (max-width: 480px) {
  .guides-list-page {
    padding: var(--guides-spacing-sm);
  }

  .guides-list-item {
    padding: var(--guides-spacing-sm);
    gap: var(--guides-spacing-sm);
  }

  .guides-list-item-title {
    font-size: 15px;
  }

  .guides-list-description {
    font-size: 13px;
  }

  .guides-list-meta {
    font-size: 12px;
  }

  .guides-list-badge {
    font-size: 10px;
    padding: 1px var(--guides-spacing-xs);
  }

  .guides-list-button {
    min-height: 40px;
    font-size: 14px;
  }

  .guides-list-tab {
    padding: var(--guides-spacing-sm);
    font-size: 14px;
    min-height: 40px;
  }

  .ascii-art {
    font-size: 12px;
  }

  .system-info {
    font-size: 11px;
  }
} 

/* ASCII-графика и системная информация */
.guides-list-icon {
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 90px;
  max-width: 90px;
  align-items: flex-start;
  justify-content: center;
  padding: 6px;
}

.ascii-art {
  font-family: monospace;
  white-space: pre;
  color: var(--guides-accent);
  font-size: 14px;
  line-height: 1.2;
  text-shadow: 0 0 8px var(--guides-accent);
  display: block;
  width: 100%;
  font-weight: bold;
}

.system-info {
  font-size: 11px;
  color: var(--guides-text-secondary);
  opacity: 0.9;
  white-space: nowrap;
  display: block;
  width: 100%;
  text-align: left;
  text-shadow: 0 0 5px var(--guides-text-secondary);
}

.file-info {
  margin-left: var(--guides-spacing-sm);
  color: var(--guides-text-dim);
  font-size: 11px;
  opacity: 0.7;
}

/* Улучшаем hover-эффект */
.guides-list-item:hover .ascii-art {
  color: var(--guides-text-secondary);
  text-shadow: 0 0 8px var(--guides-text-secondary);
}

.guides-list-item:hover .system-info {
  color: var(--guides-text-primary);
  opacity: 1;
}

/* Мобильные стили */
@media (max-width: 768px) {
  .guides-list-icon {
    min-width: 40px;
  }

  .ascii-art {
    font-size: 10px;
  }

  .system-info {
    font-size: 9px;
  }

  .file-info {
    display: block;
    margin-left: 0;
    margin-top: 2px;
  }
}

@media (max-width: 480px) {
  .guides-list-icon {
    min-width: 30px;
  }

  .ascii-art {
    font-size: 9px;
  }

  .system-info {
    font-size: 8px;
  }
} 

/* Стили для разных категорий */
.guides-list-item[data-category="nodes"] {
  border-left: 4px solid #00FFFF;
  background: linear-gradient(90deg, 
    rgba(0, 255, 255, 0.15) 0%,
    rgba(0, 255, 255, 0.05) 50%,
    transparent 100%
  );
}

.guides-list-item[data-category="nodes"] .guides-list-item-title {
  color: #00FFFF;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
}

.guides-list-item[data-category="nodes"] .guides-list-icon {
  color: #00FFFF;
}

.guides-list-item[data-category="nodes"]::before {
  content: '[ NODE_' attr(data-id) ' ]';
  color: rgba(0, 255, 255, 0.7);
  font-weight: bold;
}

.guides-list-item[data-category="tools"] {
  border-left: 4px solid #FFD700;
  background: linear-gradient(90deg, 
    rgba(255, 215, 0, 0.15) 0%,
    rgba(255, 215, 0, 0.05) 50%,
    transparent 100%
  );
}

.guides-list-item[data-category="tools"] .guides-list-item-title {
  color: #FFD700;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.7);
}

.guides-list-item[data-category="tools"] .guides-list-icon {
  color: #FFD700;
}

.guides-list-item[data-category="tools"]::before {
  content: '[ TOOL_' attr(data-id) ' ]';
  color: rgba(255, 215, 0, 0.7);
  font-weight: bold;
}

.guides-list-item[data-category="helpful"] {
  border-left: 4px solid #50FF50;
  background: linear-gradient(90deg, 
    rgba(80, 255, 80, 0.15) 0%,
    rgba(80, 255, 80, 0.05) 50%,
    transparent 100%
  );
}

.guides-list-item[data-category="helpful"] .guides-list-item-title {
  color: #50FF50;
  text-shadow: 0 0 10px rgba(80, 255, 80, 0.7);
}

.guides-list-item[data-category="helpful"] .guides-list-icon {
  color: #50FF50;
}

.guides-list-item[data-category="helpful"]::before {
  content: '[ HELP_' attr(data-id) ' ]';
  color: rgba(80, 255, 80, 0.7);
  font-weight: bold;
}

/* Эффекты при наведении */
.guides-list-item[data-category="nodes"]:hover {
  background: linear-gradient(90deg, 
    rgba(0, 255, 255, 0.2) 0%,
    rgba(0, 255, 255, 0.1) 50%,
    rgba(0, 255, 255, 0.05) 100%
  );
  box-shadow: 
    inset 0 0 20px rgba(0, 255, 255, 0.2),
    0 0 15px rgba(0, 255, 255, 0.3);
}

.guides-list-item[data-category="tools"]:hover {
  background: linear-gradient(90deg, 
    rgba(255, 215, 0, 0.2) 0%,
    rgba(255, 215, 0, 0.1) 50%,
    rgba(255, 215, 0, 0.05) 100%
  );
  box-shadow: 
    inset 0 0 20px rgba(255, 215, 0, 0.2),
    0 0 15px rgba(255, 215, 0, 0.3);
}

.guides-list-item[data-category="helpful"]:hover {
  background: linear-gradient(90deg, 
    rgba(80, 255, 80, 0.2) 0%,
    rgba(80, 255, 80, 0.1) 50%,
    rgba(80, 255, 80, 0.05) 100%
  );
  box-shadow: 
    inset 0 0 20px rgba(80, 255, 80, 0.2),
    0 0 15px rgba(80, 255, 80, 0.3);
} 

/* Стили для категорий */
.guides-list-item[data-category="nodes"] .guides-list-item-title {
  color: #00FFFF;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
}

.guides-list-item[data-category="nodes"] .guides-list-description {
  color: #B4F4F4;
  opacity: 0.85;
}

.guides-list-item[data-category="tools"] .guides-list-item-title {
  color: #FFD700;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.7);
}

.guides-list-item[data-category="tools"] .guides-list-description {
  color: #FFF0B3;
  opacity: 0.85;
}

.guides-list-item[data-category="helpful"] .guides-list-item-title {
  color: #50FF50;
  text-shadow: 0 0 10px rgba(80, 255, 80, 0.7);
}

.guides-list-item[data-category="helpful"] .guides-list-description {
  color: #B3FFB3;
  opacity: 0.85;
}

/* Эффекты при наведении */
.guides-list-item:hover .guides-list-description {
  opacity: 1;
} 