.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1 0 auto;
  padding-bottom: 60px; /* Высота футера */
}

.footer {
  flex-shrink: 0;
  width: 100%;
 
  padding: 1rem 0;
  position: relative;
  z-index: 100;
}
