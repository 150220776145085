.nws-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 60px auto 0;
  color: #00ff00;
}

.nws-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: 'Anonymous Pro', monospace;
}

.nws-tabs {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 255, 0, 0.2);
  margin-bottom: 2rem;
}

.nws-tab-btn {
  background: none;
  border: none;
  color: #00ff00;
  padding: 0.5rem 1rem;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.nws-tab-btn:hover {
  opacity: 1;
}

.nws-tab-btn.nws-active {
  opacity: 1;
  border-bottom: 2px solid #00ff00;
}

.nws-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.nws-item {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  transition: all 0.2s ease;
}

.nws-item:hover {
  border-color: rgba(0, 255, 0, 0.3);
  transform: translateX(5px);
}

.nws-item-content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.5rem;
  padding: 1.5rem;
  align-items: start;
}

.nws-badge {
  font-size: 1.2rem;
  opacity: 0.8;
}

.nws-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nws-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.nws-heading {
  margin: 0;
  font-size: 1.3rem;
}

.nws-date {
  font-size: 0.9rem;
  opacity: 0.8;
  white-space: nowrap;
}

.nws-description {
  color: rgba(0, 255, 0, 0.8);
  line-height: 1.6;
}

.nws-read-more {
  color: #00ff00;
  text-decoration: none;
  font-family: 'Anonymous Pro', monospace;
  opacity: 0.8;
  transition: all 0.2s ease;
}

.nws-read-more:hover {
  opacity: 1;
}

.nws-private {
  border-left: 3px solid #00ff00;
}

.nws-public {
  border-left: 3px solid rgba(0, 255, 0, 0.3);
}

.nws-cursor {
  animation: nws-blink 1s infinite;
}

@keyframes nws-blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes scanline {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

.private-badge {
  color: #00ff00;
  animation: pulse 2s infinite;
}
