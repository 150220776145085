.nv-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  background: #000000;
}

.nv-terminal {
  width: 100%;
  max-width: 1200px;
  background: rgba(0, 20, 0, 0.95);
  border: 1px solid rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1);
  border-radius: 8px;
}

.nv-terminal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(0, 20, 0, 0.98);
  border-bottom: 2px solid rgba(0, 255, 0, 0.2);
  position: relative;
}

.nv-terminal-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #00ff00;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.nv-terminal-controls {
  display: flex;
  gap: 8px;
  z-index: 2;
}

.nv-control {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid rgba(0, 255, 0, 0.3);
  background: transparent;
  transition: background-color 0.2s ease;
}

.nv-control:hover {
  background: rgba(0, 255, 0, 0.2);
}

.nv-terminal-content {
  padding: 2rem;
  color: #00ff00;
  background: rgba(0, 0, 0, 0.3);
}

.nv-page {
  max-width: 900px;
  margin: 2rem auto;
  font-family: 'Anonymous Pro', monospace;
}

.nv-page.nv-terminal {
  background: #121212;
  border: 1px solid #00FF00;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1);
}

.nv-content {
  padding: 2rem;
  color: #d4d4d4;
  width: 100%;
}

.nv-article {
  position: relative;
  padding: 1rem;
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.2);
}

.nv-back-link {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid #00FF00;
  color: #00FF00;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.nv-back-link:hover {
  background: rgba(0, 255, 0, 0.2);
  transform: translateX(-5px);
}

.nv-back-link:active {
  background: rgba(0, 255, 0, 0.3);
  transform: translateX(-2px);
}

.nv-typing-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0;
  color: #00FF00;
  position: relative;
  animation: scanline 1s ease-in-out;
}

.nv-cursor {
  animation: blink 1s infinite;
}

.nv-meta {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  opacity: 0.8;
}

.nv-short-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #333;
}

.nv-code-block {
  background: #1C1C1C;
  padding: 1rem;
  margin: 1rem 0;
  border-left: 3px solid #00FF00;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.1);
}

.nv-code-header {
  margin-bottom: 1rem;
  color: #00FF00;
  font-weight: bold;
}

.nv-footer {
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nv-unlock-link {
  color: #00FF00;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.nv-unlock-link:hover {
  opacity: 1;
}

@keyframes scanline {
  0% { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.markdown-content {
  color: #d4d4d4;
  margin: 2rem 0;
  position: relative;
  word-wrap: break-word;
  counter-reset: top-level;
}

.markdown-content h1 {
  font-size: 2.5rem;
  color: #00ff00;
  margin: 2rem 0 1.5rem;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
}

.markdown-content h2 {
  font-size: 2rem;
  color: #00dd00;
  margin: 2rem 0 1.2rem;
}

.markdown-content h3 {
  font-size: 1.7rem;
  color: #00bb00;
  margin: 1.8rem 0 1rem;
}

.markdown-content h4 {
  font-size: 1.4rem;
  color: #009900;
  margin: 1.5rem 0 1rem;
}

.markdown-content p {
  color: #d4d4d4;
  margin-bottom: 1.2rem;
  line-height: 1.6;
  font-size: 1.1rem;
}

.markdown-content ul, 
.markdown-content ol {
  margin: 1rem 0 1.5rem 2rem;
  color: #d4d4d4;
}

.markdown-content li {
  margin-bottom: 0.8rem;
  line-height: 1.5;
  position: relative;
}

.markdown-content ul li::before {
  content: '>';
  color: #00ff00;
  position: absolute;
  left: -1.2rem;
}

.markdown-content ol {
  list-style: none;
  padding-left: 2.5rem;
}

.markdown-content ol > li {
  counter-increment: top-level;
  position: relative;
  margin-bottom: 0.8rem;
}

.markdown-content ol > li::before {
  content: '[' counter(top-level) ']';
  color: #00ff00;
  position: absolute;
  left: -2.5rem;
  font-family: 'Anonymous Pro', monospace;
}

/* Для вложенных списков */
.markdown-content ol ol {
  counter-reset: nested;
}

.markdown-content ol ol > li {
  counter-increment: nested;
}

.markdown-content ol ol > li::before {
  content: '[' counter(top-level) '.' counter(nested) ']';
}

/* Сохраняем счетчик даже при прерывании текстом */
.markdown-content ol[start] > li::before {
  content: '[' counter(top-level) ']';
}

.markdown-content code {
  background: rgba(0, 255, 0, 0.1);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  color: #00ff00;
  font-size: 0.9em;
}

.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
  background: rgba(0, 0, 0, 0.2);
}

.markdown-content th,
.markdown-content td {
  border: 1px solid rgba(0, 255, 0, 0.2);
  padding: 0.8rem;
  text-align: left;
}

.markdown-content th {
  background: rgba(0, 255, 0, 0.1);
  color: #00ff00;
}

.markdown-content hr {
  border: none;
  border-top: 1px solid rgba(0, 255, 0, 0.2);
  margin: 2rem 0;
}

.markdown-content strong {
  color: #00ff00;
  font-weight: bold;
}

.markdown-content em {
  color: #d4d4d4;
  font-style: italic;
}

.nv-article {
  color: #d4d4d4;
  line-height: 1.6;
  max-width: 100%;
}

.nv-header {
  margin-bottom: 2rem;
}

.nv-typing-title {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  color: #d4d4d4;
  margin: 1rem 0;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
  font-family: 'Courier New', monospace;
  word-wrap: break-word;
}

.nv-cursor {
  animation: blink 1s infinite;
  opacity: 1;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.nv-meta {
  display: flex;
  gap: 1rem;
  color: #666;
  font-family: 'Courier New', monospace;
}

.markdown-content {
  margin: 2rem 0;
  position: relative;
  word-wrap: break-word;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.markdown-content::-webkit-scrollbar {
  display: none;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3 {
  color: #00ff00;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.3);
}

.markdown-content p {
  margin: 1rem 0;
  line-height: 1.8;
}

.markdown-content code {
  background: rgba(0, 255, 0, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: 'Fira Code', monospace;
  color: #00ff00;
}

.markdown-content pre {
  background: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 255, 0, 0.2);
  overflow-x: auto;
  max-width: 100%;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 255, 0, 0.3) transparent;
}

.markdown-content pre::before {
  content: '[>] Code_';
  position: absolute;
  top: -12px;
  left: 10px;
  background: rgba(0, 12, 3, 0.95);
  padding: 0 8px;
  color: #00ff00;
  font-size: 0.9rem;
}

.markdown-content blockquote {
  border-left: 3px solid #00ff00;
  padding-left: 1rem;
  margin: 1rem 0;
  color: #808080;
  font-style: italic;
  background: rgba(0, 255, 0, 0.05);
  padding: 1rem;
}

.markdown-content a {
  color: #00ff00;
  text-decoration: none;
  border-bottom: 1px dashed rgba(0, 255, 0, 0.3);
  transition: all 0.2s ease;
}

.markdown-content a:hover {
  border-bottom-style: solid;
  text-shadow: 0 0 8px rgba(0, 255, 0, 0.5);
}

.markdown-content img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid rgba(0, 255, 0, 0.2);
}

.nv-pinned-badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: rgba(255, 189, 46, 0.1);
  color: #ffbd2e;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.nv-back-link {
  display: inline-block;
  color: #00ff00;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
  margin: 1rem 0;
  transition: all 0.2s ease;
}

.nv-back-link:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: #00ff00;
}

.nv-unlock-link {
  display: inline-block;
  color: #ffbd2e;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 189, 46, 0.3);
  border-radius: 4px;
  margin: 1rem 0;
  margin-left: 1rem;
  transition: all 0.2s ease;
}

.nv-unlock-link:hover {
  background: rgba(255, 189, 46, 0.1);
  border-color: #ffbd2e;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
  .terminal-container {
    padding: 0.5rem;
  }
  
  .terminal-content {
    padding: 0.5rem;
  }
  
  .nv-meta {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .nv-footer {
    flex-direction: column;
    align-items: stretch;
  }
  
  .nv-back-link,
  .nv-unlock-link {
    margin: 0.5rem 0;
    text-align: center;
  }
} 