.admin-guides-page {
  min-height: 100vh;
  background: #121212;
  color: #00ff00;
  padding: 80px 2rem 2rem;
  font-family: 'Anonymous Pro', monospace;
}

.admin-guides-header {
  max-width: 1200px;
  margin: 0 auto 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-guides-title {
  font-size: 2rem;
  color: #00ff00;
  margin: 0;
}

.admin-create-guide-btn {
  padding: 0.8rem 1.5rem;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
  color: #00ff00;
  text-decoration: none;
  transition: all 0.3s ease;
}

.admin-create-guide-btn:hover {
  background: rgba(0, 255, 0, 0.2);
  border-color: rgba(0, 255, 0, 0.5);
}

.admin-guides-list {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}

.admin-guide-item {
  background: rgba(0, 20, 0, 0.2);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.admin-guide-info h3 {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  color: #00ff00;
}

.admin-guide-meta {
  display: flex;
  gap: 1rem;
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.9rem;
}

.admin-guide-actions {
  display: flex;
  gap: 1rem;
}

.admin-edit-btn,
.admin-delete-btn {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
  background: transparent;
  color: #00ff00;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  text-decoration: none;
  transition: all 0.3s ease;
}

.admin-delete-btn {
  color: #ff4444;
  border-color: rgba(255, 68, 68, 0.3);
}

.admin-edit-btn:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
}

.admin-delete-btn:hover {
  background: rgba(255, 68, 68, 0.1);
  border-color: rgba(255, 68, 68, 0.5);
}

.admin-guides-loading,
.admin-guides-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  color: #00ff00;
  font-size: 1.2rem;
}

.admin-guides-error {
  color: #ff4444;
}

@media (max-width: 768px) {
  .admin-guides-page {
    padding: 60px 1rem 1rem;
  }

  .admin-guides-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .admin-guide-item {
    flex-direction: column;
    gap: 1rem;
  }

  .admin-guide-actions {
    width: 100%;
    justify-content: stretch;
  }

  .admin-edit-btn,
  .admin-delete-btn {
    flex: 1;
    text-align: center;
  }
}
