.footer {
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 2rem 0;
  font-family: 'Anonymous Pro', monospace;
  margin-top: auto;
}

.footer-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-logo {
  color: #00ff00;
  font-size: 1.2rem;
  font-weight: bold;
}

.footer-copyright {
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.9rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-link {
  color: rgba(0, 255, 0, 0.7);
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.footer-link:hover {
  color: #00ff00;
  text-shadow: 0 0 8px rgba(0, 255, 0, 0.5);
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
  
  .footer-links {
    justify-content: center;
  }
}


.footer-social {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .footer-social-link {
    color: rgba(0, 255, 0, 0.7);
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .footer-social-link:hover {
    color: #00ff00;
    text-shadow: 0 0 8px rgba(0, 255, 0, 0.5);
    transform: translateX(5px);
  }
  
  .social-icon {
    font-family: monospace;
    opacity: 0.8;
  }