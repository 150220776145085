/* Импорт шрифтов */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&family=VT323&family=Press+Start+2P&display=swap');

:root {
  /* Основная палитра */
  --about-bg-primary: #0A1B0A;
  --about-bg-secondary: #0F2D0F;
  --about-text-primary: #A8FFA8;
  --about-text-secondary: #7FFF00;
  --about-text-dim: #3D8C3D;
  --about-accent: #00FFFF;
  --about-warning: #FFD700;
  --about-error: #FF3131;
  
  /* Эффекты */
  --about-neon-glow: 0 0 10px var(--about-text-primary);
  --about-text-glow: 0 0 5px currentColor;
  
  /* Размеры */
  --about-spacing-xs: 4px;
  --about-spacing-sm: 8px;
  --about-spacing-md: 16px;
  --about-spacing-lg: 24px;
}

/* Обновляем все базовые классы с префиксом xnabout */
.xnabout-container {
  background: var(--about-bg-primary);
  min-height: 100vh;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
}

.xnabout-container::before {
  content: '';
  position: fixed;
  inset: 0;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 2px,
    rgba(0, 255, 102, 0.03) 3px,
    rgba(0, 255, 102, 0.03) 4px
  );
  pointer-events: none;
  animation: scanlines 60s linear infinite;
}

.xnabout-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.xnabout-header {
  position: relative;
  padding: 3rem;
  margin-bottom: 4rem;
  border: 1px solid var(--about-text-primary);
  background: rgba(10, 27, 10, 0.8);
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
  transform-style: preserve-3d;
  perspective: 1000px;
  isolation: isolate;
}

.xnabout-header::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 1px solid var(--about-text-primary);
  opacity: 0.3;
  clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 98% 98%, 96% 100%, 2% 100%, 0 98%);
}

.xnabout-header::after {
  content: '';
  position: absolute;
  inset: -4px;
  border: 1px solid var(--about-accent);
  opacity: 0.2;
  clip-path: polygon(0 4%, 4% 0, 96% 0, 100% 4%, 96% 96%, 92% 100%, 4% 100%, 0 96%);
  animation: borderPulse 3s infinite;
}

.xnabout-header-matrix {
  position: absolute;
  inset: 0;
  background: 
    linear-gradient(90deg, 
      rgba(0, 255, 102, 0.03) 1px, 
      transparent 1px
    ),
    linear-gradient(
      rgba(0, 255, 102, 0.03) 1px,
      transparent 1px
    );
  background-size: 20px 20px;
  opacity: 0.2;
  z-index: -1;
}

.xnabout-title {
  font-family: 'Press Start 2P', monospace;
  font-size: 3rem;
  color: var(--about-text-primary);
  margin-bottom: 1rem;
  text-shadow: var(--about-neon-glow);
  animation: glitchText 5s infinite;
}

.xnabout-subtitle {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  color: var(--about-text-secondary);
  margin-top: 1rem;
  padding-left: 2rem;
  border-left: 2px solid var(--about-text-primary);
}

.xnabout-text-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 4rem 0;
}

.xnabout-text {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--about-text-primary);
  padding: 1.2rem 1.5rem;
  background: rgba(10, 27, 10, 0.6);
  border: 1px solid transparent;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
  position: relative;
}

.xnabout-text.visible {
  opacity: 1;
  transform: translateY(0);
}

.xnabout-text::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 102, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xnabout-text:hover::before {
  transform: translateX(100%);
}

/* Команда */
.xnabout-team-section {
  margin: 6rem 0;
}

.xnabout-team-title {
  font-family: 'Press Start 2P', monospace;
  font-size: 2rem;
  color: var(--about-text-primary);
  margin-bottom: 3rem;
  text-shadow: var(--about-neon-glow);
}

.xnabout-team-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
}

.xnabout-team-member {
  padding: 2rem;
  background: var(--about-bg-secondary);
  border: 1px solid var(--about-text-primary);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
  backdrop-filter: blur(5px);
}

.xnabout-team-member::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(0, 255, 102, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xnabout-team-member:hover::before {
  transform: translateX(100%);
}

.xnabout-member-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.xnabout-member-symbol {
  font-size: 2rem;
  color: var(--about-accent);
  animation: pulse 2s infinite;
}

.xnabout-member-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.xnabout-member-name {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.4rem;
  color: var(--about-text-primary);
}

.xnabout-member-role {
  font-family: 'VT323', monospace;
  font-size: 1.1rem;
  color: var(--about-text-secondary);
}

.xnabout-member-description {
  font-family: 'Share Tech Mono', monospace;
  line-height: 1.6;
  color: var(--about-text-primary);
  margin-bottom: 2rem;
  padding-left: 1rem;
  border-left: 2px solid var(--about-text-secondary);
}

.xnabout-member-command {
  font-family: 'Share Tech Mono', monospace;
  color: var(--about-accent);
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid var(--about-accent);
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  overflow: hidden;
}

.xnabout-command-prompt {
  color: var(--about-text-secondary);
  margin-right: 0.5rem;
}

.xnabout-command-cursor {
  display: inline-block;
  width: 8px;
  height: 15px;
  background: var(--about-accent);
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

/* Добавляем подсветку для выделенных слов */
.highlight {
  color: var(--about-accent);
  position: relative;
  cursor: help;
}

.highlight::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  background: var(--about-bg-secondary);
  border: 1px solid var(--about-accent);
  color: var(--about-accent);
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  pointer-events: none;
}

.highlight:hover::after {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-5px);
}

/* Добавляем статус */
.xnabout-member-status {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
  color: var(--about-accent);
  padding: 0.4rem 0.8rem;
  border: 1px solid var(--about-accent);
  background: rgba(0, 255, 255, 0.1);
}

.xnabout-member-status::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--about-accent);
  border-radius: 50%;
  margin-right: 0.5rem;
  animation: statusBlink 2s infinite;
}

/* Анимации */
@keyframes scanlines {
  0% { background-position: 0 0; }
  100% { background-position: 0 100%; }
}

@keyframes pulse {
  0%, 100% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(0.95); }
}

@keyframes glitchText {
  0%, 100% { transform: none; opacity: 1; }
  7% { transform: skew(-0.5deg, -0.9deg); opacity: 0.75; }
  10% { transform: none; opacity: 1; }
  27% { transform: none; opacity: 1; }
  30% { transform: skew(0.8deg, -0.1deg); opacity: 0.75; }
  35% { transform: none; opacity: 1; }
}

@keyframes borderPulse {
  0%, 100% { opacity: 0.2; }
  50% { opacity: 0.4; }
}

@keyframes statusBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

@keyframes blink {
  0%, 50% { opacity: 1; }
  51%, 100% { opacity: 0; }
}

@keyframes scan {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(100vh); }
}

/* Обновляем медиа-запросы */
@media (max-width: 768px) {
  .xnabout-title {
    font-size: 2rem;
  }

  .xnabout-team-list {
    grid-template-columns: 1fr;
  }

  .xnabout-header {
    padding: 2rem;
  }

  .xnabout-header-matrix {
    background-size: 15px 15px;
  }
  
  .xnabout-team-member {
    padding: 1.5rem;
  }
  
  .xnabout-member-status {
    position: relative;
    top: 0;
    right: 0;
    display: inline-block;
    margin-bottom: 1rem;
  }
}

/* Добавляем новые стили для миссии */
.xnabout-mission-section {
  margin: 6rem 0;
  position: relative;
  background: rgba(10, 27, 10, 0.6);
  padding: 3rem;
  border: 1px solid var(--about-text-primary);
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.xnabout-mission-points {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.xnabout-mission-point {
  padding: 2rem;
  background: rgba(0, 255, 102, 0.05);
  border: 1px solid var(--about-text-secondary);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.xnabout-mission-point::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, transparent, rgba(0, 255, 102, 0.1), transparent);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xnabout-mission-point:hover::before {
  transform: translateX(100%);
}

.xnabout-mission-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: block;
  filter: drop-shadow(0 0 10px var(--about-accent));
}

.xnabout-mission-title {
  font-family: 'Press Start 2P', monospace;
  font-size: 1.2rem;
  color: var(--about-text-primary);
  margin-bottom: 1rem;
}

.xnabout-mission-description {
  font-family: 'Share Tech Mono', monospace;
  color: var(--about-text-secondary);
  line-height: 1.6;
}

/* Улучшаем карточки команды */
.xnabout-member-specialties {
  margin: 2rem 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border-left: 2px solid var(--about-accent);
}

.xnabout-member-specialties h4 {
  font-family: 'Share Tech Mono', monospace;
  color: var(--about-accent);
  margin-bottom: 1rem;
}

.xnabout-member-specialties ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.xnabout-member-specialties li {
  padding: 0.5rem 1rem;
  background: rgba(0, 255, 255, 0.1);
  border: 1px solid var(--about-accent);
  color: var(--about-text-primary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.xnabout-member-specialties li:hover {
  transform: translateY(-2px);
  background: rgba(0, 255, 255, 0.2);
}

/* Статистика */
.xnabout-member-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0;
}

.xnabout-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid var(--about-text-secondary);
  transition: all 0.3s ease;
}

.xnabout-stat-item:hover {
  transform: translateY(-2px);
  border-color: var(--about-accent);
}

.xnabout-stat-value {
  font-family: 'Press Start 2P', monospace;
  font-size: 1.2rem;
  color: var(--about-accent);
  margin-bottom: 0.5rem;
}

.xnabout-stat-label {
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
  color: var(--about-text-secondary);
  text-transform: uppercase;
}

/* Достижения */
.xnabout-member-achievements {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0;
}

.xnabout-achievement-badge {
  padding: 0.5rem 1rem;
  background: rgba(0, 255, 102, 0.1);
  border: 1px solid var(--about-text-primary);
  color: var(--about-text-primary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.xnabout-achievement-badge::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, transparent, rgba(0, 255, 102, 0.2), transparent);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xnabout-achievement-badge:hover {
  transform: translateY(-2px);
  border-color: var(--about-accent);
}

.xnabout-achievement-badge:hover::before {
  transform: translateX(100%);
}

/* Футер */
.xnabout-footer {
  margin-top: 6rem;
  padding: 4rem 2rem;
  background: rgba(10, 27, 10, 0.8);
  border: 1px solid var(--about-text-primary);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.xnabout-footer::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, transparent, rgba(0, 255, 102, 0.05), transparent);
  animation: footerGlow 10s linear infinite;
}

.xnabout-footer-text {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  color: var(--about-text-primary);
  max-width: 800px;
  margin: 2rem auto;
  line-height: 1.8;
}

.xnabout-contact-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.xnabout-contact-btn {
  padding: 1rem 2rem;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.xnabout-contact-btn.primary {
  background: var(--about-accent);
  color: var(--about-bg-primary);
}

.xnabout-contact-btn.secondary {
  background: transparent;
  border: 1px solid var(--about-accent);
  color: var(--about-accent);
}

.xnabout-contact-btn::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xnabout-contact-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px var(--about-accent);
}

.xnabout-contact-btn:hover::before {
  transform: translateX(100%);
}

/* Дополнительные анимации */
@keyframes footerGlow {
  0%, 100% { transform: translateX(-100%); }
  50% { transform: translateX(100%); }
}

/* Адаптивность */
@media (max-width: 768px) {
  .xnabout-mission-points {
    grid-template-columns: 1fr;
  }

  .xnabout-member-stats {
    grid-template-columns: 1fr;
  }

  .xnabout-contact-buttons {
    flex-direction: column;
    gap: 1rem;
  }
}

/* Добавляем стили для section-title */
.xnabout-section-title {
  font-family: 'Press Start 2P', monospace;
  font-size: 2rem;
  color: var(--about-text-primary);
  margin-bottom: 2rem;
  text-shadow: var(--about-neon-glow);
  position: relative;
  display: inline-block;
}

.xnabout-section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 2px;
  background: var(--about-accent);
  transform: scaleX(0.7);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.xnabout-section-title:hover::after {
  transform: scaleX(1);
}

/* Добавляем стиль для cursor */
.cursor {
  display: inline-block;
  color: var(--about-accent);
  animation: blink 1s step-end infinite;
  margin-left: 0.5rem;
}

/* Добавляем стиль для scan-effect */
.scan-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--about-accent),
    transparent
  );
  animation: scan 3s linear infinite;
  opacity: 0.5;
  z-index: 2;
}

/* Добавляем стиль для xnabout-section */
.xnabout-section {
  opacity: 0;
  transform: translateY(20px);
  animation: sectionAppear 0.5s ease forwards;
}

@keyframes sectionAppear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 