.subscription-status {
  padding: var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
}

.status-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  position: relative;
}

.status-label {
  color: var(--text-secondary);
  font-size: 14px;
  min-width: 120px;
}

/* Стили для значений статуса */
.xnd-status-value {
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 2px;
  min-width: 100px;
  text-align: center;
  border: 1px solid var(--text-primary);
  background: rgba(13, 27, 13, 0.5);
}

.xnd-status-value.active {
  color: var(--active-green);
  border-color: var(--active-green);
  text-shadow: 0 0 5px var(--active-green);
}

.xnd-status-value.warning {
  color: var(--warning-yellow);
  border-color: var(--warning-yellow);
  text-shadow: 0 0 5px var(--warning-yellow);
}

.xnd-status-value.inactive {
  color: var(--error-red);
  border-color: var(--error-red);
  text-shadow: 0 0 5px var(--error-red);
}

/* Блок продления подписки */
.xnd-extend-subscription {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  border: 1px solid var(--text-primary);
  background: rgba(33, 255, 51, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-md);
}

.xnd-extend-message {
  color: var(--text-secondary);
  font-size: 14px;
  flex-grow: 1;
  font-family: 'Share Tech Mono', monospace;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-left: 2px solid var(--text-primary);
  background: rgba(13, 27, 13, 0.5);
  text-shadow: 0 0 5px rgba(192, 255, 62, 0.3);
  position: relative;
}

.xnd-extend-message::before {
  content: '[i]';
  color: var(--text-primary);
  margin-right: var(--spacing-sm);
  text-shadow: 0 0 5px var(--text-primary);
}

.xnd-extend-message.warning {
  color: var(--warning-yellow);
  border-left-color: var(--warning-yellow);
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.3);
}

/* Кнопка продления */
.xnd-renewal-button {
  background: transparent;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  align-self: flex-end;
  min-width: 200px;
  text-align: center;
}

.xnd-renewal-button.active {
  border-color: var(--active-green);
  color: var(--active-green);
  text-shadow: 0 0 5px var(--active-green);
}

.xnd-renewal-button.warning {
  border-color: var(--warning-yellow);
  color: var(--warning-yellow);
  text-shadow: 0 0 5px var(--warning-yellow);
  animation: pulse 2s infinite;
}

.xnd-renewal-button:hover {
  background: var(--text-primary);
  color: var(--bg-primary);
  box-shadow: 0 0 15px var(--text-primary);
}

.xnd-renewal-button.active:hover {
  background: var(--active-green);
  border-color: var(--active-green);
  box-shadow: 0 0 15px var(--active-green);
}

.xnd-renewal-button.warning:hover {
  background: var(--warning-yellow);
  border-color: var(--warning-yellow);
  box-shadow: 0 0 15px var(--warning-yellow);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.xnd-features-list {
  margin-top: var(--spacing-lg);
  padding: var(--spacing-md);
  border: 1px solid var(--text-primary);
  background: rgba(33, 255, 51, 0.05);
}

.xnd-feature-item {
  color: var(--text-secondary);
  padding: var(--spacing-xs) 0;
  font-size: 14px;
}

.xnd-feature-item:before {
  content: '>';
  color: var(--text-primary);
  margin-right: var(--spacing-sm);
}

.xnd-action-buttons {
  display: flex;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.xnd-info-button {
  background: transparent;
  border: 1px solid var(--text-secondary);
  color: var(--text-secondary);
  padding: var(--spacing-sm) var(--spacing-md);
  text-decoration: none;
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.xnd-info-button:hover {
  background: var(--text-secondary);
  color: var(--bg-primary);
  box-shadow: 0 0 15px var(--text-secondary);
}

.xnd-subscribe-button {
  background: transparent;
  border: 1px solid var(--active-green);
  color: var(--active-green);
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 0 0 5px var(--active-green);
}

.xnd-subscribe-button:hover {
  background: var(--active-green);
  color: var(--bg-primary);
  box-shadow: 0 0 15px var(--active-green);
}

.xnd-welcome-message {
  color: var(--text-secondary);
  font-size: 14px;
  margin: var(--spacing-md) 0;
  padding: var(--spacing-md);
  border-left: 2px solid var(--text-primary);
  background: rgba(33, 255, 51, 0.05);
}

/* Анимация появления */
.subscription-status {
  animation: fade-in 0.3s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
  .subscription-status {
    padding: var(--spacing-sm);
  }

  .status-info {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
  }

  .status-label {
    min-width: auto;
    margin-bottom: var(--spacing-xs);
  }

  .xnd-status-value {
    width: 100%;
    min-width: auto;
  }

  .xnd-extend-subscription {
    flex-direction: column;
    align-items: stretch;
    padding: var(--spacing-sm);
    margin: var(--spacing-sm) 0;
  }

  .xnd-extend-message {
    margin-bottom: var(--spacing-sm);
    padding: var(--spacing-sm);
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-xs);
    font-size: 13px;
    line-height: 1.4;
  }

  .xnd-extend-message::before {
    margin-top: 2px;
    margin-right: var(--spacing-xs);
  }

  .xnd-renewal-button {
    width: 100%;
    margin-top: var(--spacing-xs);
  }

  .xnd-subscribe-button,
  .xnd-info-button {
    width: 100%;
    text-align: center;
    padding: var(--spacing-sm);
  }

  .xnd-action-buttons {
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  .xnd-features-list {
    margin-top: var(--spacing-md);
    padding: var(--spacing-sm);
  }

  .xnd-feature-item {
    padding: var(--spacing-sm) 0;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }

  .xnd-feature-item:before {
    flex-shrink: 0;
  }

  .xnd-welcome-message {
    margin: var(--spacing-sm) 0;
    padding: var(--spacing-sm);
  }
}

/* Медиа-запросы для очень маленьких экранов */
@media (max-width: 380px) {
  .subscription-status {
    padding: var(--spacing-xs);
  }

  .xnd-status-value,
  .xnd-extend-message,
  .xnd-renewal-button,
  .xnd-subscribe-button,
  .xnd-info-button,
  .xnd-feature-item {
    font-size: 12px;
  }

  .xnd-extend-subscription {
    margin-top: var(--spacing-sm);
  }

  .status-label {
    font-size: 12px;
  }
}

/* Кнопка помощи */
.xnd-help-button {
  background: transparent;
  border: 1px solid var(--text-secondary);
  color: var(--text-secondary);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-family: 'Share Tech Mono', monospace;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: auto;
  position: relative;
}

.xnd-help-button::before {
  content: '[?]';
  margin-right: var(--spacing-xs);
  color: var(--text-primary);
}

.xnd-help-button:hover {
  border-color: var(--text-primary);
  color: var(--text-primary);
  text-shadow: 0 0 5px var(--text-primary);
}

/* Модальное окно помощи */
.xnd-help-popup {
  position: absolute;
  top: calc(100% + var(--spacing-sm));
  right: 0;
  width: 300px;
  background: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: var(--spacing-md);
  z-index: 1000;
  animation: popup-appear 0.2s ease-out;
  box-shadow: 0 0 20px rgba(33, 255, 51, 0.1);
}

.xnd-help-popup::before {
  content: '';
  position: absolute;
  top: -8px;
  right: 20px;
  width: 14px;
  height: 14px;
  background: var(--bg-secondary);
  border-left: 1px solid var(--text-primary);
  border-top: 1px solid var(--text-primary);
  transform: rotate(45deg);
}

.xnd-help-title {
  color: var(--text-primary);
  font-size: 14px;
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--text-primary);
}

.xnd-help-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.xnd-help-item {
  color: var(--text-secondary);
  font-size: 13px;
  padding: var(--spacing-sm) 0;
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
}

.xnd-help-item::before {
  content: '>';
  color: var(--text-primary);
  flex-shrink: 0;
}

.xnd-help-close {
  position: absolute;
  top: var(--spacing-xs);
  right: var(--spacing-xs);
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  font-family: 'Share Tech Mono', monospace;
  padding: 2px 6px;
}

.xnd-help-close:hover {
  color: var(--error-red);
}

@keyframes popup-appear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Мобильная версия */
@media (max-width: 768px) {
  .xnd-help-button {
    margin-left: 0;
    margin-top: var(--spacing-sm);
    width: auto;
  }

  .xnd-help-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 320px;
    margin: 0 auto;
    animation: mobile-popup-appear 0.3s ease-out;
  }

  .xnd-help-popup::before {
    display: none;
  }
}

@keyframes mobile-popup-appear {
  from {
    opacity: 0;
    transform: translate(-50%, -45%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {
  .status-info {
    flex-wrap: wrap;
    gap: var(--spacing-sm);
  }

  .xnd-help-button {
    margin-left: 0;
    margin-top: var(--spacing-sm);
    width: auto;
  }

  /* ... остальные мобильные стили ... */
} 