/* Основной контейнер */
.enterprise-container {
  padding: 30px;
  color: var(--text-color);
  max-width: 1400px;
  margin: 0 auto;
  background: var(--bg-primary);
}

/* Заголовок */
.enterprise-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding: 20px;
  background: rgba(0, 255, 0, 0.05);
  border-radius: 12px;
  border: 1px solid var(--success-color);
}

/* Селектор пользователя */
.enterprise-user-selector select {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--success-color);
  padding: 10px 20px;
  border-radius: 8px;
  font-family: 'Fira Code', monospace;
  min-width: 250px;
  transition: all 0.3s ease;
}

.enterprise-user-selector select:hover {
  border-color: var(--success-color);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.1);
}

/* Секции серверов и кошельков */
.enterprise-servers-section,
.enterprise-wallets-section {
  margin-bottom: 40px;
  padding: 25px;
  background: var(--bg-secondary);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.enterprise-section-title {
  color: var(--success-color);
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--success-color);
  font-size: 1.5em;
}

/* Карточки серверов */
.enterprise-server-card {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.enterprise-server-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 255, 0, 0.1);
  border-color: var(--success-color);
}

.enterprise-server-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.enterprise-server-header h3 {
  color: var(--success-color);
  font-size: 1.2em;
}

/* Информация о сервере */
.enterprise-server-info {
  background: rgba(0, 255, 0, 0.05);
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
}

.enterprise-server-info p {
  margin: 8px 0;
  color: var(--success-color);
}

/* Ноды */
.enterprise-nodes-list {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}

.enterprise-node-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-bottom: 10px;
  background: var(--bg-secondary);
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.enterprise-node-item:hover {
  border-color: var(--success-color);
  background: rgba(0, 255, 0, 0.05);
}

/* Кошельки */
.enterprise-wallet-card {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.enterprise-wallet-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 255, 0, 0.1);
  border-color: var(--success-color);
}

.enterprise-wallet-info {
  background: rgba(0, 255, 0, 0.05);
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
}

.enterprise-wallet-address {
  font-family: 'Fira Code', monospace;
  font-size: 0.9em;
  padding: 12px;
  background: var(--bg-secondary);
  border-radius: 8px;
  word-break: break-all;
  margin-top: 12px;
  border: 1px solid var(--border-color);
  color: var(--success-color);
}

/* Кнопки */
.enterprise-action-btn {
  background: transparent;
  border: 1px solid var(--success-color);
  color: var(--success-color);
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Fira Code', monospace;
  margin: 0 5px;
}

.enterprise-action-btn:hover {
  background: var(--success-color);
  color: var(--bg-primary);
}

.enterprise-delete-btn {
  border-color: var(--danger-color);
  color: var(--danger-color);
}

.enterprise-delete-btn:hover {
  background: var(--danger-color);
  color: var(--bg-primary);
}

/* Селекты статусов */
.enterprise-node-select {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 6px 12px;
  border-radius: 6px;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.enterprise-node-select:hover {
  border-color: var(--success-color);
}

/* Статусы */
.enterprise-status-active {
  color: var(--success-color);
}

.enterprise-status-inactive {
  color: var(--danger-color);
}

.enterprise-status-maintenance {
  color: var(--warning-color);
}

/* Уведомления */
.enterprise-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 25px;
  border-radius: 8px;
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  animation: slideIn 0.3s ease;
  z-index: 1000;
  font-family: 'Fira Code', monospace;
}

.enterprise-notification.enterprise-status-success {
  border-color: var(--success-color);
  color: var(--success-color);
  background: rgba(0, 255, 0, 0.1);
}

.enterprise-notification.enterprise-status-error {
  border-color: var(--danger-color);
  color: var(--danger-color);
  background: rgba(255, 0, 0, 0.1);
}

/* Анимации */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.enterprise-error-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 25px;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid var(--danger-color);
  color: var(--danger-color);
  animation: slideIn 0.3s ease;
  z-index: 1000;
  font-family: 'Fira Code', monospace;
} 