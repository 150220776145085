.xproj-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.xproj-header {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(180, 255, 159, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.xproj-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
}

.xproj-title {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  color: var(--home-text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xproj-title-prefix {
  color: var(--home-accent-green);
}

.xproj-command {
  color: var(--home-accent-cyan);
}

.xproj-cursor {
  color: var(--home-accent-cyan);
  animation: blink 1s infinite;
}

.xproj-status {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'Share Tech Mono', monospace;
}

.xproj-status-dot {
  width: 8px;
  height: 8px;
  background: #4CAF50;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.xproj-status-value {
  color: #4CAF50;
}

.xproj-status-time {
  color: var(--home-text-secondary);
  font-size: 0.9rem;
}

.xproj-metrics {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  padding-top: 1.5rem;
}

.xproj-metric {
  flex: 1;
}

.xproj-metric-bar {
  height: 4px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.xproj-metric-fill {
  height: 100%;
  background: var(--home-accent-cyan);
  border-radius: 2px;
  transition: width 0.3s ease;
}

.xproj-metric-label {
  color: var(--home-accent-green);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.xproj-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
}

.xproj-card {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(180, 255, 159, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.xproj-card:hover {
  border-color: var(--home-accent-cyan);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.1);
}

.xproj-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
}

.xproj-card-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.xproj-card-icon {
  font-size: 1.5rem;
  color: var(--home-accent-cyan);
}

.xproj-card-name {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  color: var(--home-text-primary);
}

.xproj-card-status {
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
}

.xproj-card-status.active {
  color: #4CAF50;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.xproj-card-status.monitoring {
  color: #FF9800;
  border: 1px solid rgba(255, 152, 0, 0.3);
}

.xproj-node-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.xproj-node-type,
.xproj-node-uptime {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.xproj-node-label,
.xproj-uptime-label {
  color: var(--home-accent-green);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
}

.xproj-node-count,
.xproj-uptime-value {
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.5rem;
}

.xproj-specs {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.xproj-specs-title {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1.5rem;
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
}

.xproj-specs-icon {
  color: var(--home-accent-cyan);
  font-size: 1.2rem;
}

.xproj-specs-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.xproj-spec {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.xproj-spec:hover {
  background: rgba(0, 0, 0, 0.4);
  transform: translateX(5px);
}

.xproj-spec-icon {
  color: var(--home-accent-cyan);
  font-size: 1.2rem;
}

.xproj-spec-label {
  color: var(--home-accent-green);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
}

.xproj-spec-value {
  margin-left: auto;
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
}

.xproj-updates {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.xproj-update {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.xproj-update:hover {
  background: rgba(0, 0, 0, 0.4);
  transform: translateX(5px);
}

.xproj-update-icon {
  color: var(--home-accent-cyan);
  font-size: 1.2rem;
}

.xproj-update-label {
  color: var(--home-accent-green);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
}

.xproj-update-value {
  margin-left: auto;
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

@media (max-width: 1200px) {
  .xproj-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .xproj-title-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .xproj-metrics {
    flex-direction: column;
    align-items: stretch;
  }

  .xproj-specs-grid,
  .xproj-updates {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .xproj-container {
    padding: 1rem;
  }
} 