/* Обновленные стили для Enterprise таба */
.enterprise-dashboard {
  padding: 1.5rem;
  background: rgba(0, 20, 0, 0.3);
  border-radius: 8px;
}

.enterprise-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.2);
}

.header-actions {
  display: flex;
  gap: 1rem;
}

.action-button {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
}

.data-table {
  width: 100%;
  overflow-x: auto;
}

.table-header {
  display: grid;
  grid-template-columns: 80px 200px minmax(200px, 1fr) minmax(250px, 350px) 100px;
  gap: 1rem;
  padding: 0.75rem;
  background: rgba(0, 20, 40, 0.5);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.header-cell {
  color: #0ff;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

.table-body {
  max-height: 400px;
  overflow-y: auto;
}

.table-row {
  display: grid;
  grid-template-columns: 80px 200px minmax(200px, 1fr) minmax(250px, 350px) 100px;
  gap: 1rem;
  padding: 0.75rem;
  align-items: center;
}

.table-row:hover {
  background: rgba(0, 255, 0, 0.05);
}

.cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00ff00;
}

.status-indicator {
  font-size: 0.8rem;
}

.status-indicator.active {
  color: #00ff00;
}

.status-indicator.maintenance {
  color: #ffa500;
}

.details-button {
  background: transparent;
  border: none;
  color: rgba(0, 255, 0, 0.7);
  cursor: pointer;
  padding: 0.25rem;
  transition: all 0.3s ease;
}

.details-button:hover {
  color: #00ff00;
}

.table-footer {
  padding: 0.75rem;
  text-align: center;
  border-top: 1px solid rgba(0, 255, 0, 0.2);
}

.view-more {
  background: transparent;
  border: none;
  color: #00ff00;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.view-more:hover {
  text-decoration: underline;
}

.section-stats {
  display: flex;
  gap: 1rem;
  color: rgba(0, 255, 0, 0.7);
}

.stat {
  font-size: 0.9rem;
}

/* Стили для скроллбара */
.table-body::-webkit-scrollbar {
  width: 6px;
}

.table-body::-webkit-scrollbar-track {
  background: rgba(0, 20, 0, 0.4);
}

.table-body::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 0, 0.3);
  border-radius: 3px;
}

/* Добавляем стили для экспорта */
.export-group {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.export-format {
  background: rgba(0, 20, 0, 0.95);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
  padding: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
  cursor: pointer;
}

.action-button.warning {
  border-color: rgba(255, 165, 0, 0.3);
  color: #FFA500;
}

.action-button.warning:hover {
  background: rgba(255, 165, 0, 0.1);
  border-color: rgba(255, 165, 0, 0.5);
}

.status-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.status-indicator.active {
  background: #00ff00;
  box-shadow: 0 0 5px #00ff00;
}

.status-indicator.inactive {
  background: #ff0000;
  box-shadow: 0 0 5px #ff0000;
}

.status-indicator.maintenance {
  background: #FFA500;
  box-shadow: 0 0 5px #FFA500;
}

/* Киберпанк стили для Enterprise */
.enterprise-dashboard {
    padding: 1.5rem;
    background: rgba(0, 10, 20, 0.95);
    border-radius: 8px;
    color: #e0e0ff;
  }
  
  .cyber-panel {
    background: rgba(20, 30, 40, 0.8);
    border: 1px solid rgba(0, 255, 255, 0.1);
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
  }
  
  .cyber-panel:hover {
    border-color: rgba(0, 255, 255, 0.2);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.15);
  }
  
  .neon-text {
    color: #0ff;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  }
  
  .neon-cyan {
    color: #0ff;
    text-shadow: 0 0 5px #0ff;
  }
  
  .neon-red {
    color: #f55;
    text-shadow: 0 0 5px rgba(255, 85, 85, 0.5);
  }
  
  .cyber-select {
    background: rgba(0, 20, 40, 0.9);
    border: 1px solid rgba(0, 255, 255, 0.2);
    color: #0ff;
    padding: 0.5rem;
  }
  
  .cyber-button {
    background: rgba(0, 255, 255, 0.1);
    border: 1px solid rgba(0, 255, 255, 0.2);
    color: #0ff;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
  }
  
  .cyber-button:hover {
    background: rgba(0, 255, 255, 0.2);
    border-color: rgba(0, 255, 255, 0.4);
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
  }
  
  .cyber-row {
    background: rgba(0, 20, 40, 0.6);
    border-left: 2px solid transparent;
    transition: all 0.3s ease;
  }
  
  .cyber-row:hover {
    background: rgba(0, 30, 60, 0.8);
    border-left: 2px solid #0ff;
  }
  
  .status-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .status-indicator.active {
    background: #0f0;
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  }
  
  .status-indicator.maintenance {
    background: #ff0;
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5);
  }
  
  .status-indicator.stopped {
    background: #f00;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  }
  
  .mono-text {
    font-family: 'Anonymous Pro', monospace;
    letter-spacing: 1px;
  }
  
  .glitch-effect {
    animation: glitch 1s infinite;
  }
  
  @keyframes glitch {
    0% { text-shadow: 2px 2px #0ff, -2px -2px #f0f; }
    25% { text-shadow: -2px 2px #0ff, 2px -2px #f0f; }
    50% { text-shadow: 2px -2px #0ff, -2px 2px #f0f; }
    75% { text-shadow: -2px -2px #0ff, 2px 2px #f0f; }
    100% { text-shadow: 2px 2px #0ff, -2px -2px #f0f; }
  }

.cyber-search {
  background: rgba(0, 20, 40, 0.9);
  border: 1px solid rgba(0, 255, 255, 0.2);
  color: #0ff;
  padding: 0.5rem;
  width: 200px;
}

.section-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.node-icon {
  padding: 0.2rem 0.4rem;
  margin-right: 0.4rem;
  border-radius: 3px;
  font-size: 0.8rem;
}

.neon-green {
  color: #0f0;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.neon-red {
  color: #f00;
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

.neon-yellow {
  color: #ff0;
  text-shadow: 0 0 5px rgba(255, 255, 0, 0.5);
}

.cyber-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid rgba(0, 255, 255, 0.1);
}

.page-info {
  font-family: 'Anonymous Pro', monospace;
}

.table-header .header-cell {
  cursor: pointer;
  transition: color 0.3s ease;
}

.table-header .header-cell:hover {
  color: #0ff;
}

.status-icon {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Анимация для иконки установки */
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.status-icon.installing {
  animation: spin 2s linear infinite;
}

/* Стили для кошельков */
.wallet-table .table-row {
  border-left: 2px solid transparent;
  position: relative;
  overflow: hidden;
}

.wallet-table .table-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: -2px;
  height: 100%;
  width: 2px;
  background: linear-gradient(180deg, #0ff, #80f);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.wallet-table .table-row:hover::before {
  opacity: 1;
}

.wallet-type-icon {
  font-family: 'Anonymous Pro', monospace;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  font-weight: bold;
}

.neon-blue {
  color: #0ff;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  border: 1px solid rgba(0, 255, 255, 0.3);
}

.neon-orange {
  color: #f90;
  text-shadow: 0 0 5px rgba(255, 153, 0, 0.5);
  border: 1px solid rgba(255, 153, 0, 0.3);
}

.address-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
}

.address {
  font-family: 'Anonymous Pro', monospace;
  color: #0ff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.copy-button {
  background: none;
  border: none;
  color: #0ff;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.table-row:hover .copy-button {
  opacity: 1;
}

.linked-node {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0.1rem 0.3rem;
  background: rgba(0, 255, 255, 0.1);
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 3px;
  font-size: 0.8rem;
}

.actions {
  display: flex;
  gap: 0.5rem;
  position: relative;
  min-width: 120px; /* Фиксированная минимальная ширина */
  justify-content: flex-end;
}

.action-button {
  background: transparent;
  border: 1px solid rgba(0, 255, 255, 0.2);
  color: #0ff;
  padding: 0.4rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Anonymous Pro', monospace;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  border-color: rgba(0, 255, 255, 0.5);
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-1px);
}

.action-button:active {
  transform: translateY(0);
}

.search-box {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 0.5rem;
  color: rgba(0, 255, 255, 0.5);
}

.cyber-search {
  padding-left: 2rem;
}

.section-stats {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.stat {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: rgba(0, 255, 255, 0.7);
}

.stat-icon {
  color: #0ff;
}

.neon-purple {
  color: #f0f;
  text-shadow: 0 0 5px rgba(255, 0, 255, 0.5);
  border: 1px solid rgba(255, 0, 255, 0.3);
}

.nodes-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.node-icon {
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.node-icon.running {
  border-color: rgba(0, 255, 0, 0.3);
}

.node-icon.stopped {
  border-color: rgba(255, 0, 0, 0.3);
  opacity: 0.7;
}

.ip-address {
  font-family: 'Anonymous Pro', monospace;
  color: #0ff;
}

.action-button.password {
  color: #f0f;
  border-color: rgba(255, 0, 255, 0.3);
}

.action-button.password:hover {
  background: rgba(255, 0, 255, 0.1);
  border-color: rgba(255, 0, 255, 0.5);
}

/* Стили для экспорта */
.export-format {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.export-group {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

/* Стили для отображения связанных нод и серверов */
.linked-nodes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.server-nodes-group {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 0.5rem;
  background: rgba(0, 20, 40, 0.3);
  border: 1px solid rgba(0, 255, 255, 0.1);
  border-radius: 3px;
  min-width: 0;
}

.server-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.1);
  font-family: 'Courier New', monospace;
}

.server-name {
  color: #0ff;
  font-size: 0.9rem;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.server-status {
  margin-left: auto;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.server-status.active {
  color: #0f0;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.server-status.inactive {
  color: #f00;
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

/* Анимация для статусов серверов */
.server-status.active {
  animation: pulse 2s infinite;
}

.server-status.inactive {
  animation: flicker 4s infinite;
}

/* Обновляем стили карточки ноды */
.node-detail-card {
  background: rgba(0, 20, 40, 0.3);
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  margin-bottom: 0.5rem;
}

.node-detail-card:hover {
  border-color: rgba(0, 255, 255, 0.4);
  background: rgba(0, 20, 40, 0.5);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
}

/* Добавляем неоновый эффект при наведении */
.node-detail-card:hover .server-name,
.node-detail-card:hover .node-name {
  text-shadow: 0 0 8px rgba(0, 255, 255, 0.8);
}

.nodes-list {
  display: flex;
  gap: 0.3rem;
  flex-wrap: wrap;
  min-width: 0;
}

.linked-nodes-cell {
  min-width: 250px;
}

/* Анимация при наведении */
.server-nodes-group:hover {
  border-color: rgba(0, 255, 255, 0.3);
  background: rgba(0, 20, 40, 0.5);
}

/* Стили для статуса сервера внутри группы */
.server-nodes-group .status-icon {
  font-size: 0.8rem;
}

/* Стили для нод внутри группы */
.server-nodes-group .node-icon {
  font-size: 0.7rem;
  padding: 0.1rem 0.3rem;
}

/* Медиа-запрос для маленьких экранов */
@media (max-width: 1200px) {
  .table-row,
  .table-header {
    grid-template-columns: 80px 150px minmax(150px, 1fr) minmax(200px, 300px) 100px;
  }
}

/* Стили для уведомлений */
.action-notification {
  position: absolute;
  bottom: calc(100% + 5px);
  right: 0;
  background: rgba(0, 20, 40, 0.9);
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 0.5rem;
  border-radius: 3px;
  font-size: 0.8rem;
  white-space: nowrap;
  animation: fadeInOut 2s ease;
  z-index: 100;
}

.action-notification.success {
  color: #0f0;
  border-color: rgba(0, 255, 0, 0.3);
}

.action-notification.info {
  color: #0ff;
  border-color: rgba(0, 255, 255, 0.3);
}

.action-notification.error {
  color: #f00;
  border-color: rgba(255, 0, 0, 0.3);
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(5px); }
  10% { opacity: 1; transform: translateY(0); }
  90% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-5px); }
}

/* Стили для разных типов кнопок */
.action-button.view { color: #0ff; }
.action-button.export { color: #0f0; }
.action-button.private-key { color: #f0f; }
.action-button.password { color: #ff0; }

/* Добавляем эффект пульсации при копировании */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.action-button.active {
  animation: pulse 0.3s ease;
}

/* Стили для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 20, 40, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: rgba(0, 20, 40, 0.95);
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
  min-width: 500px;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.close-button {
  background: transparent;
  border: 1px solid rgba(255, 0, 0, 0.3);
  color: #f00;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: rgba(255, 0, 0, 0.1);
  border-color: rgba(255, 0, 0, 0.5);
}

.detail-row {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: rgba(0, 20, 40, 0.3);
  border-radius: 4px;
}

.detail-label {
  min-width: 120px;
  color: #0ff;
  margin-right: 1rem;
}

.detail-value {
  color: #0f0;
  word-break: break-all;
}

/* Исправляем позиционирование кнопок в actions */
.table-row {
  grid-template-columns: 80px 180px minmax(200px, 1fr) minmax(200px, 350px) 140px !important;
}

.table-header {
  grid-template-columns: 80px 180px minmax(200px, 1fr) minmax(200px, 350px) 140px !important;
}

.actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding-right: 1rem;
  min-width: 140px;
}

.action-button {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

/* Анимация появления модального окна */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  animation: modalFadeIn 0.3s ease;
}

/* Стили для модального окна с деталями */
.modal-content {
  min-width: 600px;
  max-width: 800px;
  background: rgba(0, 20, 40, 0.95);
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.header-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-info .neon-text {
  margin: 0;
  color: #0ff;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.detail-row {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.1);
}

.detail-label {
  color: #0f0;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.detail-value {
  color: #fff;
  word-break: break-word;
}

.detail-value.mono-text {
  font-family: 'Anonymous Pro', monospace;
}

/* Стили для отображения нод */
.nodes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.node-detail-card {
  background: rgba(0, 20, 40, 0.3);
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.node-detail-card:hover {
  border-color: rgba(0, 255, 255, 0.4);
  background: rgba(0, 20, 40, 0.5);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.1);
}

.node-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'Courier New', monospace;
}

.node-name {
  color: #0ff;
  font-size: 0.9rem;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.node-status {
  margin-left: auto;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.node-status.running {
  color: #0f0;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.node-status.stopped {
  color: #f00;
  text-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

/* Добавляем пульсацию для активных нод */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.node-status.running {
  animation: pulse 2s infinite;
}

/* Добавляем мерцание для остановленных нод */
@keyframes flicker {
  0% { opacity: 0.7; }
  5% { opacity: 0.9; }
  10% { opacity: 0.7; }
  15% { opacity: 0.9; }
  20% { opacity: 0.7; }
  100% { opacity: 0.7; }
}

.node-status.stopped {
  animation: flicker 4s infinite;
}

/* Стили для контейнеров с кнопкой копирования */
.private-key-container,
.address-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 4px;
}

.copy-button {
  background: transparent;
  border: 1px solid rgba(0, 255, 255, 0.2);
  color: #0ff;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copy-button:hover {
  border-color: rgba(0, 255, 255, 0.5);
  background: rgba(0, 255, 255, 0.1);
}

/* Обновленные стили для заголовка */
.header-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.detail-label {
  text-transform: uppercase;
  color: #0f0;
  font-size: 0.9rem;
}

.detail-row {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.1);
}

/* Стили для отображения информации о сервере */
.detail-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.password-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 0, 0.1);
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 0, 0.2);
}

.node-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: rgba(0, 20, 40, 0.3);
  border-radius: 4px;
}

.linked-wallets {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid rgba(0, 255, 255, 0.1);
}

.detail-subtitle {
  display: block;
  color: #0f0;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.wallet-tag {
  display: inline-block;
  background: rgba(0, 255, 255, 0.1);
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  margin: 0.2rem;
  font-size: 0.8rem;
}

.private-data-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.private-data {
  background: rgba(255, 0, 255, 0.1);
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 0, 255, 0.2);
  width: 200px; /* Фиксированная ширина */
}

.private-data .mono-text {
  display: block;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-button,
.copy-button {
  flex-shrink: 0;
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-button {
  background: transparent;
  border: 1px solid rgba(0, 255, 255, 0.2);
  color: #0ff;
  padding: 0.4rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-button:hover {
  border-color: rgba(0, 255, 255, 0.5);
  background: rgba(0, 255, 255, 0.1);
}

.view-button.active {
  background: rgba(0, 255, 255, 0.2);
  border-color: rgba(0, 255, 255, 0.5);
}

/* Анимация для появления приватных данных */
.private-data .mono-text {
  transition: all 0.3s ease;
}

/* Стиль для скрытых данных */
.private-data .mono-text.hidden {
  filter: blur(4px);
}

/* Пульсация для кнопки просмотра */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.view-button:active {
  animation: pulse 0.3s ease;
}

.node-type {
  color: #ff00ff;
  font-size: 0.9rem;
}

.node-name {
  color: #0ff;
  font-size: 0.9rem;
}

.node-status {
  color: #0f0;
  font-size: 0.9rem;
  margin-left: auto;
}

.error-panel {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 0, 0, 0.3);
  padding: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  animation: glowPulse 2s infinite;
}

.error-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.error-icon {
  color: #ff0000;
  font-size: 1.5rem;
  animation: blink 1s infinite;
}

.glitch {
  position: relative;
  color: #0ff;
  font-size: 2rem;
  letter-spacing: 0.2em;
  animation: glitchText 3s infinite;
}

.error-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.matrix-line {
  font-family: 'Anonymous Pro', monospace;
  color: #0f0;
  display: flex;
  gap: 0.5rem;
  opacity: 0.8;
}

.matrix-char {
  color: #0ff;
}

.error-message {
  font-size: 1.2rem;
  text-align: center;
  color: #ff0000;
  text-shadow: 0 0 5px #ff0000;
  margin: 1rem 0;
}

.error-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.5);
}

.detail-line {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #0ff;
  font-family: 'Anonymous Pro', monospace;
}

.detail-icon {
  color: #0f0;
}

.enterprise-access {
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background: transparent;
  border: 1px solid #0ff;
  color: #0ff;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.enterprise-access:hover {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
}

.button-cursor {
  animation: blink 1s infinite;
}

@keyframes glowPulse {
  0% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.2); }
  50% { box-shadow: 0 0 20px rgba(255, 0, 0, 0.4); }
  100% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.2); }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes glitchText {
  0% { text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
                    0.025em 0.04em 0 #fffc00; }
  15% { text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
                     0.025em 0.04em 0 #fffc00; }
  16% { text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
                     -0.05em -0.05em 0 #fffc00; }
  49% { text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
                     -0.05em -0.05em 0 #fffc00; }
  50% { text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
                     0 -0.04em 0 #fffc00; }
  99% { text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
                     0 -0.04em 0 #fffc00; }
  100% { text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
                      -0.04em -0.025em 0 #fffc00; }
}

/* Обновляем стили для страницы с ошибкой доступа */
.enterprise-dashboard.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: rgba(0, 10, 20, 0.95);
}

.error-panel {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 0, 0, 0.3);
  padding: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  animation: glowPulse 2s infinite;
  backdrop-filter: blur(10px);
}

.error-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(255, 0, 0, 0.2);
  padding-bottom: 1rem;
}

.error-icon {
  color: #ff0000;
  font-size: 1.5rem;
  animation: blink 1s infinite;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
}

.glitch {
  position: relative;
  color: #0ff;
  font-size: 2.5rem;
  letter-spacing: 0.2em;
  animation: glitchText 3s infinite;
  font-family: 'Anonymous Pro', monospace;
  text-transform: uppercase;
}

.error-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 4px;
}

.matrix-line {
  font-family: 'Anonymous Pro', monospace;
  color: #0f0;
  display: flex;
  gap: 0.5rem;
  opacity: 0.8;
  font-size: 1.1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.matrix-char {
  color: #0ff;
  animation: blink 2s infinite;
}

.error-message {
  font-size: 1.4rem;
  text-align: center;
  color: #ff0000;
  text-shadow: 0 0 5px #ff0000;
  margin: 1rem 0;
  font-family: 'Anonymous Pro', monospace;
  letter-spacing: 1px;
}

.error-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1.5rem;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.detail-line {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #0ff;
  font-family: 'Anonymous Pro', monospace;
  padding: 0.5rem;
  border-left: 2px solid transparent;
  transition: all 0.3s ease;
}

.detail-line:hover {
  border-left-color: #0ff;
  background: rgba(0, 255, 255, 0.1);
}

.detail-icon {
  color: #0f0;
  font-size: 1.2rem;
  min-width: 30px;
  text-align: center;
}

.enterprise-access {
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background: transparent;
  border: 1px solid #0ff;
  color: #0ff;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'Anonymous Pro', monospace;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.enterprise-access:hover {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
}

.enterprise-access::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.enterprise-access:hover::before {
  left: 100%;
}

.button-cursor {
  animation: blink 1s infinite;
}

/* Добавляем новые стили для страницы с ошибкой доступа */
.enterprise-dashboard.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: rgba(0, 10, 20, 0.95);
}

/* Обновляем стили для панели с ошибкой */
.error-panel.access-denied {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 0, 0, 0.3);
  padding: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  animation: glowPulse 2s infinite;
  backdrop-filter: blur(10px);
}

/* Уникальный класс для сообщения об ошибке доступа */
.access-error-message {
  font-size: 1.4rem;
  text-align: center;
  color: #ff0000;
  text-shadow: 0 0 5px #ff0000;
  margin: 1rem 0;
  font-family: 'Anonymous Pro', monospace;
  letter-spacing: 1px;
}

/* Добавляем стили для лоадера */
.enterprise-dashboard.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: rgba(0, 10, 20, 0.95);
}

.loading-panel {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 255, 255, 0.3);
  padding: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  animation: glowPulse 2s infinite;
  backdrop-filter: blur(10px);
}

.loading-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.loading-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  padding-bottom: 1rem;
  width: 100%;
  justify-content: center;
}

.loading-icon {
  color: #0ff;
  font-size: 1.5rem;
  animation: spin 2s linear infinite;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.loading-text {
  color: #0ff;
  font-size: 2rem;
  letter-spacing: 0.2em;
  font-family: 'Anonymous Pro', monospace;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.loading-progress {
  width: 100%;
  position: relative;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.progress-line {
  height: 2px;
  background: linear-gradient(90deg, #0ff, #0f0);
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

.progress-line::after {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, 
    transparent,
    rgba(0, 255, 255, 0.5),
    transparent
  );
  animation: progressLine 1.5s ease-in-out infinite;
}

.progress-text {
  text-align: center;
  margin-top: 1rem;
  font-family: 'Anonymous Pro', monospace;
  color: #0f0;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.status-text {
  animation: blink 1s infinite;
}

.loading-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.detail-line {
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
}

.detail-line:nth-child(1) { animation-delay: 0.2s; }
.detail-line:nth-child(2) { animation-delay: 0.4s; }
.detail-line:nth-child(3) { animation-delay: 0.6s; }

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes progressLine {
  0% { left: -50%; }
  100% { left: 100%; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Добавляем эффект мерцания для текста статуса */
@keyframes textFlicker {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

.status-text {
  animation: textFlicker 2s infinite;
}