.admin-news-page {
    padding: 2rem;
    background: #1a1a1a;
    min-height: 100vh;
    margin-top: 60px;
    color: #00ff00;
  }
  
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .create-btn {
    padding: 0.5rem 1rem;
    background: rgba(0, 255, 0, 0.1);
    border: 1px solid rgba(0, 255, 0, 0.2);
    color: #00ff00;
    cursor: pointer;
    font-family: monospace;
    display: inline-block;
    text-decoration: none;
    margin-left: auto;
  }
  
  .create-btn:hover {
    background: rgba(0, 255, 0, 0.2);
  }
  
  .news-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .news-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: rgba(0, 255, 0, 0.05);
    border: 1px solid rgba(0, 255, 0, 0.2);
    border-radius: 4px;
  }
  
  .news-status {
    display: flex;
    gap: 0.5rem;
  }
  
  .news-content {
    flex: 1;
  }
  
  .news-meta {
    display: flex;
    gap: 1rem;
    font-size: 0.9em;
    color: rgba(0, 255, 0, 0.7);
  }
  
  .news-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .edit-btn,
  .delete-btn {
    padding: 0.25rem 0.5rem;
    background: #2a2a2a;
    border: 1px solid rgba(0, 255, 0, 0.2);
    color: #00ff00;
    cursor: pointer;
    font-family: monospace;
  }
  
  .delete-btn {
    color: #ff4444;
    border-color: rgba(255, 68, 68, 0.2);
  }
  
  .status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-family: monospace;
  }
  
  .status-badge.draft {
    color: #ffaa00;
  }
  
  .status-badge.published {
    color: #00ff00;
  }
  
  .pinned-badge {
    color: #00ffff;
  }
  
  .create-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: rgba(128, 128, 128, 0.1);
  }