.guide-page {
  background: linear-gradient(180deg, rgba(0, 10, 0, 0.95) 0%, rgba(0, 15, 0, 0.98) 100%);
  min-height: 100vh;
  position: relative;
}

.guide-container {
  position: relative;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.guide-container p {
  color: #b0b0b0;
}

.guide-header {
  margin-bottom: 4rem;
}

.guide-title-section {
  position: relative;
  margin-bottom: 3rem;
}

.guide-title-wrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  animation: fadeIn 0.6s ease-out;
}

.guide-icon {
  font-size: 2.2rem;
  color: #00ff00;
  animation: typeIn 0.3s ease-out;
}

.guide-title {
  font-family: 'Fira Code', monospace;
  font-size: 2.8rem;
  color: #00ff00;
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.guide-title::after {
  content: '_';
  animation: blink 1.2s infinite;
  margin-left: 5px;
}

.guide-description {
  font-size: 1.25rem;
  color: #00ff00;
  opacity: 0.85;
  margin-bottom: 1.5rem;
  max-width: 700px;
  line-height: 1.6;
}

.guide-meta {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.guide-badge.new {
  background: rgba(0, 255, 0, 0.15);
  border: 1px solid #00ff00;
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  font-size: 0.9rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.guide-category {
  color: rgba(0, 255, 0, 0.85);
  font-size: 1rem;
}

.guide-date {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(0, 20, 0, 0.4);
  border: 1px solid rgba(0, 255, 0, 0.15);
  border-radius: 4px;
  font-size: 0.85rem;
  color: rgba(0, 255, 0, 0.7);
}

.time-icon {
  opacity: 0.8;
}

.guide-content-wrapper {
  margin-top: 2rem;
  padding: 2rem;
  background: rgba(0, 25, 0, 0.4);
  border: 1px solid rgba(0, 255, 0, 0.15);
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.content-header {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.15);
}

.content-title {
  color: #00ff00;
  font-size: 1.2rem;
  font-weight: 500;
}

.content-body h2 {
  font-size: 1.7rem;
  color: aquamarine;
}

.guide-meta-line {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  flex-wrap: wrap;
}

.guide-category-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: rgba(0, 40, 0, 0.5);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  color: #00ff00;
  font-size: 0.9rem;
  box-shadow: 0 2px 8px rgba(0, 255, 0, 0.1);
  transition: all 0.3s ease;
}

.guide-category-badge:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 255, 0, 0.15);
}

.guide-tag {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.35rem 0.75rem;
  background: rgba(0, 30, 0, 0.4);
  border: 1px solid rgba(0, 255, 0, 0.15);
  border-radius: 4px;
  color: rgba(0, 255, 0, 0.8);
  font-size: 0.85rem;
  transition: all 0.3s ease;
}

.guide-tag:hover {
  background: rgba(0, 40, 0, 0.5);
  transform: translateY(-1px);
}

.guide-separator {
  color: rgba(0, 255, 0, 0.3);
}

.guide-description-block {
  background: rgba(0, 20, 0, 0.7);
  border: 1px solid #00ff00;
  padding: 1.5rem;
  margin: 2rem 0;
  position: relative;
  overflow: hidden;
}

.guide-description-block::before {
  content: '[sys.info]';
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: rgba(0, 255, 0, 0.3);
  font-size: 0.8rem;
}

.guide-description-block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, 
    transparent 0%,
    #00ff00 50%,
    transparent 100%
  );
  animation: scanline 4s linear infinite;
}

.description-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-family: 'Fira Code', monospace;
}

.description-icon {
  color: #00ff00;
  font-size: 1.2rem;
  animation: pulse 2s infinite;
}

.description-text {
  font-family: 'Anonymous Pro', monospace;
  color: #b0b0b0;
  line-height: 1.8;
  position: relative;
}

.description-text span {
  position: relative;
  display: inline-block;
}

.description-text span.glitch {
  animation: glitch 3s infinite;
  color: #00ff00;
}

.reading-progress {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(0, 255, 0, 0.1);
  z-index: 1000;
}

.reading-progress-bar {
  height: 100%;
  background: #00ff00;
  width: var(--progress-width, 0%);
  transition: width 0.3s ease;
}

@keyframes typeIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.guide-header-image {
  position: relative;
  width: 100%;
  height: 35vh;
  min-height: 300px;
  margin-bottom: -100px;
  overflow: hidden;
}

.header-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fadeIn 0.5s ease-out;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(18, 18, 18, 0.7) 0%,
    rgba(18, 18, 18, 0.9) 70%,
    #121212 100%
  );
}

.guide-header {
  position: relative;
  z-index: 2;
  padding-top: 2rem;
}

.guide-title-wrapper {
  background: rgba(18, 18, 18, 0.6);
  padding: 1.5rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
}

.guide-meta-line,
.guide-description-block {
  background: rgba(18, 18, 18, 0.6);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .header-image {
    transform: translateY(0);
    transition: transform 0.3s ease-out;
  }
  
  .guide-page:hover .header-image {
    transform: translateY(-20px);
  }
}

.content-body {
  padding: 3rem;
  line-height: 1.8;
  font-family: 'Anonymous Pro', monospace;
  color: #B0B0B0;
}

/* Заголовки первого уровня */
.content-body h1 {
  font-size: 2.5rem;
  color: #00FF00;
  margin: 3rem 0 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid rgba(0, 255, 0, 0.3);
  position: relative;
}

.content-body h1::before {
  content: '[#]';
  margin-right: 1rem;
  color: #00FF00;
}

/* Заголовки второго уровня (секции) */
.guide-section-header {
  font-size: 2rem;
  color: #66FF66;
  margin: 2.5rem 0 1.5rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid rgba(102, 255, 102, 0.3);
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section-icon {
  font-size: 1.8rem;
  color: #66FF66;
  opacity: 0.9;
}

/* Заголовки третьего уровня */
.content-body h3 {
  font-size: 1.5rem;
  color: #66FF66;
  margin: 2rem 0 1rem;
  display: flex;
  align-items: center;
}

.content-body h3::before {
  content: '[+]';
  margin-right: 0.8rem;
  color: #66FF66;
  opacity: 0.8;
}

/* Параграфы */
.content-body p {
  font-size: 1.3rem;
  line-height: 1.8;
  margin: 1rem 0;
  color: #B0B0B0;
}

/* Анимации для заголовков */
.guide-section-header::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, 
    #66FF66 0%,
    rgba(102, 255, 102, 0.5) 50%,
    transparent 100%
  );
  animation: scanline 2s ease-out;
}

/* Код и блоки кода */
.content-body code {
  padding: 0.2em 0.4em;
  font-family: 'Anonymous Pro', monospace;
  color: #66FF66;
  background: rgba(102, 255, 102, 0.1);
  border-radius: 3px;
}

/* Маркированные списки */
.content-body ul {
  list-style: none;
  margin: 1.5rem 0;
  padding: 0;
}

.content-body ul li {
  font-size: 1.3rem;
  position: relative;
  padding-left: 2rem;
  margin: 0.8rem 0;
  color: #B0B0B0;
  line-height: 1.6;
}

.content-body ul li::before {
  content: '[•]';
  position: absolute;
  left: -10px;
  color: #66FF66;
  font-family: 'Anonymous Pro', monospace;
}

/* Нумерованные списки */
.content-body ol {
  list-style: none;
  counter-reset: item;
  margin: 1.5rem 0;
  padding: 0;
}

.content-body ol li {
  position: relative;
  font-size: 1.3rem;
  padding-left: 2.5rem;
  margin: 0.8rem 0;
  color: #B0B0B0;
  line-height: 1.6;
  counter-increment: item;
}

.content-body ol li::before {
  content: '[' counter(item) ']';
  position: absolute;
  left: 0;
  color: #66FF66;
  font-family: 'Anonymous Pro', monospace;
}

/* Цитаты */
.content-body blockquote {
  margin: 2rem 0;
  padding: 1rem 1.5rem;
  border-left: 2px solid #66FF66;
  background: rgba(102, 255, 102, 0.05);
  color: #B0B0B0;
  font-style: italic;
}

.content-body blockquote::before {
  content: '[>]';
  display: block;
  color: #66FF66;
  margin-bottom: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
}

/* Горизонтальные линии */
.content-body hr {
  border: none;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    #66FF66,
    transparent
  );
  margin: 2rem 0;
  position: relative;
}

.content-body hr::before {
  content: '[---]';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #121212;
  padding: 0 1rem;
  color: #66FF66;
  font-family: 'Anonymous Pro', monospace;
}

/* Ссылки */
.content-body a {
  color: #ff66ab;
  text-decoration: none;
  padding-right: 1.2em;
  position: relative;
  border-bottom: 1px dashed rgba(255, 102, 171, 0.3);
  transition: all 0.3s ease;
}

.content-body a::after {
  content: '↗';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.9em;
  opacity: 0.8;
  transition: transform 0.2s ease;
}

.content-body a:hover {
  color: #ff99cc;
  border-bottom-color: rgba(255, 102, 171, 0.6);
}

.content-body a:hover::after {
  transform: translate(2px, -50%);
}

/* Изображения */
.content-body img {
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(102, 255, 102, 0.2);
  margin: 1.5rem 0;
  transition: all 0.3s ease;
}

.content-body img:hover {
  transform: scale(1.01);
  border-color: rgba(102, 255, 102, 0.4);
  box-shadow: 0 4px 20px rgba(0, 255, 0, 0.15);
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
  .content-body {
    padding: 1.5rem;
  }

  .content-body h1 { font-size: 2rem; }
  .guide-section-header { font-size: 1.7rem; }
  .content-body h3 { font-size: 1.4rem; }
  .content-body p { font-size: 1rem; }
  
  .content-body ul li,
  .content-body ol li {
    font-size: 1rem;
    padding-left: 1.5rem;
  }
}

.guide-title {
  font-family: 'Fira Code', monospace;
  font-size: 2.8rem;
  color: #00ff00;
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.typing-effect {
  animation: typing 3.5s steps(40, end);
}

.guide-icon.rotating-icon {
  font-size: 2.2rem;
  color: #00ff00;
  transition: transform 0.3s ease;
}

.guide-icon.rotating-icon:hover {
  transform: rotate(180deg);
  filter: brightness(1.2);
}

.guide-category-badge.glow-effect {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid #00ff00;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  color: #00ff00;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.2);
  animation: glowPulse 2s infinite;
}

.install-button {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid #00ff00;
  color: #00ff00;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-family: 'Anonymous Pro', monospace;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: all 0.3s ease;
  animation: pulseBorder 2s infinite;
}

.install-button:hover {
  border-width: 3px;
  color: #fff;
  background: rgba(0, 255, 0, 0.1);
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes glowPulse {
  0% { box-shadow: 0 0 5px rgba(0, 255, 0, 0.2); }
  50% { box-shadow: 0 0 15px rgba(0, 255, 0, 0.4); }
  100% { box-shadow: 0 0 5px rgba(0, 255, 0, 0.2); }
}

@keyframes pulseBorder {
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0, 255, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 0, 0); }
}

.highlight-terminal {
  background: rgba(0, 0, 0, 0.7);
  color: #ff3333;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: 'Anonymous Pro', monospace;
  position: relative;
  margin: 0 0.2em;
}

.highlight-terminal::before,
.highlight-terminal::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(255, 51, 51, 0.3);
}

.highlight-terminal::before {
  left: 0;
}

.highlight-terminal::after {
  right: 0;
}

.guide-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.guide-tag {
  font-size: 0.9rem;
  color: #00ff00;
  background: rgba(0, 255, 0, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 255, 0, 0.2);
  transition: all 0.3s ease;
}

.guide-tag:hover {
  background: rgba(0, 255, 0, 0.2);
}

.guide-access-restricted {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: #121212;
}

.restricted-container {
  max-width: 600px;
  width: 100%;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 8px;
}

.restricted-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.warning-icon {
  font-size: 2rem;
}

.restricted-title {
  color: #00ff00;
  font-size: 1.8rem;
  margin: 0;
}

.restricted-content {
  text-align: center;
}

.restricted-message {
  color: rgba(0, 255, 0, 0.8);
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.highlight {
  color: #00ff00;
  font-weight: bold;
}

.price-block {
  margin: 2rem 0;
  padding: 1rem;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 4px;
}

.price-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.price-amount {
  font-size: 2rem;
  color: #00ff00;
}

.price-period {
  color: rgba(0, 255, 0, 0.7);
}

.price-note {
  color: rgba(0, 255, 0, 0.6);
  font-size: 0.9rem;
  margin: 0;
}

.access-button {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem 2rem;
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  text-decoration: none;
  font-family: 'Anonymous Pro', monospace;
  transition: all 0.3s ease;
}

.access-button:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
  transform: translateY(-2px);
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.guide-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.guide-image-modal.closing {
  opacity: 0;
}

.guide-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem;
}

.guide-modal-content img {
  max-width: 100%;
  max-height: 85vh;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.guide-modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  z-index: 1001;
}

.guide-modal-close:hover {
  background: rgba(0, 255, 0, 0.1);
  transform: rotate(90deg);
}

.guide-project-info {
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  padding: 1.5rem;
  margin: 2rem 0;
  font-family: 'Anonymous Pro', monospace;
}

.project-info-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.2);
}

.project-info-icon {
  color: #00ff00;
  font-size: 1.2rem;
}

.project-info-title {
  color: #00ff00;
  font-size: 1.2rem;
  margin: 0;
}

.project-info-content {
  display: flex;
  gap: 2rem;
}

.project-info-logo {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
}

.project-info-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.project-placeholder-logo {
  color: #00ff00;
  font-size: 2rem;
  opacity: 0.5;
}

.project-info-details {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.detail-label {
  color: rgba(0, 255, 0, 0.7);
  margin-right: 0.5rem;
}

.detail-value {
  color: #fff;
}

.status-active {
  color: #00ff00;
}

.status-inactive {
  color: #ff4444;
}

.network-active {
  color: #ffaa00;
}

.network-inactive {
  color: #00aaff;
}

.highlight-text {
  background: rgba(255, 0, 0, 0.1);
  color: #ff3333;
  padding: 2px 6px;
  border-radius: 3px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.highlight-text:hover {
  background: rgba(255, 0, 0, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(255, 0, 0, 0.15);
}

.related-guides {
  margin-top: 4rem;
  padding: 2rem;
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 8px;
}

.related-guides-header {
  margin-bottom: 2rem;
}

.related-guides-title {
  color: #00ff00;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
}

.related-guides-title::after {
  content: '_';
  animation: blink 1s infinite;
  opacity: 0.7;
}

.related-guides-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.related-guide-card {
  background: rgba(0, 30, 0, 0.5);
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 6px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
  transform: translateY(20px);
}

.related-guide-card:hover {
  background: rgba(0, 40, 0, 0.6);
  border-color: rgba(0, 255, 0, 0.2);
  transform: translateY(-2px);
}

.related-guide-card.masked {
  position: relative;
  overflow: hidden;
  opacity: 0.7;
}

.related-guide-card.masked::before {
  content: '[PRIVATE]';
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #00ff00;
  font-size: 0.8rem;
  opacity: 0.7;
}

.related-guide-title {
  color: #00ff00;
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
}

.related-guide-description {
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.9rem;
  margin: 0 0 1rem 0;
  line-height: 1.5;
}

.related-guide-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.related-guide-category {
  color: #00ff00;
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 4px;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .related-guides {
    padding: 1rem;
    margin-top: 2rem;
  }

  .related-guides-grid {
    grid-template-columns: 1fr;
  }

  .related-guide-card {
    padding: 1rem;
  }
}

.breadcrumbs {
  margin: 1rem 0 2rem;
  padding: 1rem;
  background: rgba(0, 20, 0, 0.4);
  border: 1px solid rgba(0, 255, 0, 0.15);
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(0, 255, 0, 0.7);
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.breadcrumb-item:not(.current):hover {
  background: rgba(0, 255, 0, 0.1);
  color: #00ff00;
  transform: translateY(-1px);
}

.breadcrumb-item.current {
  color: #00ff00;
  pointer-events: none;
}

.breadcrumb-icon {
  font-size: 0.9rem;
  opacity: 0.8;
}

.breadcrumb-text {
  font-size: 0.9rem;
}

.breadcrumb-separator {
  color: rgba(0, 255, 0, 0.4);
  margin: 0 0.25rem;
  font-size: 0.9rem;
}

.breadcrumb-item:not(.current):hover .breadcrumb-icon {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.8;
  }
}

@media (max-width: 768px) {
  .breadcrumbs {
    margin: 0.5rem 0 1rem;
    padding: 0.75rem;
  }
  
  .breadcrumb-text {
    font-size: 0.8rem;
  }
  
  .breadcrumb-icon {
    font-size: 0.8rem;
  }
}

.guide-loading {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  background: linear-gradient(180deg, rgba(0, 10, 0, 0.95) 0%, rgba(0, 15, 0, 0.98) 100%);
}

.loading-container {
  text-align: center;
}

.loading-title {
  color: #00ff00;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: 'Anonymous Pro', monospace;
  position: relative;
  display: inline-block;
}

.loading-title::after {
  content: '_';
  animation: blink 1s infinite;
}

.loading-spinner {
  width: 80px;
  height: 80px;
  position: relative;
  margin: 2rem auto;
}

.loading-spinner::before,
.loading-spinner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 50%;
}

.loading-spinner::before {
  border-top-color: #00ff00;
  animation: spin 1s linear infinite;
}

.loading-spinner::after {
  border-right-color: #00ff00;
  animation: spin 1.5s linear infinite reverse;
}

.loading-text {
  color: rgba(0, 255, 0, 0.7);
  font-family: 'Anonymous Pro', monospace;
  font-size: 1.1rem;
  margin-top: 1rem;
}

.loading-progress {
  width: 300px;
  height: 2px;
  background: rgba(0, 255, 0, 0.1);
  margin: 2rem auto;
  position: relative;
  overflow: hidden;
}

.loading-progress::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: #00ff00;
  animation: progress 1.5s ease-in-out infinite;
}

.loading-status {
  color: rgba(0, 255, 0, 0.6);
  font-family: 'Anonymous Pro', monospace;
  font-size: 0.9rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.loading-status-line {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-icon {
  opacity: 0.8;
}

.loading-placeholder {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 8px;
  animation: pulse 2s infinite;
}

.placeholder-header {
  margin-bottom: 3rem;
}

.placeholder-breadcrumbs {
  margin-bottom: 2rem;
}

.placeholder-line {
  height: 20px;
  background: rgba(0, 255, 0, 0.1);
  margin: 1rem 0;
  border-radius: 4px;
  animation: shimmer 2s infinite;
}

.placeholder-line.breadcrumb {
  width: 300px;
  height: 16px;
  margin: 0;
}

.placeholder-title-section {
  margin-bottom: 2rem;
}

.placeholder-line.title-main {
  width: 70%;
  height: 48px;
  margin-bottom: 1.5rem;
  background: rgba(0, 255, 0, 0.15);
}

.placeholder-meta {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.placeholder-line.category {
  width: 120px;
  height: 24px;
}

.placeholder-line.tag {
  width: 100px;
  height: 24px;
}

.placeholder-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.placeholder-section {
  background: rgba(0, 20, 0, 0.4);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 255, 0, 0.1);
}

.placeholder-line.section-title {
  width: 40%;
  height: 32px;
  margin-bottom: 2rem;
  background: rgba(0, 255, 0, 0.15);
}

.placeholder-line.text {
  height: 16px;
  margin: 1rem 0;
}

.placeholder-line.text.long {
  width: 90%;
}

.placeholder-line.text.medium {
  width: 75%;
}

.placeholder-line.text.short {
  width: 40%;
}

.placeholder-code-block {
  background: rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 255, 0, 0.1);
  margin: 1rem 0;
}

.placeholder-line.code {
  height: 16px;
  margin: 0.75rem 0;
  background: rgba(0, 255, 0, 0.08);
}

@media (max-width: 768px) {
  .loading-placeholder {
    padding: 1rem;
  }

  .placeholder-line.title-main {
    width: 90%;
    height: 36px;
  }

  .placeholder-meta {
    flex-wrap: wrap;
  }

  .placeholder-section {
    padding: 1rem;
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes shimmer {
  0% { opacity: 0.3; }
  50% { opacity: 0.5; }
  100% { opacity: 0.3; }
}

@keyframes pulse {
  0% { border-color: rgba(0, 255, 0, 0.1); }
  50% { border-color: rgba(0, 255, 0, 0.2); }
  100% { border-color: rgba(0, 255, 0, 0.1); }
}
