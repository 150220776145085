.paymentModal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  padding: 20px;
}

.paymentModal-container {
  background: #000;
  border: 1px solid #00ff00;
  border-radius: 12px;
  width: 100%;
  max-width: 700px;
  height: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  animation: paymentModal-borderPulse 2s infinite;
}

.paymentModal-header {
  position: relative;
  background: #000;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px 12px 0 0;
}

.paymentModal-closeBtn {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 0.4rem 0.8rem;
  font-family: 'Courier New', monospace;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentModal-closeBtn:hover {
  border-color: #ff0000;
  color: #ff0000;
  background: rgba(255, 0, 0, 0.1);
}

.paymentModal-closeBtn:hover {
  text-shadow: 0 0 8px rgba(255, 0, 0, 0.4);
}

.paymentModal-content {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  padding-bottom: 0;
}

.paymentModal-activateBtn {
  background: #00ff00;
  color: #000;
  width: 100%;
  padding: 1.2rem;
  border: none;
  border-radius: 0 0 12px 12px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
}

@media (max-width: 768px) {
  .paymentModal-wrapper {
    padding: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    background: #000;
  }

  .paymentModal-container {
    width: 100vw;
    height: 100vh;
    max-height: none;
    max-width: none;
    margin: 0;
    border: none;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    animation: none;
    display: flex;
    flex-direction: column;
  }

  .paymentModal-header {
    padding: 1rem;
    border-radius: 0;
    background: #000;
    border-bottom: 1px solid rgba(0, 255, 0, 0.2);
  }

  .paymentModal-content {
    flex: 1;
    padding: 1rem;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .paymentModal-planSelector {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-bottom: 1.5rem;
  }

  .paymentModal-planOption {
    width: 100%;
    margin: 0;
  }

  .paymentModal-activateBtn {
    width: 100vw;
    border-radius: 0;
    padding: 1.2rem;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;
  }

  .paymentModal-content {
    padding-bottom: calc(1.2rem + 60px);
  }

  .paymentModal-closeBtn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}

@supports (padding: max(0px)) {
  @media (max-width: 768px) {
    .paymentModal-container {
      padding-top: max(1rem, env(safe-area-inset-top));
      padding-bottom: max(1rem, env(safe-area-inset-bottom));
    }
    
    .paymentModal-activateBtn {
      padding-bottom: max(1.2rem, env(safe-area-inset-bottom));
    }
  }
}

.paymentModal-content::-webkit-scrollbar {
  width: 8px;
}

.paymentModal-content::-webkit-scrollbar-track {
  background: rgba(0, 255, 0, 0.1);
  border-radius: 4px;
}

.paymentModal-content::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 0, 0.3);
  border-radius: 4px;
}

.paymentModal-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 255, 0, 0.5);
}

.paymentModal-header h2 {
  color: #00ff00;
  font-size: 1.8rem;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  margin: 0;
}

.paymentModal-cursor {
  animation: paymentModal-blink 1s infinite;
}

.paymentModal-planSelector {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.paymentModal-planOption {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 8px;
  padding: 1rem;
  color: #00ff00;
  cursor: pointer;
  transition: all 0.3s ease;
}

.paymentModal-planOption--active {
  background: rgba(0, 255, 0, 0.1);
  border-color: #00ff00;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.2);
}

.paymentModal-planDuration {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.paymentModal-planPrice {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Courier New', monospace;
}

.paymentModal-planDiscount {
  font-size: 0.8rem;
  color: #ffff00;
  margin-top: 0.5rem;
}

.paymentModal-summary {
  background: rgba(0, 255, 0, 0.05);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.paymentModal-summaryHeader {
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.paymentModal-summaryRow {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0;
  border-bottom: 1px solid rgba(0, 255, 0, 0.1);
  font-family: 'Courier New', monospace;
}

.paymentModal-summaryRow span:first-child {
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.9rem;
}

.paymentModal-summaryRow span:last-child {
  color: #00ff00;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  background: rgba(0, 255, 0, 0.05);
}

.paymentModal-summaryRow--total {
  border-bottom: none;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 255, 0, 0.2);
}

.paymentModal-summaryRow--total span:first-child {
  color: #00ff00;
  font-size: 1rem;
}

.paymentModal-summaryRow--total span:last-child {
  color: #00ff00;
  font-size: 1.1rem;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
}

.paymentModal-summaryRow--savings span:last-child {
  color: #ffff00;
  background: rgba(255, 255, 0, 0.05);
}

.paymentModal-payment {
  text-align: center;
  margin-bottom: 2rem;
}

.paymentModal-cryptoIcon {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: #00ff00;
}

.paymentModal-paymentNote {
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.8rem;
  margin: 1rem 0;
}

.paymentModal-cancelNote {
  text-align: center;
  color: rgba(0, 255, 0, 0.5);
  font-size: 0.8rem;
  margin: 1rem 0;
}

.paymentModal-learnMore {
  display: block;
  text-align: center;
  color: #00ff00;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 2rem;
}

@keyframes paymentModal-borderPulse {
  0% { box-shadow: 0 0 10px rgba(0, 255, 0, 0.2); }
  50% { box-shadow: 0 0 20px rgba(0, 255, 0, 0.4); }
  100% { box-shadow: 0 0 10px rgba(0, 255, 0, 0.2); }
}

@keyframes paymentModal-blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.paymentModal-paymentHelp {
  background: transparent;
  border: none;
  color: rgba(0, 255, 0, 0.7);
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  transition: all 0.3s ease;
  font-family: 'Courier New', monospace;
}

.paymentModal-paymentHelp:hover {
  color: #00ff00;
  text-shadow: 0 0 8px rgba(0, 255, 0, 0.4);
}

.paymentModal-paymentHelp::before {
  content: '[>]';
  margin-right: 0.5rem;
  color: #00ff00;
  opacity: 0.8;
}

.paymentModal-paymentHelp:hover::before {
  animation: paymentModal-arrowBlink 1s infinite;
}

@keyframes paymentModal-arrowBlink {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.4; }
}

@media (max-width: 480px) {
  .paymentModal-planSelector {
    grid-template-columns: 1fr;
}
}

