.projectAdminPage-container {
  padding: 2rem;
  background: #1a1a1a;
  min-height: 100vh;
  margin-top: 60px;
}

.projectAdminPage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.2);
}

.projectAdminPage-title {
  color: #00ff00;
  font-size: 1.8rem;
  font-family: 'Courier New', monospace;
}

.projectAdminPage-createBtn {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid #00ff00;
  color: #00ff00;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: 'Courier New', monospace;
  transition: all 0.3s ease;
}

.projectAdminPage-createBtn:hover {
  background: rgba(0, 255, 0, 0.2);
}

.projectAdminPage-list {
  display: grid;
  gap: 1rem;
}

.projectAdminPage-item {
  display: flex;
  background: #2a2a2a;
  border: 1px solid rgba(0, 255, 0, 0.2);
  padding: 1rem;
  border-radius: 4px;
  gap: 1rem;
  transition: all 0.3s ease;
}

.projectAdminPage-item:hover {
  border-color: #00ff00;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.1);
}

.projectAdminPage-status {
  flex-shrink: 0;
}

.projectAdminPage-statusBadge {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-family: 'Courier New', monospace;
}

.projectAdminPage-statusBadge.active {
  background: rgba(0, 255, 0, 0.1);
  color: #00ff00;
}

.projectAdminPage-statusBadge.inactive {
  background: rgba(255, 0, 0, 0.1);
  color: #ff0000;
}

.projectAdminPage-content {
  flex-grow: 1;
}

.projectAdminPage-name {
  color: #00ff00;
  margin: 0 0 0.5rem 0;
  font-family: 'Courier New', monospace;
}

.projectAdminPage-meta {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  font-family: 'Courier New', monospace;
  color: rgba(0, 255, 0, 0.7);
}

.projectAdminPage-description {
  color: #888;
  font-size: 0.9rem;
}

.projectAdminPage-actions {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.projectAdminPage-editBtn,
.projectAdminPage-deleteBtn {
  background: none;
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.projectAdminPage-editBtn:hover {
  background: rgba(0, 255, 0, 0.1);
}

.projectAdminPage-deleteBtn {
  color: #ff4444;
  border-color: rgba(255, 68, 68, 0.2);
}

.projectAdminPage-deleteBtn:hover {
  background: rgba(255, 68, 68, 0.1);
}

.projectAdminPage-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.projectAdminPage-modalContent {
  background: #2a2a2a;
  padding: 2rem;
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
}

.projectAdminPage-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.projectAdminPage-formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.projectAdminPage-label {
  color: #00ff00;
  font-family: 'Courier New', monospace;
}

.projectAdminPage-input,
.projectAdminPage-textarea {
  background: #1a1a1a;
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #fff;
  padding: 0.5rem;
  font-family: 'Courier New', monospace;
}

.projectAdminPage-textarea {
  min-height: 100px;
  resize: vertical;
}

.projectAdminPage-checkbox {
  width: 20px;
  height: 20px;
  accent-color: #00ff00;
}

.projectAdminPage-submitBtn {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid #00ff00;
  color: #00ff00;
  padding: 0.5rem;
  cursor: pointer;
  font-family: 'Courier New', monospace;
  margin-top: 1rem;
}

.projectAdminPage-submitBtn:hover {
  background: rgba(0, 255, 0, 0.2);
}