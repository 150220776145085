:root {
  --enterprise-primary: var(--home-enterprise-primary);
  --enterprise-secondary: #2a3f5f;
  --enterprise-accent: #00ff9d;
  --enterprise-dark: #0a192f;
  --enterprise-text: #e0e0e0;
  --enterprise-glow: 0 0 10px var(--enterprise-accent);
}

/* Container & Layout */
.xnenter-container {
  background-color: var(--enterprise-dark);
  color: var(--enterprise-text);
  min-height: 100vh;
  padding: 4rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

/* Header Section Enhanced */
.xnenter-header {
  position: relative;
  border: 1px solid var(--enterprise-primary);
  padding: 5rem 3rem;
  margin: 2rem 0 6rem;
  background: linear-gradient(
    165deg,
    rgba(10, 25, 47, 0.95) 0%,
    rgba(42, 63, 95, 0.3) 100%
  );
  box-shadow: var(--enterprise-glow);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  overflow: hidden;
}

/* Декоративные углы */
.xnenter-header::before,
.xnenter-header::after,
.xnenter-header-corners::before,
.xnenter-header-corners::after {
  content: '';
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--enterprise-accent);
  opacity: 0.7;
  transition: all 0.3s ease;
}

.xnenter-header::before { top: 1rem; left: 1rem; border-right: none; border-bottom: none; }
.xnenter-header::after { top: 1rem; right: 1rem; border-left: none; border-bottom: none; }
.xnenter-header-corners::before { bottom: 1rem; left: 1rem; border-right: none; border-top: none; }
.xnenter-header-corners::after { bottom: 1rem; right: 1rem; border-left: none; border-top: none; }

.xnenter-header:hover::before,
.xnenter-header:hover::after,
.xnenter-header:hover .xnenter-header-corners::before,
.xnenter-header:hover .xnenter-header-corners::after {
  width: 3rem;
  height: 3rem;
  opacity: 1;
}

/* Фоновые иконки */
.xnenter-header-icons {
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.xnenter-header-icon {
  position: absolute;
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.1;
  transition: all 0.5s ease;
  font-size: 1.2rem;
}

.xnenter-header-icon:nth-child(1) { top: 15%; left: 10%; content: '⌘'; }
.xnenter-header-icon:nth-child(2) { top: 35%; right: 15%; content: '⎔'; }
.xnenter-header-icon:nth-child(3) { bottom: 20%; left: 18%; content: '⎈'; }
.xnenter-header-icon:nth-child(4) { bottom: 35%; right: 20%; content: '⌬'; }
.xnenter-header-icon:nth-child(5) { top: 50%; left: 25%; content: '◈'; }
.xnenter-header-icon:nth-child(6) { bottom: 45%; right: 8%; content: '⟡'; }

.xnenter-header:hover .xnenter-header-icon {
  opacity: 0.15;
  transform: rotate(180deg) scale(1.2);
}

/* Заголовок */
.xnenter-typing-text {
  font-family: 'VT323', monospace;
  font-size: 4.5rem;
  color: var(--enterprise-accent);
  margin-bottom: 0.5rem;
  line-height: 1.2;
  text-align: center;
  position: relative;
  max-width: 900px;
  text-shadow: 0 0 15px rgba(0, 255, 157, 0.3);
}

/* Описание */
.xnenter-header-description {
  font-size: 1.3rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  color: var(--enterprise-text);
  position: relative;
  padding: 2rem;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  background: rgba(10, 25, 47, 0.5);
}

.xnenter-header-description:hover {
  border-color: var(--enterprise-primary);
  background: rgba(0, 255, 157, 0.03);
  transform: translateY(-2px);
}

/* Бейдж */
.xnenter-header-badge {
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--enterprise-accent);
  color: var(--enterprise-accent);
  font-family: 'VT323', monospace;
  font-size: 1.1rem;
  opacity: 0.8;
  transition: all 0.3s ease;
  background: rgba(10, 25, 47, 0.8);
}

.xnenter-header-badge:hover {
  opacity: 1;
  transform: scale(1.05);
  background: rgba(0, 255, 157, 0.1);
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
}

/* Адаптивность */
@media (max-width: 1024px) {
  .xnenter-header {
    padding: 4rem 2rem;
    margin: 1rem 0 4rem;
  }

  .xnenter-typing-text {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .xnenter-header {
    padding: 3rem 1.5rem;
    margin: 0.5rem 0 3rem;
  }

  .xnenter-typing-text {
    font-size: 2.5rem;
  }

  .xnenter-header-description {
    font-size: 1.1rem;
    padding: 1.5rem;
  }

  .xnenter-header-badge {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .xnenter-header {
    padding: 2rem 1rem;
  }

  .xnenter-typing-text {
    font-size: 2rem;
  }

  .xnenter-header-description {
    font-size: 1rem;
    padding: 1rem;
  }
}

/* Services Section Enhanced */
.xnenter-services-section {
  position: relative;
  margin: 8rem 0;
  padding: 2rem 0;
}

.xnenter-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 3rem;
  position: relative;
}

/* ASCII Art Background */
.xnenter-services-ascii {
  position: absolute;
  top: -2rem;
  left: 2rem;
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.1;
  font-size: 0.8rem;
  white-space: pre;
  pointer-events: none;
  user-select: none;
}

.xnenter-service-card {
  border: 1px solid var(--enterprise-primary);
  background: rgba(10, 25, 47, 0.8);
  padding: 2.5rem;
  position: relative;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  backdrop-filter: blur(5px);
}

.xnenter-service-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 48%,
    var(--enterprise-accent) 50%,
    transparent 52%
  );
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
}

.xnenter-service-card:hover::before {
  opacity: 0.1;
}

/* Service Card Header */
.xnenter-service-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  position: relative;
}

.xnenter-service-icon-wrapper {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xnenter-service-icon {
  font-family: monospace;
  font-size: 2rem;
  color: var(--enterprise-accent);
  position: relative;
  z-index: 1;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.xnenter-service-icon-bg {
  position: absolute;
  inset: 0;
  opacity: 0.3;
  transition: all 0.3s ease;
}

.xnenter-service-card:hover .xnenter-service-icon-bg {
  transform: rotate(45deg);
  opacity: 0.5;
}

.xnenter-service-title {
  font-family: 'VT323', monospace;
  font-size: 1.8rem;
  color: var(--enterprise-accent);
  margin: 0;
  flex-grow: 1;
}

/* Service Content */
.xnenter-service-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--enterprise-text);
  position: relative;
  padding-left: 1rem;
  border-left: 1px solid var(--enterprise-primary);
  margin: 1rem 0;
  transition: all 0.3s ease;
}

.xnenter-service-card:hover .xnenter-service-description {
  border-left-color: var(--enterprise-accent);
  padding-left: 1.5rem;
}

/* Service Stats */
.xnenter-service-stats {
  margin-top: auto;
  padding: 1rem;
  background: rgba(0, 255, 157, 0.05);
  border: 1px solid transparent;
  font-family: monospace;
  transition: all 0.3s ease;
  position: relative;
}

.xnenter-service-stats::before {
  content: '[stats]';
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  background: var(--enterprise-dark);
  padding: 0 0.5rem;
  color: var(--enterprise-accent);
  font-size: 0.9rem;
  opacity: 0.8;
}

.xnenter-service-card:hover .xnenter-service-stats {
  border-color: var(--enterprise-accent);
  background: rgba(0, 255, 157, 0.08);
}

/* Decorative Elements */
.xnenter-service-decorative {
  position: absolute;
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.5;
  font-size: 0.8rem;
  pointer-events: none;
}

.xnenter-service-decorative.top-left { top: 1rem; left: 1rem; }
.xnenter-service-decorative.top-right { top: 1rem; right: 1rem; }
.xnenter-service-decorative.bottom-left { bottom: 1rem; left: 1rem; }
.xnenter-service-decorative.bottom-right { bottom: 1rem; right: 1rem; }

@media (max-width: 768px) {
  .xnenter-services-ascii {
    display: none;
  }
  
  .xnenter-service-card {
    min-height: 320px;
    padding: 2rem;
  }
}

/* Projects Section Enhanced */
.xnenter-projects-section {
  position: relative;
  margin: 8rem 0;
  padding: 2rem 0;
}

.xnenter-projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 3rem;
  margin-top: 3rem;
  position: relative;
}

/* ASCII Art Header с глитч-эффектом */
.xnenter-projects-ascii {
  position: absolute;
  top: -3rem;
  right: 2rem;
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.1;
  font-size: 0.8rem;
  white-space: pre;
  pointer-events: none;
  user-select: none;
  transform: perspective(500px) rotateX(10deg);
  animation: ascii-glitch 10s infinite;
}

@keyframes ascii-glitch {
  0%, 100% { transform: perspective(500px) rotateX(10deg) translateY(0); }
  98% { transform: perspective(500px) rotateX(10deg) translateY(0); }
  99% { transform: perspective(500px) rotateX(10deg) translateY(-5px); }
}

/* Project Card с улучшенными эффектами */
.xnenter-project-card {
  border: 1px solid var(--enterprise-primary);
  background: rgba(10, 25, 47, 0.9);
  padding: 2.5rem;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(5px);
  isolation: isolate;
}

/* Декоративные углы */
.xnenter-project-card::before,
.xnenter-project-card::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid var(--enterprise-accent);
  opacity: 0.5;
  transition: all 0.3s ease;
}

.xnenter-project-card::before {
  top: -1px;
  left: -1px;
  border-right: none;
  border-bottom: none;
}

.xnenter-project-card::after {
  bottom: -1px;
  right: -1px;
  border-left: none;
  border-top: none;
}

.xnenter-project-card:hover::before,
.xnenter-project-card:hover::after {
  width: 25px;
  height: 25px;
  opacity: 1;
}

/* Project Icon */
.xnenter-project-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-family: monospace;
  position: relative;
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.8;
}

.xnenter-project-card:hover .xnenter-project-icon {
  transform: rotate(90deg);
  opacity: 1;
}

/* Metric Icon */
.xnenter-metric-icon {
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.8;
  transition: all 0.3s ease;
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xnenter-project-card:hover .xnenter-metric-icon {
  opacity: 1;
  transform: scale(1.1);
}

/* Project Header */
.xnenter-project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px dashed var(--enterprise-primary);
  position: relative;
}

.xnenter-project-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'VT323', monospace;
  font-size: 1.8rem;
  color: var(--enterprise-accent);
}

.xnenter-project-network {
  padding: 0.4rem 1.2rem;
  border: 1px solid var(--enterprise-accent);
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--enterprise-accent);
  position: relative;
  background: rgba(0, 255, 157, 0.05);
  transition: all 0.3s ease;
}

.xnenter-project-network::before {
  content: '[';
  position: absolute;
  left: -10px;
  opacity: 0;
  transition: all 0.3s ease;
}

.xnenter-project-network::after {
  content: ']';
  position: absolute;
  right: -10px;
  opacity: 0;
  transition: all 0.3s ease;
}

.xnenter-project-card:hover .xnenter-project-network::before {
  left: -15px;
  opacity: 1;
}

.xnenter-project-card:hover .xnenter-project-network::after {
  right: -15px;
  opacity: 1;
}

/* Project Description */
.xnenter-project-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--enterprise-text);
  margin-bottom: 2rem;
  padding-left: 1rem;
  border-left: 2px solid transparent;
  transition: all 0.3s ease;
}

.xnenter-project-card:hover .xnenter-project-description {
  border-left-color: var(--enterprise-accent);
  padding-left: 1.5rem;
}

/* Metrics Grid */
.xnenter-project-metrics {
  background: rgba(10, 25, 47, 0.5);
  padding: 1.5rem;
  margin: 2rem 0;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  position: relative;
}

.xnenter-project-metrics::before {
  content: '{ metrics }';
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  background: var(--enterprise-dark);
  padding: 0 0.5rem;
  color: var(--enterprise-accent);
  font-family: monospace;
  font-size: 0.9rem;
  opacity: 0.8;
}

.xnenter-project-card:hover .xnenter-project-metrics {
  border-color: var(--enterprise-accent);
  background: rgba(0, 255, 157, 0.03);
}

.xnenter-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.2rem;
}

.xnenter-metric-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem;
  border-left: 2px solid var(--enterprise-primary);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.xnenter-metric-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background: var(--enterprise-accent);
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.xnenter-project-card:hover .xnenter-metric-item::before {
  transform: translateY(0);
}

/* Значения метрик */
.xnenter-metric-value {
  margin-left: auto;
  color: var(--enterprise-accent);
  font-family: monospace;
  position: relative;
  padding: 0.2rem 0.5rem;
}

.xnenter-metric-value::before {
  content: '[';
  position: absolute;
  left: -5px;
  opacity: 0;
  transition: all 0.3s ease;
}

.xnenter-metric-value::after {
  content: ']';
  position: absolute;
  right: -5px;
  opacity: 0;
  transition: all 0.3s ease;
}

.xnenter-project-card:hover .xnenter-metric-value::before,
.xnenter-project-card:hover .xnenter-metric-value::after {
  opacity: 0.5;
}

/* Отзывы с улучшенным дизайном */
.xnenter-testimonial-content {
  font-style: italic;
  line-height: 1.6;
  color: var(--enterprise-text);
  opacity: 0.9;
  position: relative;
  padding: 0 1.5rem;
}

.xnenter-quote-icon {
  color: var(--enterprise-accent);
  opacity: 0.5;
  font-family: monospace;
  position: absolute;
  transition: all 0.3s ease;
}

.xnenter-quote-icon:first-child {
  left: 0;
  top: 0;
}

.xnenter-quote-icon:last-child {
  right: 0;
  bottom: 0;
}

.xnenter-project-card:hover .xnenter-quote-icon {
  opacity: 0.8;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .xnenter-projects-ascii {
    display: none;
  }
  
  .xnenter-project-card {
    padding: 1.5rem;
  }
  
  .xnenter-metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .xnenter-project-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .xnenter-project-network {
    align-self: flex-start;
  }
}

/* ROI Stats Section Enhanced */
.xnenter-roi-stats-section {
  margin: 8rem 0;
  padding: 4rem 0;
  position: relative;
  perspective: 1500px;
}

.xnenter-roi-stats-container {
  position: relative;
  z-index: 1;
}

.xnenter-roi-stats-container::before {
  content: '// system performance analysis';
  position: absolute;
  top: -2rem;
  left: 0;
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.5;
  font-size: 0.9rem;
}

.xnenter-roi-stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin: 3rem 0;
  position: relative;
  transform-style: preserve-3d;
}

/* Floating Windows Style */
.xnenter-roi-stat-card {
  background: rgba(10, 25, 47, 0.95);
  padding: 2.5rem;
  position: relative;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
  transform: translateZ(0) rotateX(0);
  z-index: 1;
}

/* Window Header */
.xnenter-stat-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: rgba(0, 255, 157, 0.05);
  border-bottom: 1px solid var(--enterprise-primary);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--enterprise-accent);
  opacity: 0.8;
  transform: translateZ(10px);
}

.xnenter-stat-header::before {
  content: '[ metrics_window ]';
  margin-right: auto;
}

.xnenter-stat-header::after {
  content: '[ * ]';
}

/* Stat Content */
.xnenter-stat-content {
  margin-top: 30px;
  position: relative;
  z-index: 1;
  transform: translateZ(20px);
}

.xnenter-stat-icon {
  font-size: 2rem;
  color: var(--enterprise-accent);
  margin-bottom: 1.5rem;
  opacity: 0.8;
  transition: all 0.3s ease;
  position: relative;
  transform: translateZ(30px);
}

.xnenter-stat-value {
  font-size: 3.5rem;
  font-family: 'VT323', monospace;
  color: var(--enterprise-accent);
  margin: 1rem 0;
  position: relative;
  display: inline-block;
  transform: translateZ(40px);
  text-shadow: 0 0 15px rgba(0, 255, 157, 0.3);
}

.xnenter-stat-label {
  font-family: monospace;
  color: var(--enterprise-text);
  opacity: 0.9;
  margin-top: 1rem;
  position: relative;
  transform: translateZ(30px);
}

/* Hover Effects */
.xnenter-roi-stat-card:hover {
  transform: translateZ(50px) rotateX(5deg);
}

.xnenter-roi-stat-card::before {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid var(--enterprise-primary);
  transition: all 0.3s ease;
  transform: translateZ(-1px);
}

.xnenter-roi-stat-card:hover::before {
  border-color: var(--enterprise-accent);
  box-shadow: 
    0 0 30px rgba(0, 255, 157, 0.1),
    0 0 60px rgba(0, 255, 157, 0.05);
}

/* Highlighted Words */
.xnenter-highlight {
  color: var(--enterprise-accent);
  position: relative;
  display: inline-block;
  transition: all 0.3s ease;
}

.xnenter-highlight::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 1px;
  background: var(--enterprise-accent);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.xnenter-roi-stat-card:hover .xnenter-highlight::after {
  transform: scaleX(1);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .xnenter-roi-stats-grid {
    grid-template-columns: repeat(2, 1fr);
    perspective: none;
  }
  
  .xnenter-roi-stat-card:hover {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .xnenter-roi-stats-grid {
    grid-template-columns: 1fr;
  }
  
  .xnenter-roi-stat-card {
    padding: 2rem;
  }
  
  .xnenter-stat-value {
    font-size: 3rem;
  }
}

/* Steps Section Enhanced */
.xnenter-steps-section {
  margin: 8rem 0;
  position: relative;
  perspective: 1200px;
}

.xnenter-steps-ascii {
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.1;
  font-size: 0.8rem;
  white-space: pre;
  pointer-events: none;
  user-select: none;
}

.xnenter-steps-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin: 4rem 0;
  position: relative;
  transform-style: preserve-3d;
}

/* Floating Card Style */
.xnenter-step-card {
  border: 1px solid var(--enterprise-primary);
  background: linear-gradient(
    135deg,
    rgba(10, 25, 47, 0.95) 0%,
    rgba(10, 25, 47, 0.85) 100%
  );
  box-shadow: 
    inset 0 0 30px rgba(0, 255, 157, 0.05),
    0 0 30px rgba(0, 0, 0, 0.2);
  padding: 2.5rem;
  position: relative;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
  transform: translateZ(0);
  overflow: visible;
  isolation: isolate;
}

/* Декоративные углы */
.xnenter-step-card::before,
.xnenter-step-card::after,
.xnenter-step-corners::before,
.xnenter-step-corners::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid var(--enterprise-accent);
  opacity: 0.5;
  transition: all 0.3s ease;
}

.xnenter-step-card::before { top: -1px; left: -1px; border-right: none; border-bottom: none; }
.xnenter-step-card::after { top: -1px; right: -1px; border-left: none; border-bottom: none; }
.xnenter-step-corners::before { bottom: -1px; left: -1px; border-right: none; border-top: none; }
.xnenter-step-corners::after { bottom: -1px; right: -1px; border-left: none; border-top: none; }

/* Glowing Number */
.xnenter-step-number {
  position: absolute;
  top: -1rem;
  right: -1rem;
  padding: 0.5rem 1rem;
  background: var(--enterprise-dark);
  border: 1px solid var(--enterprise-accent);
  color: var(--enterprise-accent);
  font-family: 'VT323', monospace;
  font-size: 1.2rem;
  opacity: 0.8;
  transform: translateZ(20px);
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.5);
  z-index: 2;
  transition: all 0.3s ease;
}

.xnenter-step-card:hover .xnenter-step-number {
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.3);
}

/* Tooltip */
.xnenter-step-tooltip {
  position: absolute;
  bottom: -4.5rem;
  left: 0;
  right: 0;
  background: rgba(10, 25, 47, 0.95);
  border: 1px solid var(--enterprise-accent);
  padding: 1rem;
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--enterprise-accent);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 10;
  backdrop-filter: blur(5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.xnenter-step-tooltip::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--enterprise-accent);
}

.xnenter-step-card:hover .xnenter-step-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Декоративный фон */
.xnenter-step-card-bg {
  position: absolute;
  inset: 0;
  background: 
    linear-gradient(45deg, transparent 48%, var(--enterprise-accent) 50%, transparent 52%),
    linear-gradient(-45deg, transparent 48%, var(--enterprise-accent) 50%, transparent 52%);
  background-size: 30px 30px;
  opacity: 0.03;
  transition: all 0.3s ease;
}

.xnenter-step-card:hover .xnenter-step-card-bg {
  opacity: 0.05;
  background-size: 40px 40px;
}

/* Hover Effects */
.xnenter-step-card:hover {
  transform: translateZ(30px);
  box-shadow: 
    0 0 30px rgba(0, 255, 157, 0.1),
    0 0 60px rgba(0, 255, 157, 0.05);
}

.xnenter-step-card:hover::before,
.xnenter-step-card:hover::after,
.xnenter-step-card:hover .xnenter-step-corners::before,
.xnenter-step-card:hover .xnenter-step-corners::after {
  width: 25px;
  height: 25px;
  opacity: 1;
}

/* Step Content */
.xnenter-step-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  transform: translateZ(30px);
}

.xnenter-step-icon {
  font-family: monospace;
  font-size: 1.5rem;
  color: var(--enterprise-accent);
  opacity: 0.8;
  transition: all 0.3s ease;
  position: relative;
}

.xnenter-step-content {
  position: relative;
  transform: translateZ(20px);
}

.xnenter-step-title {
  font-family: 'VT323', monospace;
  font-size: 1.8rem;
  color: var(--enterprise-accent);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.xnenter-step-title::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 1px;
  background: var(--enterprise-accent);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.xnenter-step-card:hover .xnenter-step-title::after {
  transform: scaleX(1);
}

.xnenter-step-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--enterprise-text);
  opacity: 0.9;
  position: relative;
  padding-left: 1rem;
  border-left: 2px solid transparent;
  transition: all 0.3s ease;
}

/* Connection Lines */
.xnenter-step-card::after {
  content: '→';
  position: absolute;
  top: 50%;
  right: -1.5rem;
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.5;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  z-index: 2;
}

.xnenter-step-card:last-child::after {
  display: none;
}

/* Progress Line */
.xnenter-steps-progress {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    var(--enterprise-primary) 50%,
    transparent 50%
  );
  background-size: 20px 1px;
  opacity: 0.3;
  transform: translateY(-50%);
  z-index: -1;
  animation: progress-line 20s linear infinite;
}

@keyframes progress-line {
  from { background-position: 0 0; }
  to { background-position: 40px 0; }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .xnenter-steps-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .xnenter-step-card::after {
    display: none;
  }
  
  .xnenter-steps-progress {
    display: none;
  }
}

@media (max-width: 768px) {
  .xnenter-steps-container {
    grid-template-columns: 1fr;
    perspective: none;
  }

  .xnenter-steps-ascii {
    display: none;
  }

  .xnenter-step-card:hover {
    transform: translateY(-5px);
  }
}

/* Pricing Section Enhanced */
.xnenter-pricing-section {
  position: relative;
  margin: 8rem 0;
  padding: 4rem 0;
  perspective: 1200px;
}

.xnenter-pricing-ascii {
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.1;
  font-size: 0.8rem;
  white-space: pre;
  pointer-events: none;
  user-select: none;
}

.xnenter-pricing-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  margin: 4rem 0;
  position: relative;
}

/* Pricing Card */
.xnenter-pricing-card {
  position: relative;
  padding: 3rem;
  background: linear-gradient(
    165deg,
    rgba(10, 25, 47, 0.95) 0%,
    rgba(10, 25, 47, 0.85) 100%
  );
  transform-style: preserve-3d;
  transform: translateZ(0);
  overflow: visible;
  isolation: isolate;
}

/* System Status */
.xnenter-pricing-status {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-family: monospace;
  color: var(--enterprise-accent);
  opacity: 0.8;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--enterprise-accent);
  background: rgba(0, 255, 157, 0.05);
  animation: statusPulse 2s infinite;
}

@keyframes statusPulse {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.4; }
}

/* Card Header */
.xnenter-pricing-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px dashed var(--enterprise-primary);
}

.xnenter-pricing-icon-container {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xnenter-pricing-icon {
  font-family: monospace;
  font-size: 1.8rem;
  color: var(--enterprise-accent);
  opacity: 0.8;
  transition: all 0.4s ease;
}

.xnenter-pricing-card:hover .xnenter-pricing-icon {
  transform: rotate(180deg);
  opacity: 1;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.5);
}

.xnenter-pricing-title {
  font-family: 'VT323', monospace;
  font-size: 2.2rem;
  color: var(--enterprise-accent);
  margin: 0;
  position: relative;
}

/* Calculator Container */
.xnenter-calculator {
  background: rgba(10, 25, 47, 0.98);
  border: 1px solid var(--enterprise-primary);
  padding: 2rem;
  position: relative;
  font-family: 'VT323', monospace;
}

/* Title Bar */
.xnenter-calculator::before {
  content: '[ Cost Analyzer ]';
  position: absolute;
  top: -0.8rem;
  left: 1.2rem;
  background: var(--enterprise-dark);
  padding: 0.3rem 1rem;
  color: var(--enterprise-accent);
  font-size: 1rem;
  border: 1px solid var(--enterprise-accent);
}

/* Calculator Sections */
.xnenter-calc-section {
  margin-bottom: 2rem;
}

.xnenter-calc-label {
  font-size: 1.1rem;
  color: var(--enterprise-accent);
  margin-bottom: 1rem;
  opacity: 0.9;
  border-left: 2px solid var(--enterprise-accent);
  padding-left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Calculator Options */
.xnenter-calc-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}

.xnenter-calc-option {
  background: rgba(10, 25, 47, 0.98);
  border: 1px solid var(--enterprise-primary);
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

/* Button Press Effect */
.xnenter-calc-option:active {
  transform: scale(0.98);
  box-shadow: inset 0 0 15px rgba(0, 255, 157, 0.2);
}

/* Active State with Matrix Effect */
.xnenter-calc-option.active {
  border-color: var(--enterprise-accent);
  background: rgba(0, 255, 157, 0.05);
  box-shadow: inset 0 0 15px rgba(0, 255, 157, 0.1);
}

.xnenter-calc-option.active::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    transparent 0%,
    rgba(0, 255, 157, 0.1) 50%,
    transparent 100%
  );
  animation: matrixRain 1s linear infinite;
}

@keyframes matrixRain {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(100%); }
}

/* Results Section */
.xnenter-calc-result {
  margin-top: 2rem;
  padding: 1.5rem;
  border: 1px solid var(--enterprise-primary);
  background: rgba(10, 25, 47, 0.95);
}

.xnenter-result-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.1rem;
  color: var(--enterprise-accent);
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed var(--enterprise-primary);
}

.xnenter-result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.2rem;
  margin-bottom: 0.8rem;
  background: rgba(10, 25, 47, 0.98);
  border: 1px solid var(--enterprise-primary);
  position: relative;
  overflow: hidden;
}

/* Glitch Effect for Value Updates */
@keyframes glitchText {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
}

.xnenter-result-value.updating {
  animation: 
    valueUpdate 0.3s ease,
    glitchText 0.2s ease;
}

/* Colors for different tiers */
.xnenter-calc-option[data-tier="basic"] .xnenter-option-icon { color: #45a6fe; }
.xnenter-calc-option[data-tier="standard"] .xnenter-option-icon { color: #4deeea; }
.xnenter-calc-option[data-tier="advanced"] .xnenter-option-icon { color: #74ee15; }
.xnenter-calc-option[data-tier="premium"] .xnenter-option-icon { color: #f000ff; }

/* Mainnet Model - Retro OS Style */
.xnenter-pricing-card.mainnet {
  background: rgba(10, 25, 47, 0.98);
  padding: 2rem;
  border: 1px solid var(--enterprise-primary);
  position: relative;
}

.xnenter-pricing-card.mainnet::before {
  content: '[ mainnet_pricing.exe ]';
  position: absolute;
  top: -0.7rem;
  left: 1rem;
  background: var(--enterprise-dark);
  padding: 0.1rem 0.5rem;
  color: var(--enterprise-accent);
  font-size: 0.8rem;
  border: 1px solid var(--enterprise-accent);
  font-family: monospace;
}

/* Features Grid */
.xnenter-pricing-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin: 1rem 0;
}

.xnenter-feature-item {
  padding: 0.5rem 0.8rem;
  background: rgba(10, 25, 47, 0.95);
  border: 1px solid var(--enterprise-primary);
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  height: 2rem;
}

.xnenter-feature-icon {
  color: var(--enterprise-accent);
  opacity: 0.8;
  font-size: 0.9rem;
  min-width: 16px;
}

/* Plans Container */
.xnenter-plans-container {
  margin-top: 1.5rem;
  display: grid;
  gap: 1rem;
}

.xnenter-plan-option {
  background: rgba(10, 25, 47, 0.95);
  padding: 1rem;
  position: relative;
  border: 1px solid var(--enterprise-primary);
}

/* Plan Header */
.xnenter-plan-header {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--enterprise-accent);
  margin-bottom: 0.8rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed var(--enterprise-primary);
}

.xnenter-plan-icon {
  opacity: 0.8;
  font-size: 0.9rem;
}

/* Plan Price */
.xnenter-plan-price {
  font-size: 1.8rem;
  color: var(--enterprise-accent);
  font-family: 'VT323', monospace;
  margin: 0.8rem 0;
  display: inline-flex;
  align-items: baseline;
  gap: 0.3rem;
}

.xnenter-plan-price span {
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Plan Features List */
.xnenter-plan-features {
  list-style: none;
  padding: 0;
  margin: 0.8rem 0;
}

.xnenter-plan-features li {
  padding: 0.4rem 0.8rem 0.4rem 1.5rem;
  margin: 0.3rem 0;
  font-family: monospace;
  font-size: 0.8rem;
  color: var(--enterprise-text);
  position: relative;
  background: rgba(10, 25, 47, 0.8);
  border: 1px solid transparent;
  transition: all 0.2s ease;
}

.xnenter-plan-features li::before {
  content: '>';
  position: absolute;
  left: 0.5rem;
  color: var(--enterprise-accent);
  opacity: 0.5;
}

/* Premium Plan */
.xnenter-plan-option.premium {
  background: linear-gradient(
    135deg,
    rgba(10, 25, 47, 0.98) 0%,
    rgba(0, 255, 157, 0.02) 100%
  );
}

.xnenter-plan-option.premium::before {
  content: '[ premium ]';
  position: absolute;
  top: -0.5rem;
  right: 0.8rem;
  background: var(--enterprise-dark);
  padding: 0.1rem 0.5rem;
  color: var(--enterprise-accent);
  font-family: monospace;
  font-size: 0.8rem;
  opacity: 0.8;
  border: 1px solid var(--enterprise-accent);
}

/* Hover Effects */
.xnenter-feature-item:hover {
  border-color: var(--enterprise-accent);
  transform: translateX(3px);
  background: rgba(0, 255, 157, 0.03);
}

.xnenter-feature-item:hover .xnenter-feature-icon {
  transform: rotate(90deg);
  opacity: 1;
}

.xnenter-plan-option:hover {
  border-color: var(--enterprise-accent);
  transform: translateY(-2px);
  background: rgba(0, 255, 157, 0.02);
}

.xnenter-plan-option:hover .xnenter-plan-header {
  border-bottom-color: var(--enterprise-accent);
}

.xnenter-plan-features li:hover {
  padding-left: 1.8rem;
  border-color: var(--enterprise-accent);
  background: rgba(0, 255, 157, 0.03);
}

.xnenter-plan-features li:hover::before {
  opacity: 1;
  left: 0.8rem;
}

/* Animation for Price */
.xnenter-plan-price {
  position: relative;
  transition: all 0.3s ease;
}

.xnenter-plan-price::before,
.xnenter-plan-price::after {
  position: absolute;
  opacity: 0;
  transition: all 0.2s ease;
}

.xnenter-plan-price::before {
  content: '[';
  left: -0.8rem;
}

.xnenter-plan-price::after {
  content: ']';
  right: -0.8rem;
}

.xnenter-plan-option:hover .xnenter-plan-price::before {
  opacity: 0.5;
  left: -0.5rem;
}

.xnenter-plan-option:hover .xnenter-plan-price::after {
  opacity: 0.5;
  right: -0.5rem;
}

.xnenter-plan-option:hover .xnenter-plan-price {
  text-shadow: 0 0 8px rgba(0, 255, 157, 0.3);
}

/* Custom Solution Section */
.xnenter-custom-pricing {
  margin-top: 3rem;
  position: relative;
}

.xnenter-custom-content {
  background: rgba(10, 25, 47, 0.98);
  border: 1px solid var(--enterprise-primary);
  padding: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.xnenter-custom-content::before {
  content: '[ custom_solution.exe ]';
  position: absolute;
  top: -0.7rem;
  left: 1rem;
  background: var(--enterprise-dark);
  padding: 0.1rem 0.5rem;
  color: var(--enterprise-accent);
  font-size: 0.8rem;
  border: 1px solid var(--enterprise-accent);
  font-family: monospace;
}

.xnenter-custom-icon {
  color: var(--enterprise-accent);
  font-size: 1.2rem;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.xnenter-custom-content h3 {
  font-family: 'VT323', monospace;
  font-size: 1.2rem;
  color: var(--enterprise-accent);
  margin: 0;
}

.xnenter-custom-content p {
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--enterprise-text);
  margin: 0.5rem 0;
}

.xnenter-contact-btn {
  margin-left: auto;
  background: transparent;
  border: 1px solid var(--enterprise-primary);
  color: var(--enterprise-accent);
  font-family: monospace;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.xnenter-btn-icon {
  opacity: 0.8;
  transition: all 0.2s ease;
}

/* Hover Effects */
.xnenter-custom-content:hover {
  border-color: var(--enterprise-accent);
  background: rgba(0, 255, 157, 0.02);
}

.xnenter-custom-content:hover .xnenter-custom-icon {
  transform: rotate(180deg);
  opacity: 1;
}

.xnenter-contact-btn:hover {
  border-color: var(--enterprise-accent);
  background: rgba(0, 255, 157, 0.05);
  transform: translateY(-2px);
}

.xnenter-contact-btn:hover .xnenter-btn-icon {
  transform: translateX(3px);
  opacity: 1;
}

/* Responsive */
@media (max-width: 768px) {
  .xnenter-custom-content {
    flex-direction: column;
    text-align: center;
    padding: 2rem 1rem;
  }

  .xnenter-contact-btn {
    margin: 1rem auto 0;
  }

  .xnenter-calc-options {
    grid-template-columns: 1fr;
  }
} 