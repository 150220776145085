.config-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: #000000;
  background-image: 
    radial-gradient(circle at 50% 50%, rgba(0, 255, 0, 0.03) 0%, transparent 50%),
    radial-gradient(circle at 80% 20%, rgba(0, 255, 0, 0.02) 0%, transparent 40%);
}

.config-section {
  padding: 2rem;
}

.config-form {
  margin-top: 2rem;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgba(0, 255, 0, 0.8);
  font-family: 'Anonymous Pro', monospace;
}

.label-icon {
  color: #00ff00;
}

.input-container {
  display: flex;
  align-items: center;
  background: rgba(0, 20, 0, 0.95);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  padding: 0.5rem;
}

.input-prefix {
  color: rgba(0, 255, 0, 0.6);
  margin-right: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
}

.config-input {
  flex: 1;
  background: transparent;
  border: none;
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  font-size: 1rem;
  outline: none;
}

.config-input::placeholder {
  color: rgba(0, 255, 0, 0.3);
}

.config-submit {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 1rem;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  transition: all 0.3s ease;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
}

.config-submit:hover:not(:disabled) {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.6);
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.1);
}

.config-submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.config-message {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
}

.config-message.success {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
}

.config-message.error {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: #ff0000;
}

.current-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: rgba(0, 255, 0, 0.05);
  border-radius: 4px;
  font-family: 'Anonymous Pro', monospace;
  color: rgba(0, 255, 0, 0.7);
  margin-bottom: 1rem;
}

.value-label {
  color: rgba(0, 255, 0, 0.5);
}

@media (max-width: 768px) {
  .config-container {
    padding: 1rem;
  }

  .config-section {
    padding: 1rem;
  }

  .config-submit {
    padding: 0.8rem;
  }
}

.terminal-nav-button {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.terminal-nav-button:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.4);
  transform: translateX(-2px);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.1);
}


