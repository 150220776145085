.interactive-editor-container {
  position: relative;
  width: 100%;
  z-index: 1;
}

.interactive-editor-textarea {
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  background: #1a1a1a;
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  line-height: 1.6;
  resize: vertical;
  position: relative;
  z-index: 1;
}

.formatting-toolbar {
  position: fixed;
  transform: translateX(-50%);
  background: #2a2a2a;
  border: 1px solid #00ff00;
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  pointer-events: all;
}

.formatting-toolbar button {
  background: none;
  border: 1px solid transparent;
  color: #00ff00;
  padding: 0.5rem;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.formatting-toolbar button:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.3);
}

.toolbar-icon {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
