.wiki-editor-page {
  padding: 2rem;
  color: #fff;
}

.wiki-editor-form {
  display: grid;
  gap: 2rem;
}

.wiki-metadata {
  display: grid;
  gap: 1rem;
  background: rgba(0, 255, 0, 0.05);
  padding: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 255, 0, 0.1);
}

.form-group {
  display: grid;
  gap: 0.5rem;
}

.title-input,
.slug-input,
.category-select,
.description-input {
  width: 100%;
  padding: 0.8rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
}

.description-input {
  min-height: 100px;
  resize: vertical;
}

.image-controls {
  display: flex;
  gap: 1rem;
}

.upload-btn,
.library-btn {
  padding: 0.8rem 1.5rem;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
  border-radius: 4px;
  cursor: pointer;
}

.wiki-content-editor textarea {
  width: 100%;
  min-height: 400px;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #fff;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  resize: vertical;
}

.wiki-editor-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.cancel-btn,
.save-btn {
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

.save-btn {
  background: rgba(0, 255, 0, 0.2);
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
}
