/* Обновляем основной контейнер */
.guide-editor-page {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  background: #1a1a1a;
  min-height: 100vh;
  margin-top: 60px;
}

/* Стили для формы редактирования */
.editor-form {
  flex: 1;
  max-width: 50%;
  overflow-y: auto;
  padding-right: 1rem;
}

/* Стили для предпросмотра */
.markdown-preview {
  position: sticky;
  top: 2rem;
  width: 50%;
  height: calc(100vh - 4rem);
  background: #2a2a2a;
  padding: 2rem;
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  overflow-y: auto;
}

.preview-header {
  position: sticky;
  top: 0;
  background: #2a2a2a;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
  z-index: 1;
}

/* Стили для метаданных */
.guide-metadata {
  background: rgba(0, 255, 0, 0.05);
  padding: 1.5rem;
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.guide-metadata input,
.guide-metadata select,
.guide-metadata textarea {
  width: 100%;
  padding: 0.8rem;
  background: #2a2a2a;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  margin-top: 0.5rem;
}

/* Стили для редактора секций */
.section-editor {
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  background: #2a2a2a;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  resize: vertical;
  line-height: 1.6;
}

.editor-toolbar {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.toolbar-button {
  padding: 0.5rem 1rem;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toolbar-button:hover {
  background: rgba(0, 255, 0, 0.2);
  border-color: #00ff00;
}

/* Стили для опций гайда */
.guide-options {
  background: rgba(0, 255, 0, 0.05);
  padding: 1.5rem;
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  margin: 2rem 0;
}

.options-group {
  display: flex;
  gap: 2rem;
}

.option-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00ff00;
  cursor: pointer;
}

.option-item input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #00ff00;
  background: #2a2a2a;
}

/* Стили для кнопок действий */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.save-btn,
.cancel-btn {
  padding: 0.8rem 1.5rem;
  font-family: 'Anonymous Pro', monospace;
  cursor: pointer;
  transition: all 0.3s ease;
}

.save-btn {
  background: rgba(0, 255, 0, 0.2);
  border: 1px solid #00ff00;
  color: #00ff00;
}

.cancel-btn {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  color: #ff4444;
}

.save-btn:hover {
  background: rgba(0, 255, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.2);
}

.cancel-btn:hover {
  background: rgba(255, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
}

.version-history {
  background: rgba(0, 255, 0, 0.05);
  padding: 1.5rem;
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
}

.version-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.1);
}

.version-info {
  display: flex;
  gap: 1rem;
  color: #00ff00;
}

.version-number {
  font-weight: bold;
}

.version-date {
  color: rgba(0, 255, 0, 0.7);
}

.version-editor {
  color: rgba(0, 255, 0, 0.6);
}

.restore-button {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.restore-button:hover {
  background: rgba(0, 255, 0, 0.2);
  border-color: #00ff00;
}

.tags-section {
  margin-top: 1rem;
}

.tags-input {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 0.6rem;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
  color: #00ff00;
}

.tag button {
  background: none;
  border: none;
  color: rgba(255, 0, 0, 0.7);
  cursor: pointer;
  padding: 0;
  font-size: 1.2em;
}

.tag button:hover {
  color: #ff0000;
}

.guide-sections {
  margin: 20px 0;
}

.section-container {
  margin-bottom: 20px;
}

.section-container h3 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.section-icon {
  margin-right: 10px;
}

.section-textarea {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  border: 1px solid #2a2a2a;
  background: #1a1a1a;
  color: #fff;
  border-radius: 4px;
}

/* Стили для работы с изображениями */
.image-controls {
  margin: 1rem 0 2rem;
  padding: 1.5rem;
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
}

.header-image-controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.header-image-controls input {
  flex: 1;
  padding: 0.8rem;
  background: #2a2a2a;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
}

.image-upload-buttons {
  display: flex;
  gap: 0.5rem;
}

.upload-button,
.library-button {
  padding: 0.8rem 1.5rem;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.upload-button:hover,
.library-button:hover {
  background: rgba(0, 255, 0, 0.2);
  border-color: #00ff00;
}

.header-image-preview {
  max-width: 100%;
  max-height: 200px;
  margin-top: 1rem;
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
  object-fit: contain;
}

.section-image-upload {
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
}

/* Модальное окно библиотеки изображений */
.image-library-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.image-item {
  position: relative;
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.image-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.image-item-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 8px;
  display: flex;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-item:hover .image-item-actions {
  opacity: 1;
}

.image-item-actions button {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.5);
  color: #00ff00;
  padding: 4px 8px;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  font-size: 0.9rem;
  transition: all 0.3s;
}

.image-item-actions button:hover {
  background: rgba(0, 255, 0, 0.1);
}

.version-history-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1a1a1a;
  padding: 2rem;
  border: 1px solid rgba(0, 255, 0, 0.3);
  border-radius: 4px;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1000;
}

.version-history-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.version-btn {
  padding: 0.8rem 1.5rem;
  font-family: 'Anonymous Pro', monospace;
  cursor: pointer;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  transition: all 0.3s ease;
}

.version-btn:hover {
  background: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.2);
}

/* Стили для справки по Markdown */
.markdown-help-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.markdown-help-content {
  background: #1a1a1a;
  border: 1px solid #00ff00;
  border-radius: 4px;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 1.5rem;
}

.markdown-help-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.2);
}

.markdown-help-header h2 {
  color: #00ff00;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: #00ff00;
  font-size: 1.5rem;
  cursor: pointer;
}

.markdown-help-section {
  margin-bottom: 2rem;
}

.markdown-help-section h3 {
  color: #00ff00;
  margin-bottom: 1rem;
}

.markdown-examples {
  display: grid;
  gap: 1rem;
}

.markdown-example {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  background: rgba(0, 255, 0, 0.05);
  border-radius: 4px;
}

.markdown-example code {
  padding: 0.5rem;
  background: #2a2a2a;
  border-radius: 4px;
  color: #00ff00;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  transition: background-color 0.2s;
}

.markdown-example code:hover {
  background: #3a3a3a;
}

.example-description {
  color: #b0b0b0;
  font-size: 0.9rem;
}

.guide-content {
  margin: 2rem 0;
  background: rgba(0, 255, 0, 0.05);
  padding: 1.5rem;
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
}

.guide-content h3 {
  color: #00ff00;
  margin-bottom: 1rem;
}
