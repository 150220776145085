/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&family=VT323&display=swap');

:root {
  /* Core palette */
  --home-bg-primary: #000D00;
  --home-bg-secondary: rgba(0, 17, 0, 0.7);
  --home-text-primary: #B4FF9F;
  --home-text-secondary: #77AD66;
  --home-accent-cyan: #31FFFF;
  --home-accent-yellow: #FFE164;
  --home-accent-red: #FF3131;
  
  /* Enterprise colors */
  --home-enterprise-primary: #5E5BD9;
  --home-enterprise-secondary: rgba(94, 91, 217, 0.1);
  
  /* Effects */
  --home-neon-glow: 0 0 15px rgba(180, 255, 159, 0.2);
  --home-text-glow: 0 0 3px currentColor;
}

/* Main container */
.xn-page-container {
  background: var(--home-bg-primary);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-text-primary);
}

/* Hero section */
.xn-hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  background: radial-gradient(circle at 50% 50%, var(--home-bg-secondary) 0%, var(--home-bg-primary) 100%);
  overflow: hidden;
}

.xn-hero-content {
  max-width: 1200px;
  width: 100%;
  padding: 2rem;
  position: relative;
  z-index: 2;
  backdrop-filter: blur(5px);
  border-radius: 8px;
}


.xn-hero-title {
  font-family: 'VT323', monospace;
  font-size: 4rem;
  line-height: 1.2;
  margin: 0;
  color: var(--home-text-primary);
  text-shadow: var(--home-neon-glow);
}

.xn-hero-title-changing {
  display: block;
  color: var(--home-accent-yellow);
  font-size: 3rem;
  margin-top: 0.5rem;
}

.xn-hero-description {
  margin: 2rem 0;
}

.xn-terminal-line {
  font-family: 'Anonymous Pro', monospace;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.xn-line-label {
  color: var(--home-accent-yellow);
  font-weight: bold;
}

.xn-line-item {
  display: inline-flex;
  align-items: center;
  gap: 0.35rem;
  color: var(--home-accent-cyan);
  transition: all 0.3s ease;
  padding: 0.2rem 0.4rem;
  border-radius: 2px;
}

.xn-line-item:hover {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-1px);
}

.xn-line-icon {
  color: var(--home-accent);
  font-size: 1.2rem;
  text-shadow: 0 0 5px var(--home-accent);
}

.xn-terminal-line.enterprise .xn-line-icon {
  color: var(--home-accent-yellow);
  text-shadow: 0 0 5px var(--home-accent-yellow);
}

.xn-blink {
  color: var(--home-accent);
  animation: blink 1s step-end infinite;
  margin-left: 0.5rem;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.xn-hero-buttons {
  display: flex;
  gap: 1rem;
  margin: 2rem 0 0;
}

.xn-hero-button {
  background: transparent;
  border: 1px solid currentColor;
  color: var(--home-text-primary);
  padding: 0.8rem 1.5rem;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1rem;
  text-decoration: none;
  position: relative;
  transition: all 0.2s ease;
  min-width: 200px;
  text-align: center;
}

.xn-hero-button:hover {
  background: var(--home-text-primary);
  color: var(--home-bg-primary);
  box-shadow: 0 0 10px var(--home-text-primary);
}

.xn-enterprise-button {
  color: var(--home-enterprise-primary);
  border-color: var(--home-enterprise-primary);
}

.xn-enterprise-button:hover {
  background: var(--home-enterprise-primary);
  color: var(--home-bg-primary);
  box-shadow: 0 0 10px var(--home-enterprise-primary);
}

/* About section */
.xn-who-section {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.xn-terminal-block {
  position: relative;
  margin: 0;
  width: 100%;
}

/* Заголовок окна терминала */
.xn-window-header {
  background: rgba(0, 0, 0, 0.5);
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
  position: relative;
  backdrop-filter: blur(5px);
}

/* Кнопки управления окном */
.xn-window-controls {
  display: flex;
  gap: 8px;
  margin-right: 1rem;
  z-index: 2;
}

.xn-window-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.xn-window-button:hover::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background: rgba(0, 0, 0, 0.5);
}

.xn-window-close {
  background: var(--home-accent-red);
}

.xn-window-minimize {
  background: var(--home-accent-yellow);
}

.xn-window-maximize {
  background: var(--home-text-primary);
}

/* Путь в заголовке */
.xn-window-path {
  color: var(--home-text-secondary);
  font-size: 0.9rem;
  flex-grow: 1;
  text-align: center;
  position: relative;
  padding: 0.3rem 0.8rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-shadow: 0 0 5px rgba(180, 255, 159, 0.2);
  white-space: nowrap;
  overflow: hidden;
}

.xn-window-path::before {
  content: '📄';
  margin-right: 0.5rem;
  opacity: 0.7;
}

/* Контент терминала */
.xn-terminal-content {
  padding: 2rem;
  line-height: 1.8;
  color: var(--home-text-secondary);
  font-size: 1.1rem;
  background: var(--home-bg-secondary);
  position: relative;
  overflow: visible;
}

.xn-terminal-content::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: 
    repeating-linear-gradient(
      to right,
      transparent 0,
      transparent 3rem,
      rgba(180, 255, 159, 0.03) 3rem,
      rgba(180, 255, 159, 0.03) 3.1rem
    );
  pointer-events: none;
}

.xn-terminal-header {
  color: var(--home-text-secondary);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  font-family: 'Share Tech Mono', monospace;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xn-term-prefix {
  color: var(--home-accent-cyan);
}

.xn-term-file {
  color: var(--home-accent-yellow);
}

.xn-term-section {
  position: relative;
  z-index: 2;
  margin-left: 100px;
  background: rgba(0, 13, 0, 0.85);
  padding: 1.5rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(51, 255, 51, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.xn-term-section:hover::before {
  content: '';
  position: absolute;
  left: -2rem;
  top: 0;
  height: 100%;
  width: 2px;
  background: var(--home-accent-cyan);
  animation: highlightLine 0.3s ease;
}

@keyframes highlightLine {
  from { 
    opacity: 0;
    transform: scaleY(0);
  }
  to { 
    opacity: 1;
    transform: scaleY(1);
  }
}

.xn-term-line {
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 3rem;
}

.xn-term-line::before {
  content: attr(data-line);
  position: absolute;
  left: 0;
  color: var(--home-text-secondary);
  opacity: 0.5;
  font-size: 0.8rem;
  width: 2rem;
  text-align: right;
  padding-right: 1rem;
  user-select: none;
}

.xn-term-highlight {
  color: var(--home-accent-cyan);
  position: relative;
  transition: color 0.2s ease;
}

.xn-term-highlight:hover {
  color: var(--home-accent-yellow);
  text-shadow: 0 0 5px var(--home-accent-yellow);
}

.xn-term-description {
  padding-left: 2rem;
  margin: 1rem 0;
}

.xn-term-accent {
  color: var(--home-accent-yellow);
  text-shadow: var(--home-text-glow);
}

.xn-term-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.xn-term-list li {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.xn-term-list li::before {
  content: '•';
  color: var(--home-accent-cyan);
  margin-right: 1rem;
}

.xn-term-value {
  position: relative;
  transition: all 0.2s ease;
}

.xn-term-value:hover {
  background: rgba(180, 255, 159, 0.1);
  border-radius: 4px;
  cursor: pointer;
  color: var(--home-accent-yellow);
}

.xn-term-arrow {
  color: var(--home-accent-cyan);
  margin-right: 1rem;
  font-family: 'Share Tech Mono', monospace;
}

.xn-term-section.benefits {
  margin-top: 2rem;
}

.xn-term-section.benefits .xn-term-description {
  color: var(--home-text-primary);
}

/* Access levels */
.xn-access-section {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  padding: 2rem;
  overflow: hidden;
}

.xn-access-card {
  background: var(--home-bg-secondary);
  position: relative;
  border: 1px solid rgba(180, 255, 159, 0.2);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 700px;
}

.xn-access-card.xn-enterprise {
  border-color: rgba(94, 91, 217, 0.2);
}

.xn-access-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  padding-bottom: calc(1.5rem + 60px);
}

.xn-access-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    rgba(51, 255, 51, 0.03) 0%,
    transparent 60%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.xn-access-card:hover .xn-access-content::before {
  opacity: 1;
}

/* Статус секция */
.xn-access-status {
  background: rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.xn-status-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: 'Share Tech Mono', monospace;
}

.xn-status-label {
  color: var(--home-text-secondary);
  font-size: 0.9rem;
}

.xn-status-value {
  color: var(--home-text-primary);
  font-size: 0.9rem;
  position: relative;
}

.xn-status-value::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--home-accent-cyan);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.xn-status-item:hover .xn-status-value::after {
  transform: scaleX(1);
  transform-origin: left;
}

.xn-status-value.blink {
  animation: statusBlink 2s step-end infinite;
}

/* Секция функций */
.xn-access-features {
  flex: 1;
  padding: 1.5rem;
}

.xn-feature-header {
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
  margin-bottom: 1rem;
}

.xn-feature-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.xn-feature-bullet {
  font-size: 1.2rem;
  margin-right: 1rem;
  color: var(--home-accent-cyan);
  text-shadow: 0 0 5px var(--home-accent-cyan);
  animation: pulse 2s infinite;
  transition: all 0.3s ease;
}

@keyframes pulse {
  0% {
    text-shadow: 0 0 5px var(--home-accent-cyan);
  }
  50% {
    text-shadow: 0 0 15px var(--home-accent-cyan);
  }
  100% {
    text-shadow: 0 0 5px var(--home-accent-cyan);
  }
}

.xn-feature-list li:hover .xn-feature-bullet {
  transform: scale(1.2);
  animation: none;
  text-shadow: 0 0 10px var(--home-accent-yellow);
  color: var(--home-accent-yellow);
}

.xn-feature-command {
  position: relative;
  padding: 4px 8px;
  background: rgba(51, 255, 51, 0.1);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.xn-feature-item:hover .xn-feature-command {
  background: rgba(51, 255, 51, 0.2);
  box-shadow: 0 0 10px rgba(51, 255, 51, 0.1);
}

.xn-access-card .xn-window-header {
  position: relative;
  overflow: hidden;
}

.xn-access-card .xn-window-header::after {
  content: attr(data-path);
  position: absolute;
  right: -100%;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-accent-cyan);
  opacity: 0;
  transition: all 0.4s ease;
}

.xn-access-card:hover .xn-window-header::after {
  right: 20px;
  opacity: 0.6;
}

.xn-feature-list {
  position: relative;
  counter-reset: feature;
}

.xn-feature-item {
  counter-increment: feature;
  position: relative;
  padding: 12px 12px 12px 40px;
  transition: all 0.3s ease;
}

.xn-feature-item::before {
  content: counter(feature, decimal-leading-zero);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8em;
  color: var(--home-accent-cyan);
  opacity: 0.4;
  transition: all 0.3s ease;
}

.xn-feature-item:hover::before {
  opacity: 1;
  transform: translateY(-50%) scale(1.2);
  color: var(--home-accent-yellow);
}

.xn-feature-command {
  position: relative;
  padding: 4px 8px;
  background: rgba(51, 255, 51, 0.05);
  border-radius: 4px;
  transition: all 0.3s ease;
  font-family: 'Share Tech Mono', monospace;
  letter-spacing: 0.5px;
}

.xn-feature-command::before {
  content: '>';
  position: absolute;
  left: -15px;
  opacity: 0;
  transform: translateX(10px);
  transition: all 0.3s ease;
}

.xn-feature-item:hover .xn-feature-command::before {
  opacity: 1;
  transform: translateX(0);
}

.xn-feature-item:hover .xn-feature-command {
  padding-left: 12px;
  background: rgba(51, 255, 51, 0.1);
  letter-spacing: 1px;
}

.xn-status-item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
}

.xn-status-label {
  position: relative;
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-text-secondary);
}

.xn-status-value {
  position: relative;
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-accent-yellow);
  transition: all 0.3s ease;
}

.xn-status-value::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.xn-status-item:hover .xn-status-value::after {
  transform: scaleX(1);
  transform-origin: left;
}

.xn-access-content {
  position: relative;
  padding: 20px;
}

.xn-access-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    rgba(51, 255, 51, 0.03) 0%,
    transparent 60%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.xn-access-card:hover .xn-access-content::before {
  opacity: 1;
}

/* Контейнер для кнопки */
.xn-button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
}

/* Кнопка терминала */
.xn-terminal-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid var(--home-text-primary);
  color: var(--home-text-primary);
  padding: 1rem 1.5rem;
  height: 56px;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s ease;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.xn-terminal-button:hover {
  background: var(--home-text-primary);
  color: var(--home-bg-primary);
  box-shadow: 0 0 10px var(--home-text-primary);
}

.xn-terminal-button.enterprise {
  border-color: var(--home-enterprise-primary);
  color: var(--home-enterprise-primary);
}

.xn-terminal-button.enterprise:hover {
  background: var(--home-enterprise-primary);
  color: var(--home-bg-primary);
  box-shadow: 0 0 10px var(--home-enterprise-primary);
}

.xn-button-prompt {
  color: var(--home-accent-cyan);
}

@keyframes statusBlink {
  0%, 50% { opacity: 1; }
  51%, 100% { opacity: 0.5; }
}

/* Медиа-запросы */
@media (max-width: 1024px) {
  .xn-access-section {
    grid-template-columns: 1fr;
    padding: 1rem;
    max-width: 100%;
  }
  
  .xn-access-card {
    height: auto;
    min-height: 500px;
    width: 100%;
  }

  .xn-term-section {
    margin-left: 0;
    width: 100%;
    padding: 1.5rem;
  }
}

/* Убираем анимированную линию */
.xn-access-card::before {
  display: none;
}

/* Увеличиваем отступы между пунктами */
.xn-feature-item {
  margin-bottom: 2rem;
  padding: 16px 16px 16px 40px;
}

/* Стили для кнопок */
.xn-terminal-button {
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  height: 56px;
}

.xn-button-prompt {
  font-size: 1.2rem;
}

@media (max-width: 480px) {
  .xn-access-content {
    padding: 1rem;
  }
  
  .xn-feature-list li {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }
  
  .xn-feature-desc {
    margin-left: 1.5rem;
  }
}

/* Technical details */
.xn-tech-section {
  max-width: 800px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.xn-network-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  gap: 2rem;
}

.xn-network-title {
  color: var(--home-accent-cyan);
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.1rem;
}

.xn-network-command {
  color: var(--home-text-secondary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  opacity: 0.8;
}

.xn-network-timestamp {
  color: var(--home-text-secondary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .xn-network-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .xn-network-command {
    opacity: 0.6;
  }
}

.xn-network-status {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.xn-network-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-left: 2px solid var(--home-text-primary);
  transition: all 0.3s ease;
}

.xn-network-item:hover {
  background: rgba(0, 0, 0, 0.3);
  transform: translateX(5px);
  border-left-color: var(--home-accent-cyan);
}

.xn-network-info {
  flex: 1;
}

.xn-network-name {
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-text-primary);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.xn-network-meta {
  display: flex;
  gap: 1.5rem;
}

.xn-meta-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xn-meta-label {
  color: var(--home-text-secondary);
  font-size: 0.9rem;
}

.xn-meta-value {
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
}

.xn-meta-value.counting {
  animation: numberCount 2s ease infinite;
}

.xn-network-scripts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 150px;
}

.xn-script-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  color: var(--home-text-secondary);
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.xn-script-item:hover {
  background: rgba(180, 255, 159, 0.1);
  color: var(--home-text-primary);
}

.xn-script-status {
  font-size: 0.8rem;
  opacity: 0.5;
}

.xn-script-status.active {
  color: var(--home-accent-cyan);
  opacity: 1;
  animation: pulse 2s ease infinite;
}

.xn-network-footer {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px dashed rgba(180, 255, 159, 0.1);
}

.xn-total-stats {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}

.xn-total-item {
  text-align: center;
}

.xn-total-label {
  color: var(--home-text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.xn-total-value {
  color: var(--home-accent-cyan);
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
}

.xn-total-value.counting {
  animation: numberCount 2s ease infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes numberCount {
  0% { opacity: 1; }
  50% { opacity: 0.7; transform: scale(1.05); }
  100% { opacity: 1; }
}

@media (max-width: 768px) {
  .xn-network-item {
    flex-direction: column;
    gap: 1rem;
  }

  .xn-network-scripts {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .xn-total-stats {
    flex-direction: column;
    gap: 1rem;
  }
}

/* Statistics */
.xn-stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
}

.xn-stat-item {
  text-align: center;
}

.xn-stat-label {
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.xn-stat-value {
  font-family: 'VT323', monospace;
  color: var(--home-accent-yellow);
  font-size: 2.5rem;
  text-shadow: 0 0 10px rgba(255, 225, 100, 0.2);
}

/* Testimonials */
.xn-testimonials-section {
  max-width: 800px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.xn-testimonial {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 2rem;
  padding: 1.5rem 0;
  border-bottom: 1px dashed rgba(180, 255, 159, 0.1);
}

.xn-testimonial-avatar {
  font-family: monospace;
  white-space: pre;
  font-size: 10px;
  line-height: 1.2;
  color: var(--home-text-primary);
}

.xn-testimonial-content {
  color: var(--home-text-secondary);
}

.xn-testimonial-name {
  color: var(--home-text-primary);
  margin-bottom: 0.5rem;
}

/* Final CTA */
.xn-final-cta {
  text-align: center;
  padding: 4rem 2rem;
  margin: 4rem auto;
  max-width: 800px;
  border-top: 1px solid rgba(180, 255, 159, 0.1);
}

.xn-cta-title {
  font-family: 'VT323', monospace;
  font-size: 2.5rem;
  color: var(--home-text-primary);
  margin-bottom: 2rem;
  text-shadow: var(--home-neon-glow);
  letter-spacing: 1px;
}

.xn-final-cta .xn-hero-buttons {
  justify-content: center;
  margin-top: 2rem;
}

/* Responsive */
@media (max-width: 768px) {
  .xn-hero-title {
    font-size: 2.5rem;
    text-align: left;
  }
  
  .xn-hero-title-changing {
    font-size: 2rem;
  }
  
  .xn-hero-description {
    font-size: 1rem;
    margin: 1.5rem 0;
  }
  
  .xn-hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }
  
  .xn-hero-button {
    width: 100%;
    min-width: unset;
  }

  .xn-access-section {
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 1.5rem;
  }
  
  .xn-access-card {
    padding: 1.5rem;
  }
  
  .xn-network-status {
    margin: 1rem 0;
  }
  
  .xn-network-item {
    grid-template-columns: 90px 1fr 70px;
    gap: 0.5rem;
    font-size: 0.9rem;
  }
  
  .xn-stats-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 2rem 0;
  }
  
  .xn-testimonial {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem 0;
  }
  
  .xn-final-cta {
    padding: 2rem 1rem;
    margin: 2rem auto;
  }
  
  .xn-cta-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .xn-hero-title {
    font-size: 2rem;
  }
  
  .xn-hero-title-changing {
    font-size: 1.5rem;
  }
  
  .xn-hero-description {
    font-size: 0.9rem;
  }
  
  .xn-card-header {
    font-size: 1rem;
  }
  
  .xn-slots-counter,
  .xn-enterprise-stats {
    font-size: 0.8rem;
  }
  
  .xn-benefits-list {
    font-size: 0.9rem;
  }
  
  .xn-network-item {
    grid-template-columns: 80px 1fr 60px;
    font-size: 0.8rem;
  }
  
  .xn-stat-value {
    font-size: 1.8rem;
  }
  
  .xn-cta-title {
    font-size: 1.8rem;
  }
  
  .xn-hero-button {
    font-size: 0.9rem;
    padding: 0.7rem 1rem;
  }
  
  .xn-terminal-header {
    font-size: 0.8rem;
  }
  
  .xn-terminal-content {
    font-size: 0.9rem;
    padding-left: 1rem;
  }
}

/* Анимация печатающегося текста */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
} 

/* Анимации слов в заголовке */
.xn-hero-word-change {
  display: inline-block;
  color: var(--home-accent-cyan);
  position: relative;
  min-width: 200px;
  opacity: 0;
  animation: fadeInOut 3s infinite;
  margin-right: 10px;
}

/* Анимация плавного появления/исчезновения */
@keyframes fadeInOut {
  0%, 100% { 
    opacity: 0;
    transform: translateY(10px);
  }
  20%, 80% { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Удаляем эффект сканирования */
.xn-hero-section::after {
  display: none;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .xn-hero-word-change {
    min-width: 160px;
  }
}

@media (max-width: 480px) {
  .xn-hero-word-change {
    min-width: 140px;
  }
}

.xn-network-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border-left: 2px solid var(--home-accent-yellow);
  margin-top: 1rem;
}

.xn-more-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.xn-more-icon {
  font-size: 2rem;
  color: var(--home-accent-yellow);
  font-family: 'Share Tech Mono', monospace;
  animation: blink 2s step-end infinite;
}

.xn-more-text {
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
}

.xn-more-details {
  color: var(--home-text-secondary);
  font-size: 0.9rem;
  margin-top: 0.3rem;
}

.xn-more-stats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: right;
}

@media (max-width: 768px) {
  .xn-network-more {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .xn-more-stats {
    text-align: center;
  }
}

.xn-feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.xn-feedback-stats {
  display: flex;
  gap: 1.5rem;
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  color: var(--home-text-secondary);
}

.xn-feedback-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.xn-feedback-item {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-left: 2px solid var(--home-text-primary);
  overflow: hidden;
  transition: all 0.3s ease;
}

.xn-feedback-item:hover {
  background: rgba(0, 0, 0, 0.3);
  transform: translateX(5px);
  border-left-color: var(--home-accent-cyan);
}

.xn-feedback-meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
}

.xn-user-info {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.xn-user-avatar {
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(180, 255, 159, 0.1);
}

.xn-avatar-art {
  margin: 0;
  color: var(--home-accent-cyan);
  font-size: 10px;
  line-height: 1.2;
}

.xn-user-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.xn-user-name {
  color: var(--home-text-primary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.1rem;
}

.xn-user-stats {
  display: flex;
  gap: 1rem;
  color: var(--home-text-secondary);
  font-size: 0.9rem;
}

.xn-review-info {
  text-align: right;
}

.xn-review-rating {
  color: var(--home-accent-yellow);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.xn-review-date {
  color: var(--home-text-secondary);
  font-size: 0.9rem;
  font-family: 'Share Tech Mono', monospace;
}

.xn-feedback-content {
  padding: 1.5rem;
}

.xn-comment-header {
  color: var(--home-text-secondary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.xn-comment-text {
  color: var(--home-text-primary);
  line-height: 1.6;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.xn-verification {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--home-accent-cyan);
  font-size: 0.9rem;
}

.xn-verify-icon {
  font-size: 1.2rem;
}

.xn-verify-text {
  opacity: 0.8;
}

.xn-feedback-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border-left: 2px solid var(--home-accent-yellow);
}

@media (max-width: 768px) {
  .xn-feedback-meta {
    flex-direction: column;
    gap: 1rem;
  }

  .xn-review-info {
    text-align: left;
  }

  .xn-user-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .xn-feedback-more {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

.xn-globe-background {
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  height: 600px;
  z-index: 0;
  opacity: 0.4;
  pointer-events: none;
  transform: scale(0.8) translateX(20%) translateY(-20%);
  transition: all 0.5s ease;
}

.xn-globe-background:hover {
  opacity: 0.6;
  transform: scale(0.85) translateX(20%) translateY(-20%);
}

.xn-globe-background canvas {
  filter: blur(1px) brightness(1.2);
  transition: all 0.3s ease;
}

.xn-globe-background:hover canvas {
  filter: blur(0) brightness(1.4);
}

.xn-globe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    rgba(51, 255, 51, 0.1) 0%,
    rgba(51, 255, 51, 0) 70%
  );
  backdrop-filter: blur(20px);
  pointer-events: none;
  z-index: 1;
}

.xn-torus-container {
  position: absolute;
  left: -300px;
  top: 50%;
  transform: translateY(-50%);
  width: 500px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 20px;
  transition: all 0.3s ease;
  z-index: 10;
  pointer-events: auto;
}

.xn-torus-container:hover {
  transform: translateY(-50%);
}

.xn-torus-container canvas {
  opacity: 0.85;
  transition: all 0.3s ease;
}

.xn-torus-container:hover canvas {
  opacity: 1;
}

.xn-terminal-block {
  position: relative;
  margin: 0;
  width: 100%;
}

@media (max-width: 1200px) {
  .xn-torus-container {
    display: none;
  }
  
  .xn-terminal-block {
    margin: 0;
  }
}

.xn-cube-background {
  display: none;
}

.xn-feature-bullet {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  font-size: 16px;
  color: var(--home-accent-cyan);
  transition: all 0.3s ease;
}

.xn-feature-bullet::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0.3;
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

.xn-feature-item:hover .xn-feature-bullet {
  color: var(--home-accent-yellow);
  transform: scale(1.1);
}

.xn-feature-item:hover .xn-feature-bullet::before {
  opacity: 0.6;
  transform: rotate(225deg);
}

.xn-feature-item {
  position: relative;
  padding: 12px;
  transition: all 0.3s ease;
}

.xn-feature-item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: var(--home-accent-cyan);
  transition: width 0.3s ease;
}

.xn-feature-item:hover::after {
  width: 100%;
}

.xn-status-item {
  position: relative;
  overflow: hidden;
}

.xn-status-value {
  position: relative;
}

.xn-status-value::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -20px;
  width: 10px;
  height: 1px;
  background: var(--home-accent-cyan);
  transform: translateY(-50%);
  animation: statusPulse 2s infinite;
}

@keyframes statusPulse {
  0% {
    opacity: 0.2;
    transform: translateY(-50%) scaleX(0.5);
  }
  50% {
    opacity: 1;
    transform: translateY(-50%) scaleX(1.2);
  }
  100% {
    opacity: 0.2;
    transform: translateY(-50%) scaleX(0.5);
  }
}

.xn-access-card {
  position: relative;
  overflow: hidden;
}

.xn-access-card::before {
  display: none;
}

.xn-feature-command {
  position: relative;
  padding: 4px 8px;
  background: rgba(51, 255, 51, 0.1);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.xn-feature-item:hover .xn-feature-command {
  background: rgba(51, 255, 51, 0.2);
  box-shadow: 0 0 10px rgba(51, 255, 51, 0.1);
}

.xn-access-card .xn-window-header {
  position: relative;
  overflow: hidden;
}

.xn-access-card .xn-window-header::after {
  content: attr(data-path);
  position: absolute;
  right: -100%;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-accent-cyan);
  opacity: 0;
  transition: all 0.4s ease;
}

.xn-access-card:hover .xn-window-header::after {
  right: 20px;
  opacity: 0.6;
}

.xn-feature-list {
  position: relative;
  counter-reset: feature;
}

.xn-feature-item {
  counter-increment: feature;
  position: relative;
  padding: 12px 12px 12px 40px;
  transition: all 0.3s ease;
}

.xn-feature-item::before {
  content: counter(feature, decimal-leading-zero);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8em;
  color: var(--home-accent-cyan);
  opacity: 0.4;
  transition: all 0.3s ease;
}

.xn-feature-item:hover::before {
  opacity: 1;
  transform: translateY(-50%) scale(1.2);
  color: var(--home-accent-yellow);
}

.xn-feature-command {
  position: relative;
  padding: 4px 8px;
  background: rgba(51, 255, 51, 0.05);
  border-radius: 4px;
  transition: all 0.3s ease;
  font-family: 'Share Tech Mono', monospace;
  letter-spacing: 0.5px;
}

.xn-feature-command::before {
  content: '>';
  position: absolute;
  left: -15px;
  opacity: 0;
  transform: translateX(10px);
  transition: all 0.3s ease;
}

.xn-feature-item:hover .xn-feature-command::before {
  opacity: 1;
  transform: translateX(0);
}

.xn-feature-item:hover .xn-feature-command {
  padding-left: 12px;
  background: rgba(51, 255, 51, 0.1);
  letter-spacing: 1px;
}

.xn-status-item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
}

.xn-status-label {
  position: relative;
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-text-secondary);
}

.xn-status-value {
  position: relative;
  font-family: 'Share Tech Mono', monospace;
  color: var(--home-accent-yellow);
  transition: all 0.3s ease;
}

.xn-status-value::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.xn-status-item:hover .xn-status-value::after {
  transform: scaleX(1);
  transform-origin: left;
}

.xn-access-content {
  position: relative;
  padding: 20px;
}

.xn-access-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    rgba(51, 255, 51, 0.03) 0%,
    transparent 60%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.xn-access-card:hover .xn-access-content::before {
  opacity: 1;
}

/* Стили для networks_status */
.xn-network-status {
  margin: 2rem 0;
}

.xn-network-header {
  margin-bottom: 2rem;
  color: var(--home-accent-cyan);
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.1rem;
}

.xn-network-item {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.xn-network-name {
  color: var(--home-text-primary);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.xn-network-stats {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

.xn-network-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xn-network-stat-label {
  color: var(--home-text-secondary);
}

.xn-network-stat-value {
  color: var(--home-accent-yellow);
}

.xn-network-tools {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.xn-network-tool {
  color: var(--home-accent-cyan);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.xn-network-tool:hover {
  color: var(--home-accent-yellow);
} 
