.admin-wiki-page {
  padding: 2rem;
  color: #fff;
}

.admin-wiki-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.admin-wiki-title h1 {
  font-size: 1.8rem;
  margin: 0;
  color: #00ff00;
}

.admin-wiki-btn {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.admin-wiki-btn:hover {
  background: rgba(0, 255, 0, 0.2);
}

.admin-wiki-btn.primary {
  background: rgba(0, 255, 0, 0.2);
}

.admin-wiki-btn.primary:hover {
  background: rgba(0, 255, 0, 0.3);
}

.admin-wiki-empty {
  text-align: center;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  color: #666;
}

.admin-wiki-list {
  display: grid;
  gap: 1rem;
}

.admin-wiki-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(0, 255, 0, 0.1);
}

.admin-wiki-item-info h3 {
  margin: 0 0 0.5rem 0;
  color: #00ff00;
}

.admin-wiki-item-meta {
  display: flex;
  gap: 1rem;
  color: #666;
  margin: 0;
}

.admin-wiki-item-actions {
  display: flex;
  gap: 1rem;
}

.admin-wiki-edit-btn,
.admin-wiki-delete-btn {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 255, 0, 0.2);
  background: transparent;
  color: #00ff00;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
}

.admin-wiki-delete-btn {
  color: #ff4444;
  border-color: rgba(255, 68, 68, 0.2);
}

.admin-wiki-edit-btn:hover {
  background: rgba(0, 255, 0, 0.1);
}

.admin-wiki-delete-btn:hover {
  background: rgba(255, 68, 68, 0.1);
}

.admin-wiki-loading,
.admin-wiki-error {
  text-align: center;
  padding: 2rem;
  color: #00ff00;
}

.admin-wiki-error {
  color: #ff4444;
}

.admin-wiki-categories {
  margin: 2rem 0;
  padding: 1rem;
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 4px;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.category-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 4px;
}

.category-icon {
  color: #00ff00;
}

.category-name {
  flex: 1;
}

.category-delete-btn {
  background: transparent;
  border: none;
  color: #ff4444;
  cursor: pointer;
}

.admwiki-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.admwiki-modal-content {
  background: #000;
  border: 1px solid rgba(0, 255, 0, 0.2);
  padding: 2rem;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
}

.admwiki-modal-content input {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #fff;
  border-radius: 4px;
}

.admwiki-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.admwiki-modal-actions button {
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}
