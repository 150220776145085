/* Импорт шрифтов */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&family=VT323&family=Press+Start+2P&display=swap');

:root {
  /* Основная палитра */
  --contact-bg-primary: #0A0F1B;
  --contact-bg-secondary: #0F1B2D;
  --contact-text-primary: #9FE7FF;
  --contact-text-secondary: #00BFFF;
  --contact-text-dim: #3D5C8C;
  --contact-accent: #FF00FF;
  --contact-warning: #FFD700;
  --contact-error: #FF3131;
  
  /* Эффекты */
  --contact-neon-glow: 0 0 10px var(--contact-text-primary);
  --contact-text-glow: 0 0 5px currentColor;
}

/* Основной контейнер */
.xncontact-container {
  background: var(--contact-bg-primary);
  min-height: 100vh;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
}

/* Эффект сканлайнов */
.xncontact-container::before {
  content: '';
  position: fixed;
  inset: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 2px,
    rgba(159, 231, 255, 0.03) 3px,
    rgba(159, 231, 255, 0.03) 4px
  );
  pointer-events: none;
  animation: scanlines 60s linear infinite;
}

/* Заголовок */
.xncontact-title {
  font-family: 'Press Start 2P', monospace;
  font-size: 3rem;
  color: var(--contact-text-primary);
  margin-bottom: 2rem;
  text-shadow: var(--contact-neon-glow);
  position: relative;
  display: inline-block;
}

.xncontact-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 2px;
  background: var(--contact-accent);
  transform: scaleX(0.7);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.xncontact-title:hover::after {
  transform: scaleX(1);
}

.xncontact-description {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  color: var(--contact-text-secondary);
  max-width: 800px;
  margin: 2rem 0;
  line-height: 1.8;
  padding-left: 2rem;
  border-left: 2px solid var(--contact-accent);
}

/* Методы контакта */
.xncontact-methods {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.xncontact-card {
  padding: 2rem;
  background: rgba(15, 27, 45, 0.6);
  border: 1px solid var(--contact-text-primary);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.xncontact-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(159, 231, 255, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xncontact-card:hover::before {
  transform: translateX(100%);
}

.xncontact-method-title {
  font-family: 'Press Start 2P', monospace;
  font-size: 1.4rem;
  color: var(--contact-text-primary);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.xncontact-method-icon {
  color: var(--contact-accent);
  animation: pulse 2s infinite;
}

.xncontact-link {
  display: block;
  color: var(--contact-text-secondary);
  text-decoration: none;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.1rem;
  padding: 0.8rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.xncontact-link:hover {
  color: var(--contact-accent);
  transform: translateX(10px);
}

/* Форма контакта */
.xncontact-form-container {
  margin-top: 4rem;
  padding: 3rem;
  background: rgba(15, 27, 45, 0.8);
  border: 1px solid var(--contact-text-primary);
  position: relative;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.xncontact-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--contact-text-secondary);
}

.xncontact-form-title {
  font-family: 'Press Start 2P', monospace;
  font-size: 1.2rem;
  color: var(--contact-text-primary);
}

.xncontact-form-status {
  font-family: 'Share Tech Mono', monospace;
  color: var(--contact-accent);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xncontact-form-status::before {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--contact-accent);
  border-radius: 50%;
  animation: statusBlink 2s infinite;
}

.xncontact-input-group {
  margin-bottom: 2rem;
  position: relative;
}

.xncontact-input-label {
  font-family: 'Share Tech Mono', monospace;
  color: var(--contact-text-secondary);
  margin-bottom: 0.5rem;
  display: block;
}

.xncontact-input {
  width: 100%;
  padding: 1rem;
  background: rgba(15, 27, 45, 0.6);
  border: 1px solid var(--contact-text-secondary);
  color: var(--contact-text-primary);
  font-family: 'Share Tech Mono', monospace;
  transition: all 0.3s ease;
}

.xncontact-input:focus {
  outline: none;
  border-color: var(--contact-accent);
  box-shadow: 0 0 10px var(--contact-accent);
}

.xncontact-textarea {
  min-height: 150px;
  resize: vertical;
}

.xncontact-submit-btn {
  background: transparent;
  border: 1px solid var(--contact-accent);
  color: var(--contact-accent);
  padding: 1rem 2rem;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.xncontact-submit-btn::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 0, 255, 0.2),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xncontact-submit-btn:hover {
  background: var(--contact-accent);
  color: var(--contact-bg-primary);
  transform: translateY(-2px);
}

.xncontact-submit-btn:hover::before {
  transform: translateX(100%);
}

/* Анимации */
@keyframes scanlines {
  0% { background-position: 0 0; }
  100% { background-position: 100% 100%; }
}

@keyframes pulse {
  0%, 100% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(0.95); }
}

@keyframes statusBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

/* Адаптивность */
@media (max-width: 768px) {
  .xncontact-title {
    font-size: 2rem;
  }

  .xncontact-methods {
    grid-template-columns: 1fr;
  }

  .xncontact-form-container {
    padding: 1.5rem;
  }
}

/* Добавляем стили для уведомлений */
.xncontact-notification {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background: rgba(15, 27, 45, 0.95);
  border: 1px solid var(--contact-text-primary);
  backdrop-filter: blur(10px);
  z-index: 1000;
  animation: slideIn 0.3s ease forwards;
  max-width: 400px;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.xncontact-notification.confirmed {
  border-color: #00FF00;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1);
}

.xncontact-notification.error {
  border-color: var(--contact-error);
  box-shadow: 0 0 20px rgba(255, 49, 49, 0.1);
}

.xncontact-notification-icon {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid currentColor;
  border-radius: 50%;
}

.xncontact-notification.confirmed .xncontact-notification-icon {
  color: #00FF00;
}

.xncontact-notification.error .xncontact-notification-icon {
  color: var(--contact-error);
}

.xncontact-notification-content h3 {
  font-family: 'Press Start 2P', monospace;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--contact-text-primary);
}

.xncontact-notification-content p {
  font-family: 'Share Tech Mono', monospace;
  color: var(--contact-text-secondary);
  font-size: 0.9rem;
  line-height: 1.4;
}

.xncontact-notification-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: var(--contact-text-dim);
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.xncontact-notification-close:hover {
  color: var(--contact-text-primary);
  transform: scale(1.1);
}

/* Улучшаем стили для кнопки отправки */
.xncontact-submit-btn.submitting {
  position: relative;
  background: rgba(255, 0, 255, 0.1);
}

.xncontact-loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid var(--contact-accent);
  border-radius: 50%;
  border-top-color: transparent;
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
}

/* Добавляем новые анимации */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Улучшаем стили для статуса формы */
.xncontact-status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: var(--contact-accent);
  border-radius: 50%;
  margin-right: 0.5rem;
  animation: statusBlink 2s infinite;
}

.submitting .xncontact-status-dot {
  background: var(--contact-warning);
}

/* Добавляем стили для ошибок */
.xncontact-error {
  color: var(--contact-error);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xncontact-error::before {
  content: '[!]';
  color: var(--contact-error);
}

.xncontact-input.error {
  border-color: var(--contact-error);
  box-shadow: 0 0 5px rgba(255, 49, 49, 0.3);
}

.xncontact-input.error:focus {
  border-color: var(--contact-error);
  box-shadow: 0 0 10px rgba(255, 49, 49, 0.5);
}

/* Статус сообщение под формой */
.xncontact-status-message {
  margin-top: 1rem;
  padding: 1rem;
  font-family: 'Share Tech Mono', monospace;
  border-left: 2px solid var(--contact-text-secondary);
  background: rgba(15, 27, 45, 0.3);
}

.xncontact-status-message.confirmed {
  border-left-color: #00FF00;
  color: #00FF00;
}

.xncontact-status-message.error {
  border-left-color: var(--contact-error);
  color: var(--contact-error);
} 