.news-editor {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.news-editor__title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--text-primary);
}

.news-editor__form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.news-editor__form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.news-editor__label {
  font-size: 1rem;
  color: var(--text-secondary);
  font-family: 'Courier New', monospace;
}

.news-editor__input {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 1rem;
}

.news-editor__input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2);
}

.news-editor__md-editor {
  border-radius: 4px;
  overflow: hidden;
}

/* Переопределение стилей MD редактора */
.news-editor .w-md-editor {
  background: var(--bg-secondary) !important;
  border: 1px solid var(--border-color) !important;
}

.news-editor .w-md-editor-text-pre > code,
.news-editor .w-md-editor-text-input {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.news-editor__options {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: 4px;
}

.news-editor__checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.news-editor__checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.news-editor__checkbox {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.news-editor__select {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--bg-primary);
  color: var(--text-primary);
  cursor: pointer;
}

.news-editor__error {
  padding: 1rem;
  background: var(--error-bg);
  border: 1px solid var(--error-border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.news-editor__error-icon {
  color: var(--error-color);
  font-weight: bold;
}

.news-editor__error-text {
  color: var(--error-color);
}

.news-editor__actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.news-editor__btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.news-editor__btn--cancel {
  background: var(--bg-secondary);
  color: var(--text-primary);
}

.news-editor__btn--cancel:hover {
  background: var(--bg-hover);
}

.news-editor__btn--save {
  background: var(--primary-color);
  color: white;
}

.news-editor__btn--save:hover {
  background: var(--primary-color-dark);
}

.news-editor__loading {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
}

/* Адаптивность */
@media (max-width: 768px) {
  .news-editor {
    padding: 1rem;
  }

  .news-editor__options {
    flex-direction: column;
    gap: 1rem;
  }

  .news-editor__actions {
    flex-direction: column;
  }

  .news-editor__btn {
    width: 100%;
  }
}

.news-editor__image-tools {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: 4px;
}

.news-editor__upload-btn,
.news-editor__library-btn {
  padding: 0.75rem 1.5rem;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-primary);
  cursor: pointer;
  font-family: 'Courier New', monospace;
  transition: all 0.2s ease;
}

.news-editor__upload-btn:hover,
.news-editor__library-btn:hover {
  background: var(--bg-hover);
  border-color: var(--primary-color);
}

/* Стили для модального окна библиотеки изображений */
.image-library-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--bg-primary);
  padding: 2rem;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.image-item {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
}

.image-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.image-item-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  gap: 0.5rem;
}