.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  position: relative;
  padding-top: 60px;
}

.loader-terminal {
  width: 80%;
  max-width: 800px;
  background: rgba(0, 20, 0, 0.8);
  border: 1px solid #00ff00;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1);
  overflow: hidden;
}

.loader-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: rgba(0, 20, 0, 0.5);
  border-bottom: 1px solid rgba(0, 255, 0, 0.2);
}

.loader-controls {
  display: flex;
  gap: 6px;
}

.loader-control {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(0, 255, 0, 0.3);
}

.loader-control.red { background: #ff5f56; }
.loader-control.yellow { background: #ffbd2e; }
.loader-control.green { background: #27c93f; }

.loader-title {
  color: #00ff00;
  font-size: 1em;
}

.loader-time {
  color: #00ff00;
  font-size: 0.8em;
}

.loader-content {
  padding: 20px;
  position: relative;
}

.command-line {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.prompt {
  color: #00ff00;
  opacity: 0.8;
  margin-right: 10px;
}

.typing .cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.progress-line {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.progress-bar {
  flex-grow: 1;
  height: 5px;
  background: rgba(0, 255, 0, 0.2);
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #00ff00;
  transition: width 0.3s ease;
  animation: progressAnimation 2s infinite;
}

@keyframes progressAnimation {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

.progress-text {
  color: #00ff00;
  font-size: 0.8em;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  to { opacity: 0; }
}

@media (max-width: 768px) {
  .loader-container {
    padding: 1rem;
    padding-top: 40px;
  }

  .loader-terminal {
    width: 95%;
    margin: 0 auto;
  }

  .loader-header {
    padding: 0.8rem;
  }

  .loader-title {
    font-size: 0.9rem;
  }

  .loader-content {
    padding: 1rem;
  }

  .command-line {
    font-size: 0.9rem;
  }

  .progress-line {
    margin-top: 1rem;
  }

  .progress-bar {
    height: 4px;
  }

  .progress-text {
    font-size: 0.8rem;
  }
} 