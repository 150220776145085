.enterprise-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.enterprise-modal-content {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 25px;
  width: 100%;
  max-width: 500px;
  animation: modalAppear 0.3s ease;
}

.enterprise-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}

.enterprise-form-group {
  margin-bottom: 15px;
}

.enterprise-form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-color);
}

.enterprise-form-group input,
.enterprise-form-group select {
  width: 100%;
  padding: 8px;
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  border-radius: 4px;
  font-family: 'Fira Code', monospace;
}

.enterprise-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

@keyframes modalAppear {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.input-with-button {
  display: flex;
  gap: 10px;
  align-items: center;
}

.input-with-button input {
  flex: 1;
}

.input-with-button button {
  padding: 8px 12px;
  font-size: 16px;
} 