.terminal-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  padding-top: 80px;
  background: #000000;
  background-image: 
    radial-gradient(circle at 50% 50%, rgba(0, 255, 0, 0.03) 0%, transparent 50%),
    radial-gradient(circle at 80% 20%, rgba(0, 255, 0, 0.02) 0%, transparent 40%);
}


.terminal-prompt {
  color: #00ff00;
  display: block;
  margin-bottom: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
}

.terminal-input {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
  padding: 0.8rem;
  font-family: 'Anonymous Pro', monospace;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.terminal-input:focus {
  outline: none;
  border-color: rgba(0, 255, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.1);
}

.terminal-input.error {
  border-color: rgba(255, 0, 0, 0.5);
}

.terminal-window {
  width: 100%;
  max-width: 1200px;
  background: rgba(0, 20, 0, 0.95);
  border: 1px solid rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1),
              0 0 40px rgba(0, 255, 0, 0.05),
              inset 0 0 60px rgba(0, 255, 0, 0.02);
  border-radius: 8px;
  overflow: hidden;
  backdrop-filter: blur(5px);
  position: relative;
}

.terminal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(0, 20, 0, 0.98);
  border-bottom: 2px solid rgba(0, 255, 0, 0.2);
  position: relative;
}

.terminal-controls {
  display: flex;
  gap: 8px;
  z-index: 2;
}

.terminal-control {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid rgba(0, 255, 0, 0.3);
  background: transparent;
  transition: background-color 0.2s ease;
}

.terminal-control:hover {
  background: rgba(0, 255, 0, 0.2);
}

.terminal-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #00ff00;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  font-family: 'Anonymous Pro', monospace;
}

.terminal-content {
  padding: 2rem;
  color: #00ff00;
  background: rgba(0, 0, 0, 0.3);
}

.terminal-control:nth-child(1) {
  background: #ff5f56;
  border: 1px solid #e0443e;
}

.terminal-control:nth-child(2) {
  background: #ffbd2e;
  border: 1px solid #dea123;
}

.terminal-control:nth-child(3) {
  background: #27c93f;
  border: 1px solid #1aab29;
}

.terminal-status {
  font-size: 0.8rem;
  color: rgba(0, 255, 0, 0.6);
}



.terminal-button {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 1rem 2rem;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.terminal-button:hover:not(:disabled) {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
  transform: translateY(-2px);
}

.terminal-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}


@media (max-width: 768px) {
  .terminal-container {
    padding: 0.5rem;
    padding-top: 60px;
  }

  .terminal-window {
    border-radius: 4px;
  }
}

.config-message {
  margin-top: 20px;
  padding: 15px;
  border-radius: 4px;
  white-space: pre-line;
  line-height: 1.5;
}

.config-message.success {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
}

.message-text {
  display: block;
  margin-left: 25px;
  margin-top: -20px;
} 