.enterprise-container {
  padding: 80px 20px;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  color: #00ff00;
}

.enterprise-header {
  max-width: 1200px;
  margin: 0 auto 4rem;
  text-align: center;
}

.typing-text {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Anonymous Pro', monospace;
  color: #00ff00;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
}

.cursor {
  animation: blink 1s infinite;
}

.header-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.services-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
}

.service-card {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 2rem;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.service-card:hover {
  border-color: rgba(0, 255, 0, 0.3);
  transform: translateY(-5px);
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@media (max-width: 768px) {
  .enterprise-header {
    padding: 0 1rem;
  }
  
  .typing-text {
    font-size: 1.8rem;
  }
  
  .header-description {
    font-size: 1rem;
  }
  
  .services-section {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
}

.projects-section,
.steps-section,
.pricing-section {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 2rem 0;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.project-card {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 2rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.project-card:hover {
  border-color: rgba(0, 255, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1);
}

.project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.project-icon {
  color: #00ff00;
  margin-right: 0.5rem;
}

.project-network {
  padding: 0.3rem 0.8rem;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 4px;
  font-size: 0.9rem;
  color: #00ff00;
}

.metrics-grid {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

.metric-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.metric-item:hover {
  border-color: rgba(0, 255, 0, 0.3);
  background: rgba(0, 255, 0, 0.05);
  transform: translateX(5px);
}

.metric-icon {
  font-size: 1.2rem;
}

.metric-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.metric-value {
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  margin-left: auto;
}

.project-testimonial {
  margin-top: 2rem;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
}

.testimonial-content {
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
}

.quote-icon {
  color: #00ff00;
  font-size: 1.5rem;
  opacity: 0.5;
}

.cursor {
  display: inline-block;
  margin-left: 0.5rem;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.step-card {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.pricing-card {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 2rem;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  flex-grow: 1;
}

.get-started-btn {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 0.8rem;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  transition: all 0.3s ease;
}

.get-started-btn:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
}

@media (max-width: 768px) {
  .projects-section,
  .steps-section,
  .pricing-section {
    padding: 1rem;
  }

  .pricing-card,
  .project-card,
  .step-card {
    padding: 1.2rem;
  }
}

.roi-stats-section {
  max-width: 1200px;
  margin: 6rem auto;
  padding: 2rem 0;
  text-align: center;
}

.roi-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.roi-stat-card {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 2rem;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  position: relative;
  overflow: hidden;
}

.roi-stat-card:hover {
  border-color: rgba(0, 255, 0, 0.5);
  transform: translateY(-5px);
}

.roi-stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #00ff00, transparent);
  animation: scan 2s linear infinite;
}

.stat-icon {
  font-size: 2rem;
  color: #00ff00;
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.stat-value {
  font-size: 2.5rem;
  font-weight: bold;
  color: #00ff00;
  margin: 1rem 0;
  font-family: 'Anonymous Pro', monospace;
  text-shadow: 0 0 15px rgba(0, 255, 0, 0.3);
}

.stat-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
}

@keyframes scan {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@media (max-width: 768px) {
  .roi-stats-grid {
    grid-template-columns: 1fr;
  }
  
  .stat-value {
    font-size: 2rem;
  }
}

.contact-section {
  max-width: 900px;
  margin: 6rem auto;
  padding: 0 2rem;
}

.pricing-section {
  max-width: 900px;
  margin: 6rem auto;
  padding: 0 2rem;
}

.pricing-description {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 3rem;
  line-height: 1.6;
}

.pricing-models {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.pricing-model-card {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 2rem;
  border-radius: 4px;
  position: relative;
}

.model-title {
  color: #00ff00;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.model-features {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
  color: rgba(255, 255, 255, 0.9);
}

.example-box {
  background: rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 4px;
}

.example-title {
  color: #00ff00;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.calculation {
  color: #00ff00;
  margin-top: 1rem;
  font-family: 'Anonymous Pro', monospace;
}

.custom-options {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
  margin-top: 3rem;
}

.contact-btn {
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  transition: all 0.3s ease;
}

.contact-btn:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 20, 0, 0.95);
  border: 1px solid rgba(0, 255, 0, 0.2);
  padding: 0.8rem 1.2rem;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #00ff00;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  animation: fadeIn 0.2s ease;
  min-width: max-content;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.1);
}

.tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 255, 0, 0.2) transparent transparent transparent;
}

.model-icon {
  display: inline-block;
  margin-right: 0.5rem;
  color: #00ff00;
  font-size: 1.1em;
  opacity: 0.8;
}

.highlighted-text {
  display: inline-block;
  color: #00ff00;
  padding: 0.2rem 0.5rem;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 3px;
  cursor: help;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.pricing-models,
.model-content {
  overflow: visible;
}

.steps-section {
  max-width: 1200px;
  margin: 6rem auto;
  padding: 0 2rem;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.step-card {
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.1);
  padding: 2rem;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
}

.step-card:hover {
  border-color: rgba(0, 255, 0, 0.3);
  transform: translateY(-5px);
}

.step-card::before {
  content: attr(data-step);
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 3rem;
  opacity: 0.1;
  font-family: 'Anonymous Pro', monospace;
  color: #00ff00;
}

.step-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.step-icon {
  font-size: 1.5rem;
  color: #00ff00;
  transition: all 0.3s ease;
}

.step-card:hover .step-icon {
  transform: rotate(5deg);
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.step-title {
  color: #00ff00;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.step-card:hover .step-title {
  transform: translateX(10px);
}

.step-description {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  position: relative;
}

.step-cursor {
  display: inline-block;
  margin-left: 0.5rem;
  animation: blink 1s infinite;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.step-card:hover .step-cursor {
  opacity: 1;
}

.step-tooltip {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 20, 0, 0.9);
  padding: 1rem;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  border-top: 1px solid rgba(0, 255, 0, 0.2);
}

.step-card:hover .step-tooltip {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .steps-container {
    grid-template-columns: 1fr;
  }
  
  .step-card {
    padding: 1.5rem;
  }
}