/* Импорт шрифтов */
@import url('https://fonts.googleapis.com/css2?family=VT323&family=Press+Start+2P&family=Share+Tech+Mono&display=swap');

/* Базовые переменные */
:root {
  /* Цветовая палитра */
  --bg-primary: #0D1B0D;
  --bg-secondary: #0F1F0F;
  --text-primary: #21FF33;
  --text-secondary: #C0FF3E;
  --accent-purple: #8F00FF;
  --accent-cyan: #00FFFF;
  --error-red: #FF0000;
  --success-green: #00FF00;
  
  /* Размеры и отступы */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  
  /* Анимации */
  --transition-speed: 0.3s;
  
  /* Новые цвета */
  --active-green: #00FF00;
  --warning-yellow: #FFD700;
  --glow-cyan: #00FFFF;
  
  /* Эффекты */
  --scanline-opacity: 0.15;
  --glitch-intensity: 0.2s;
}

/* Глобальные стили */
.xnd-root {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: 'Source Code Pro', monospace;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

/* Эффект шума на фоне */
.xnd-noise-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0.05;
  z-index: 1000;
  filter: url(#noise);
}

/* Основной контейнер */
.xnd-dashboard-container {
  min-height: 100vh;
  padding: var(--spacing-md);
  position: relative;
  overflow-x: hidden;
}

/* Окно дашборда */
.xnd-dashboard-window {
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(33, 255, 51, 0.1);
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  margin-top: 1em;
}

/* Заголовок окна */
.xnd-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--text-primary);
}

.xnd-dashboard-controls {
  display: flex;
  gap: var(--spacing-sm);
}

.xnd-control {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.xnd-control.red { background-color: #FF5F56; }
.xnd-control.yellow { background-color: #FFBD2E; }
.xnd-control.green { background-color: #27C93F; }

/* Заголовок */
.xnd-dashboard-title {
  position: relative;
  font-family: 'Press Start 2P', monospace;
  font-size: 20px;
  color: var(--text-secondary);
  text-shadow: 0 0 10px rgba(192, 255, 62, 0.5);
}

.xnd-dashboard-title::after {
  content: '_';
  animation: cursor-blink 1s step-end infinite;
}

@keyframes cursor-blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.xnd-dashboard-title:hover {
  animation: glitch-header 0.3s infinite;
}

@keyframes glitch-header {
  0% {
    transform: translate(0);
    text-shadow: 0 0 10px rgba(192, 255, 62, 0.5);
  }
  25% {
    transform: translate(-2px, 2px);
    text-shadow: -2px 0 var(--accent-purple), 2px 2px var(--accent-cyan);
  }
  50% {
    transform: translate(2px, -2px);
    text-shadow: 2px 0 var(--accent-cyan), -2px -2px var(--accent-purple);
  }
  75% {
    transform: translate(-2px, 2px);
    text-shadow: -2px 0 var(--accent-purple), 2px 2px var(--accent-cyan);
  }
  100% {
    transform: translate(0);
    text-shadow: 0 0 10px rgba(192, 255, 62, 0.5);
  }
}

/* Блок пользователя */
.xnd-user-block {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.xnd-user-label {
  color: var(--text-secondary);
  font-size: 14px;
}

.xnd-username {
  color: var(--accent-cyan);
}

/* Кнопка отключения */
.xnd-disconnect-button {
  background: transparent;
  border: 1px solid var(--error-red);
  color: var(--error-red);
  padding: var(--spacing-xs) var(--spacing-sm);
  cursor: pointer;
  font-family: 'Source Code Pro', monospace;
  transition: all var(--transition-speed);
}

.xnd-disconnect-button:hover {
  background-color: var(--error-red);
  color: var(--bg-primary);
}

/* Вкладки */
.xnd-tabs {
  display: flex;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--text-primary);
}

.xnd-tab {
  background: transparent;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  cursor: pointer;
  font-family: 'Source Code Pro', monospace;
  transition: all var(--transition-speed);
  position: relative;
  overflow: hidden;
}

.xnd-tab:hover {
  background-color: rgba(33, 255, 51, 0.1);
}

.xnd-tab.active {
  background-color: rgba(33, 255, 51, 0.2);
  border-color: var(--text-secondary);
}

.xnd-tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--text-secondary);
  animation: scanline 2s linear infinite;
}

/* Основной контент */
.xnd-dashboard-content {
  padding: var(--spacing-lg);
}

/* Блоки статуса */
.xnd-status-block {
  border: 1px solid var(--text-primary);
  margin-bottom: var(--spacing-lg);
  position: relative;
  overflow: hidden;
}

.xnd-block-header {
  padding: var(--spacing-sm) var(--spacing-md);
  border-bottom: 1px solid var(--text-primary);
  background-color: rgba(33, 255, 51, 0.1);
}

.xnd-block-title {
  margin: 0;
  font-family: 'VT323', monospace;
  font-size: 20px;
  color: var(--text-secondary);
}

.xnd-status-content {
  padding: var(--spacing-md);
  background: rgba(13, 27, 13, 0.3);
}

.xnd-status-row {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-sm);
  padding: var(--spacing-xs) 0;
  border-bottom: 1px solid rgba(33, 255, 51, 0.1);
}

.xnd-status-label {
  color: var(--text-secondary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  min-width: 120px;
}

/* Анимации */
@keyframes scanline {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Адаптивность */
@media (max-width: 768px) {
  .xnd-dashboard-container {
    padding: var(--spacing-sm);
  }
  
  .xnd-dashboard-header {
    flex-direction: column;
    gap: var(--spacing-md);
    padding: var(--spacing-sm);
  }

  .xnd-dashboard-title {
    font-size: 16px;
    text-align: center;
    margin: var(--spacing-sm) 0;
  }

  .xnd-dashboard-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    align-items: center;
  }

  .xnd-user-block {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .xnd-disconnect-button {
    width: 100%;
    margin-top: var(--spacing-sm);
  }

  .xnd-tabs {
    flex-direction: column;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm);
  }

  .xnd-tab {
    width: 100%;
    text-align: center;
  }

  .xnd-dashboard-content {
    padding: var(--spacing-sm);
  }

  .xnd-status-block {
    margin-bottom: var(--spacing-md);
  }

  .xnd-block-header {
    padding: var(--spacing-xs) var(--spacing-sm);
  }

  .xnd-block-title {
    font-size: 16px;
  }

  .xnd-status-content {
    padding: var(--spacing-sm);
  }

  .xnd-status-row {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
  }

  .xnd-status-label {
    min-width: auto;
    margin-bottom: var(--spacing-xs);
  }

  .xnd-api-key-row {
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  .xnd-api-key-text {
    width: 100%;
    text-align: center;
    word-break: break-all;
    padding: var(--spacing-sm);
  }

  .xnd-key-action {
    width: 100%;
    text-align: center;
  }

  .xnd-telegram-info {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }

  .xnd-navigation-grid {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }

  .xnd-nav-card {
    padding: var(--spacing-sm);
  }
}

/* Дополнительные стили для очень маленьких экранов */
@media (max-width: 380px) {
  .xnd-dashboard-title {
    font-size: 14px;
  }

  .xnd-block-title {
    font-size: 14px;
  }

  .xnd-status-label,
  .xnd-status-value,
  .xnd-api-key-text,
  .xnd-telegram-info,
  .xnd-nav-card-description {
    font-size: 12px;
  }

  .xnd-dashboard-controls {
    gap: var(--spacing-xs);
  }

  .xnd-control {
    width: 10px;
    height: 10px;
  }
}

/* Загрузчик */
.xnd-loading-indicator {
  text-align: center;
  padding: var(--spacing-xl);
}

.xnd-blink {
  animation: blink 1s infinite;
}

/* Сообщения об ошибках */
.xnd-error-message {
  color: var(--error-red);
  padding: var(--spacing-md);
  border: 1px solid var(--error-red);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.xnd-error-icon {
  color: var(--error-red);
}

/* API ключ */
.xnd-api-key-row {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.xnd-key-action {
  background: transparent;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  padding: var(--spacing-xs) var(--spacing-sm);
  cursor: pointer;
  transition: all var(--transition-speed);
}

.xnd-key-action:hover {
  background-color: var(--text-primary);
  color: var(--bg-primary);
}

.xnd-key-action.copied {
  background-color: var(--success-green);
  border-color: var(--success-green);
  color: var(--bg-primary);
}

/* Навигационная сетка */
.xnd-navigation-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-xl);
}

.xnd-nav-card {
  position: relative;
  border: 1px solid var(--text-primary);
  background: linear-gradient(
    135deg,
    rgba(33, 255, 51, 0.05) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: var(--spacing-lg);
  text-decoration: none;
  color: var(--text-primary);
  transition: all var(--transition-speed);
  overflow: hidden;
}

.xnd-nav-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(33, 255, 51, 0.1) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.xnd-nav-card:hover::before {
  transform: translateX(100%);
}

.xnd-nav-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(33, 255, 51, 0.2);
}

.xnd-nav-card-content {
  position: relative;
  z-index: 1;
}

.xnd-nav-card-icon {
  font-size: 28px;
  margin-bottom: var(--spacing-md);
  opacity: 0.8;
  transition: all var(--transition-speed);
}

.xnd-nav-card:hover .xnd-nav-card-icon {
  opacity: 1;
  transform: scale(1.1);
}

.xnd-nav-card-title {
  font-family: 'VT323', monospace;
  font-size: 24px;
  margin: 0 0 var(--spacing-sm);
  color: var(--text-secondary);
}

.xnd-nav-card-description {
  font-size: 14px;
  margin: 0;
  opacity: 0.8;
  line-height: 1.6;
}

/* Дополнительные эффекты */
.xnd-retro-input {
  background: var(--bg-primary);
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: 'Source Code Pro', monospace;
  width: 100%;
  transition: all var(--transition-speed);
}

.xnd-retro-input:focus {
  outline: none;
  border-color: var(--text-secondary);
  box-shadow: 0 0 10px rgba(33, 255, 51, 0.2);
}

.xnd-retro-select {
  appearance: none;
  background: var(--bg-primary);
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: 'Source Code Pro', monospace;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.xnd-retro-select:focus {
  outline: none;
  border-color: var(--text-secondary);
}

/* Стилизация скроллбара */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-primary);
}

::-webkit-scrollbar-thumb {
  background: var(--text-primary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}

/* Дополнительные утилиты */
.xnd-text-glitch {
  position: relative;
  display: inline-block;
}

.xnd-text-glitch::before,
.xnd-text-glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.xnd-text-glitch::before {
  left: 2px;
  text-shadow: -2px 0 var(--accent-purple);
  animation: glitch-anim-1 2s infinite linear alternate-reverse;
}

.xnd-text-glitch::after {
  left: -2px;
  text-shadow: 2px 0 var(--accent-cyan);
  animation: glitch-anim-2 3s infinite linear alternate-reverse;
}

@keyframes glitch-anim-1 {
  0% {
    clip-path: inset(20% 0 30% 0);
  }
  100% {
    clip-path: inset(10% 0 85% 0);
  }
}

@keyframes glitch-anim-2 {
  0% {
    clip-path: inset(25% 0 58% 0);
  }
  100% {
    clip-path: inset(43% 0 1% 0);
  }
}

/* Стили для уведомлений */
.xnd-notification {
  position: fixed;
  bottom: var(--spacing-lg);
  right: var(--spacing-lg);
  background: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: var(--spacing-md);
  color: var(--text-primary);
  font-family: 'Source Code Pro', monospace;
  z-index: 1000;
  animation: notification-slide 0.3s ease-out;
  max-width: 300px;
}

@keyframes notification-slide {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.xnd-notification-success {
  border-color: var(--success-green);
  color: var(--success-green);
}

.xnd-notification-error {
  border-color: var(--error-red);
  color: var(--error-red);
}

/* Стили для подсказок */
.xnd-tooltip {
  position: relative;
  display: inline-block;
}

.xnd-tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: all var(--transition-speed);
}

.xnd-tooltip:hover::after {
  opacity: 1;
  transform: translateX(-50%) translateY(-5px);
}

/* Стили для загрузки */
.xnd-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
}

.xnd-loading-dots {
  display: flex;
  gap: var(--spacing-xs);
}

.xnd-loading-dot {
  width: 8px;
  height: 8px;
  background: var(--text-primary);
  border-radius: 50%;
  animation: loading-dot 1s infinite ease-in-out;
}

.xnd-loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.xnd-loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dot {
  0%, 100% {
    transform: scale(0.3);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Улучшенный эффект сканлайн */
.xnd-scanlines {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1001;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 0, 0, var(--scanline-opacity)) 51%
  );
  background-size: 100% 4px;
  animation: scanlines 6s linear infinite;
}

@keyframes scanlines {
  from { transform: translateY(0); }
  to { transform: translateY(4px); }
}

/* Улучшенные переходы между вкладками */
.xnd-tab-transition {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.xnd-tab-transition.active {
  opacity: 1;
  transform: translateY(0);
}

/* Анимация загрузки для статуса подписки */
.xnd-subscription-status {
  position: relative;
}

.xnd-subscription-scanning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Share Tech Mono', monospace;
  animation: scan-fade-out 1s forwards;
}

@keyframes scan-fade-out {
  0% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; pointer-events: none; }
}

/* Улучшенные стили статуса */
.xnd-status-value {
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 2px;
  background: rgba(13, 27, 13, 0.5);
  min-width: 100px;
  text-align: center;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
}

.xnd-status-value.active {
  color: var(--active-green);
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  border: 1px solid var(--active-green);
}

.xnd-status-value.expired {
  color: var(--error-red);
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  border: 1px solid var(--error-red);
}

.xnd-status-value:not(.active):not(.expired) {
  color: var(--text-secondary);
  text-shadow: 0 0 10px rgba(192, 255, 62, 0.5);
  border: 1px solid var(--text-secondary);
}

/* Улучшенные кнопки */
.xnd-disconnect-button,
.xnd-key-action {
  border: 1px solid currentColor;
  transition: all 0.3s ease;
}

.xnd-disconnect-button:hover,
.xnd-key-action:hover {
  box-shadow: 0 0 15px var(--glow-cyan);
  text-shadow: 0 0 5px var(--glow-cyan);
  border-color: var(--glow-cyan);
}

/* Улучшенный блок Telegram */
.xnd-telegram-status {
  font-family: 'Share Tech Mono', monospace;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.xnd-telegram-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  opacity: 0.8;
}

.xnd-telegram-connection {
  position: relative;
  padding-left: 20px;
}

.xnd-telegram-connection::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--active-green);
  box-shadow: 0 0 10px var(--active-green);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: translateY(-50%) scale(1); opacity: 1; }
  50% { transform: translateY(-50%) scale(1.2); opacity: 0.5; }
  100% { transform: translateY(-50%) scale(1); opacity: 1; }
}

/* Улучшенный фоновый декор */
.xnd-matrix-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  opacity: 0.05;
  font-family: 'Share Tech Mono', monospace;
  overflow: hidden;
}

.xnd-matrix-line {
  position: absolute;
  color: var(--text-primary);
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  animation: matrix-fall linear infinite;
}

@keyframes matrix-fall {
  from { transform: translateY(-100%); }
  to { transform: translateY(100vh); }
}

/* Улучшенные стили для списка гайдов */
.xnd-guides-list {
  font-family: 'Share Tech Mono', monospace;
  list-style: none;
  padding: 0;
}

.xnd-guide-item {
  padding: var(--spacing-sm);
  opacity: 0;
  transform: translateX(-10px);
  animation: guide-appear 0.3s forwards;
}

.xnd-guide-item:nth-child(1) { animation-delay: 0.1s; }
.xnd-guide-item:nth-child(2) { animation-delay: 0.2s; }
.xnd-guide-item:nth-child(3) { animation-delay: 0.3s; }
/* и так далее */

@keyframes guide-appear {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Адаптивность */
@media (max-width: 768px) {
  .xnd-dashboard-title {
    font-size: 16px;
  }
  
  .xnd-status-block {
    margin: var(--spacing-md) 0;
  }
  
  .xnd-telegram-info {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Стили для секции гайдов */
.xnd-guides-section {
  font-family: 'Share Tech Mono', monospace;
  background: var(--bg-primary);
  border: 1px solid var(--text-primary);
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.xnd-guides-header {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.xnd-guides-separator {
  color: var(--text-primary);
  margin: var(--spacing-xs) 0;
}

.xnd-guides-list {
  list-style: none;
  padding: 0;
  margin: var(--spacing-md) 0;
}

.xnd-guide-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-xs) 0;
  color: var(--text-primary);
  opacity: 0;
  transform: translateX(-10px);
  animation: guide-appear 0.3s forwards;
}

.xnd-guide-item:hover {
  background: rgba(33, 255, 51, 0.1);
}

.xnd-guide-meta {
  color: var(--text-secondary);
  font-size: 0.9em;
  opacity: 0.8;
}

.xnd-guides-footer {
  color: var(--text-secondary);
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-sm);
  border-top: 1px solid var(--text-primary);
}

@keyframes guide-appear {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.xnd-guide-item:nth-child(1) { animation-delay: 0.1s; }
.xnd-guide-item:nth-child(2) { animation-delay: 0.2s; }
.xnd-guide-item:nth-child(3) { animation-delay: 0.3s; }
.xnd-guide-item:nth-child(4) { animation-delay: 0.4s; }

/* Улучшенные стили для навигационных карточек */
.xnd-nav-card {
  position: relative;
  overflow: hidden;
}

.xnd-nav-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(33, 255, 51, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    transform: rotate(45deg) translateY(-100%);
  }
  100% {
    transform: rotate(45deg) translateY(100%);
  }
}

.xnd-nav-card:hover .xnd-nav-card-icon {
  transform: scale(1.1);
  text-shadow: 0 0 10px var(--text-primary);
}

.xnd-nav-card-title {
  position: relative;
  display: inline-block;
}

.xnd-nav-card-title::after {
  content: '_';
  animation: cursor-blink 1s step-end infinite;
}

/* Стили для API блока */
.xnd-api-key-text {
  font-family: 'Share Tech Mono', monospace;
  background: rgba(13, 27, 13, 0.5);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 2px;
  flex-grow: 1;
  word-break: break-all;
}

.xnd-api-key-button {
  background: transparent;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  cursor: pointer;
  font-family: 'Share Tech Mono', monospace;
  transition: all var(--transition-speed);
  margin-top: var(--spacing-md);
}

.xnd-api-key-button:hover:not(:disabled) {
  background: var(--text-primary);
  color: var(--bg-primary);
}

.xnd-api-key-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Стили для Telegram Access */
.xnd-telegram-access {
  margin-top: var(--spacing-md);
}

.xnd-telegram-status {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.xnd-telegram-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  background: rgba(13, 27, 13, 0.3);
  padding: var(--spacing-sm);
  border-radius: 2px;
}

.xnd-telegram-connection {
  position: relative;
  padding-left: 24px;
  color: var(--text-secondary);
}

.xnd-telegram-connection::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--active-green);
  box-shadow: 0 0 10px var(--active-green);
  animation: pulse 2s infinite;
}

/* Стили для модального окна */
.xnd-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(13, 27, 13, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.xnd-modal-content {
  background: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: var(--spacing-xl);
  position: relative;
  width: 100%;
  max-width: 500px;
  animation: modal-appear 0.3s ease-out;
}

.xnd-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--text-primary);
}

.xnd-modal-title {
  font-family: 'Share Tech Mono', monospace;
  font-size: 20px;
  color: var(--text-secondary);
  margin: 0;
}

.xnd-modal-close {
  background: transparent;
  border: none;
  color: var(--error-red);
  font-size: 20px;
  cursor: pointer;
  padding: var(--spacing-xs);
  transition: all var(--transition-speed);
}

.xnd-modal-close:hover {
  color: var(--text-primary);
  transform: scale(1.1);
}

/* Дополнительные стили для блоков */
.xnd-block-content {
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(33, 255, 51, 0.05) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.xnd-block-separator {
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    var(--text-primary),
    transparent
  );
  margin: var(--spacing-md) 0;
  opacity: 0.3;
}

/* Стили для предупреждений */
.xnd-warning-message {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background: rgba(255, 49, 49, 0.1);
  border: 1px solid var(--error-red);
  margin-top: var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
}

.xnd-warning-icon {
  color: var(--error-red);
  animation: blink 2s infinite;
}

/* Стили для успешных сообщений */
.xnd-success-message {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid var(--success-green);
  margin-top: var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
}

.xnd-success-icon {
  color: var(--success-green);
}

/* Стили для значений статуса */
.xnd-status-value {
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 2px;
  background: rgba(13, 27, 13, 0.5);
  min-width: 100px;
  text-align: center;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
}

.xnd-status-value.active {
  color: var(--active-green);
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  border: 1px solid var(--active-green);
}

.xnd-status-value.expired {
  color: var(--error-red);
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  border: 1px solid var(--error-red);
}

.xnd-status-value:not(.active):not(.expired) {
  color: var(--text-secondary);
  text-shadow: 0 0 10px rgba(192, 255, 62, 0.5);
  border: 1px solid var(--text-secondary);
}

/* Стили для API ключа */
.xnd-api-key-text {
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  background: rgba(13, 27, 13, 0.5);
  padding: var(--spacing-sm);
  border-radius: 2px;
  border: 1px solid rgba(33, 255, 51, 0.2);
  color: var(--text-secondary);
  flex-grow: 1;
  word-break: break-all;
  position: relative;
  overflow: hidden;
}

.xnd-api-key-warning {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
  padding: var(--spacing-sm);
  background: rgba(255, 215, 0, 0.1);
  border: 1px solid var(--warning-yellow);
  font-family: 'Share Tech Mono', monospace;
  font-size: 12px;
  color: var(--warning-yellow);
}

.xnd-api-key-warning .xnd-warning-icon {
  color: var(--warning-yellow);
  animation: blink 2s infinite;
}

/* Стили для Telegram информации */
.xnd-telegram-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  background: rgba(13, 27, 13, 0.3);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: 2px;
  border: 1px solid rgba(33, 255, 51, 0.2);
  margin-top: var(--spacing-sm);
}

.xnd-telegram-info span {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--text-secondary);
}

.xnd-telegram-info .xnd-status-value {
  min-width: auto;
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: 12px;
  margin-left: auto;
}

/* Анимация для API ключа при копировании */
.xnd-api-key-text.copied::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(33, 255, 51, 0.2),
    transparent
  );
  animation: copy-flash 0.5s ease-out;
}

@keyframes copy-flash {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.xnd-renewal-button {
  background: transparent;
  border: 1px solid var(--warning-yellow);
  color: var(--warning-yellow);
  padding: 8px 16px;
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 16px;
  text-shadow: 0 0 5px var(--warning-yellow);
  position: relative;
  overflow: hidden;
}

.xnd-renewal-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 215, 0, 0.2),
    transparent
  );
  transition: transform 0.5s;
}

.xnd-renewal-button:hover {
  background: var(--warning-yellow);
  color: var(--bg-primary);
  box-shadow: 0 0 15px var(--warning-yellow);
}

.xnd-renewal-button:hover::before {
  transform: translateX(200%);
}

.xnd-renewal-button:active {
  transform: scale(0.98);
}

.xnd-welcome-block {
  padding: var(--spacing-md);
  border: 1px solid var(--text-primary);
  background: rgba(13, 27, 13, 0.3);
}

.xnd-welcome-message {
  color: var(--text-secondary);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  margin: var(--spacing-md) 0;
  padding: var(--spacing-md);
  border-left: 2px solid var(--text-primary);
  background: rgba(33, 255, 51, 0.05);
}

.xnd-action-buttons {
  display: flex;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.xnd-info-button {
  background: transparent;
  border: 1px solid var(--text-secondary);
  color: var(--text-secondary);
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.xnd-info-button:hover {
  background: var(--text-secondary);
  color: var(--bg-primary);
  box-shadow: 0 0 15px var(--text-secondary);
}

.xnd-subscribe-button {
  background: transparent;
  border: 1px solid var(--active-green);
  color: var(--active-green);
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 0 0 5px var(--active-green);
}

.xnd-subscribe-button:hover {
  background: var(--active-green);
  color: var(--bg-primary);
  box-shadow: 0 0 15px var(--active-green);
} 