.ascii-animation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Share Tech Mono', monospace;
  color: var(--terminal-text-primary);
  position: relative;
}

.animation-content {
  position: relative;
  padding: 2rem;
  transform-origin: center;
  transition: all 0.5s ease;
}

.frame-container {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
}

.frame-container.in {
  opacity: 1;
}

.frame-container.out {
  opacity: 0;
}

.ascii-art {
  white-space: pre;
  line-height: 1.2;
  font-size: 1.2em;
  margin: 0;
  text-shadow: 0 0 10px var(--terminal-text-primary);
  letter-spacing: 0.1em;
}

/* Состояния */
.state-idle .ascii-art {
  color: #00ff66;
}

.state-connecting .ascii-art {
  color: #00ccff;
}

.state-authenticating .ascii-art {
  color: #ffcc00;
}

.state-success .ascii-art {
  color: #00ff66;
  animation: successPulse 2s ease-in-out infinite;
}

.state-error .ascii-art {
  color: #ff3333;
  animation: errorShake 0.5s ease-in-out;
}

/* Индикаторы статуса */
.status-indicators {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--terminal-text-primary);
  animation: statusPulse 2s infinite;
  position: relative;
  overflow: hidden;
}

.status-dot:nth-child(2) {
  animation-delay: 0.6s;
}

.status-dot:nth-child(3) {
  animation-delay: 1.2s;
}

@keyframes statusPulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes successPulse {
  0%, 100% {
    filter: brightness(1);
    transform: scale(1);
  }
  50% {
    filter: brightness(1.2);
    transform: scale(1.02);
  }
}

@keyframes errorShake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

/* Неоновое свечение для разных состояний */
.state-idle .ascii-art { text-shadow: 0 0 10px #00ff66; }
.state-connecting .ascii-art { text-shadow: 0 0 10px #00ccff; }
.state-authenticating .ascii-art { text-shadow: 0 0 10px #ffcc00; }
.state-success .ascii-art { text-shadow: 0 0 15px #00ff66; }
.state-error .ascii-art { text-shadow: 0 0 15px #ff3333; }

/* Неоновое свечение */
.ambient-glow {
  position: absolute;
  inset: -50px;
  background: radial-gradient(
    circle at center,
    rgba(0, 255, 102, 0.15) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.hovered .ambient-glow {
  opacity: 1;
}

.hovered .animation-content {
  transform: scale(1.02);
}

/* Добавляем красивое свечение текста */
.ascii-art::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(8px);
  opacity: 0.5;
}

/* Плавное изменение яркости при наведении */
.hovered .ascii-art {
  text-shadow: 
    0 0 20px var(--terminal-text-primary),
    0 0 30px var(--terminal-text-primary),
    0 0 40px var(--terminal-text-primary);
  transition: text-shadow 0.5s ease;
}

/* Добавляем мягкую пульсацию */
@keyframes softPulse {
  0%, 100% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.1);
  }
}

.ascii-art {
  animation: softPulse 3s ease-in-out infinite;
}

/* Добавляем эффект сканирования */
.ascii-art::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--terminal-text-primary),
    transparent
  );
  animation: scanline 2s linear infinite;
  opacity: 0.5;
}

@keyframes scanline {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

/* Улучшаем анимацию статус-индикаторов */
.status-dot {
  position: relative;
  overflow: hidden;
}

.status-dot::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: linear-gradient(
    45deg,
    transparent,
    var(--terminal-text-primary),
    transparent
  );
  animation: shimmer 2s linear infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

/* Стили для цифр времени */
.time-digit {
  display: inline-block;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  min-width: 1ch;
  min-height: 1em;
}

.time-digit::before {
  content: attr(data-prev);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  transform-origin: bottom;
  backface-visibility: hidden;
  animation: digitFlipOut 0.3s ease-out both;
}

.time-digit::after {
  content: attr(data-next);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  transform-origin: top;
  backface-visibility: hidden;
  animation: digitFlipIn 0.3s ease-out both;
}

@keyframes digitFlipOut {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(-90deg);
  }
}

@keyframes digitFlipIn {
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0);
  }
}

/* Разные задержки для разных позиций */
.digit-0 { animation-delay: 0s; }
.digit-1 { animation-delay: 0.1s; }
.digit-2 { animation-delay: 0.2s; }
.digit-3 { animation-delay: 0.15s; }
.digit-4 { animation-delay: 0.25s; }
.digit-5 { animation-delay: 0.3s; }
.digit-6 { animation-delay: 0.35s; }
.digit-7 { animation-delay: 0.4s; }

/* Обновляем анимацию матрицы */
@keyframes matrixChange {
  0%, 85% {
    opacity: 1;
  }
  90% {
    opacity: 0.7;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.ascii-art .matrix-line {
  animation: matrixChange 2s infinite;
}

.ascii-art .matrix-line:nth-child(2) {
  animation-delay: 0.7s;
}

.ascii-art .matrix-line:nth-child(3) {
  animation-delay: 1.3s;
} 