:root {
  --private-primary: #B4FF9F;
  --private-secondary: #1A1A1A;
  --private-accent: #FF3366;
  --private-dark: #0D0D0D;
  --private-text: #E0E0E0;
  --private-glow: 0 0 15px var(--private-primary);
  --private-font-mono: 'JetBrains Mono', 'Fira Code', monospace;
  --guides-bg-primary: #0A1B0A;
  --guides-border: #00FF66;
  --guides-bg-secondary: #0F2D0F;
  --guides-text-primary: #A8FFA8;
  --guides-text-secondary: #7FFF00;
  --guides-text-dim: #3D8C3D;
  --guides-heading: #00FFC8;
  --guides-accent: #00FFFF;
  --guides-warning: #FFD700;
  --guides-error: #FF3131;
  --guides-hot: #FF3131;
  --guides-private: #0080FF;
  --neon-glow: 0 0 10px var(--guides-border);
  --neon-text-glow: 0 0 5px currentColor;
}

/* Container */
.private-access-container {
  background-color: var(--private-dark);
  color: var(--private-text);
  min-height: 100vh;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

/* Matrix Background */
.private-access-container::before {
  content: '';
  position: fixed;
  inset: 0;
  background: 
    linear-gradient(rgba(13, 13, 13, 0.9), rgba(13, 13, 13, 0.95)),
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 40px,
      rgba(180, 255, 159, 0.03) 40px,
      rgba(180, 255, 159, 0.03) 41px
    ),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 40px,
      rgba(180, 255, 159, 0.03) 40px,
      rgba(180, 255, 159, 0.03) 41px
    );
  pointer-events: none;
  z-index: 0;
}

/* Header */
.private-header {
  position: relative;
  margin: 6rem auto 4rem;
  padding: 3rem;
  max-width: 1000px;
  z-index: 1;
  transform-style: preserve-3d;
  perspective: 1000px;
}

/* Cyberpunk Frame */
.private-header::before,
.private-header::after {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid var(--private-primary);
  transform: skew(-2deg);
  opacity: 0.3;
  transition: all 0.3s ease;
}

.private-header::after {
  transform: skew(2deg);
  border-color: var(--private-accent);
}

.private-header:hover::before {
  transform: skew(-4deg) translateZ(20px);
  opacity: 0.5;
}

.private-header:hover::after {
  transform: skew(4deg) translateZ(-20px);
  opacity: 0.5;
}

/* ASCII Art Decoration */
.private-header-ascii {
  position: absolute;
  top: -3rem;
  left: -2rem;
  font-family: var(--private-font-mono);
  color: var(--private-primary);
  opacity: 0.15;
  white-space: pre;
  font-size: 0.9rem;
  transform: rotate(-5deg);
  pointer-events: none;
  z-index: -1;
}

/* Enhanced Title */
.typing-text {
  font-family: var(--private-font-mono);
  font-size: 4rem;
  color: var(--private-primary);
  margin-bottom: 2rem;
  position: relative;
  text-shadow: 0 0 20px rgba(180, 255, 159, 0.3);
  animation: glitchText 5s infinite;
  transform: translateZ(50px);
}

/* Glitch Effect */
.typing-text::before,
.typing-text::after {
  content: 'Secure Access Protocol_';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: var(--private-dark);
  animation: glitchLayers 4s infinite;
}

.typing-text::before {
  text-shadow: -2px 0 var(--private-accent);
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  animation-delay: -0.1s;
}

.typing-text::after {
  text-shadow: 2px 0 var(--private-accent);
  clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
  animation-delay: 0.1s;
}

/* Enhanced Description */
.header-description {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  position: relative;
  padding: 2rem;
  background: rgba(180, 255, 159, 0.02);
  border-left: 3px solid var(--private-primary);
  margin-left: 2rem;
  transform: translateZ(30px);
  backdrop-filter: blur(5px);
}

.header-description::before {
  content: '[ENCRYPTED]';
  position: absolute;
  top: -1rem;
  left: 1rem;
  font-family: var(--private-font-mono);
  font-size: 0.8rem;
  color: var(--private-primary);
  padding: 0.3rem 1rem;
  background: var(--private-dark);
  border: 1px solid var(--private-primary);
  transform: translateZ(40px);
}

/* Highlight Words */
.header-description strong {
  color: var(--private-primary);
  position: relative;
  white-space: nowrap;
}

.header-description strong::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 1px;
  background: var(--private-primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header-description:hover strong::after {
  transform: scaleX(1);
}

/* Header Animations */
@keyframes glitchText {
  0%, 100% { transform: translateZ(50px); }
  98% { transform: translateZ(50px); }
  99% { transform: translate3d(-2px, 2px, 50px); }
}

@keyframes glitchLayers {
  0%, 100% { transform: translateX(0); }
  98% { transform: translateX(0); }
  99% { transform: translateX(3px); }
}

/* Pricing Section */
.xnprivate-pricing {
  position: relative;
  max-width: 1200px;
  margin: 4rem auto;
  padding: 2rem;
  display: flex;
  gap: 3rem;
  background: rgba(10, 25, 47, 0.95);
  border: 1px solid var(--private-primary);
}

.xnprivate-pricing::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 1px solid var(--private-primary);
  opacity: 0.3;
  clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 100% 98%, 98% 100%);
}

/* Side Panel with Plans */
.xnprivate-sidebar {
  width: 300px;
  position: relative;
  border-right: 1px dashed var(--private-primary);
  padding-right: 2rem;
}

.xnprivate-title {
  font-family: var(--private-font-mono);
  font-size: 1.1rem;
  color: var(--private-primary);
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.xnprivate-title::before {
  content: '[SELECT_TIER]';
  position: absolute;
  top: -1.5rem;
  left: 0;
  font-size: 0.7rem;
  opacity: 0.6;
}

/* Plans */
.xnprivate-plans {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.xnprivate-plan-option {
  padding: 1rem;
  background: transparent;
  border: 1px solid rgba(180, 255, 159, 0.2);
  color: var(--private-text);
  font-family: var(--private-font-mono);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%);
}

.xnprivate-plan-option::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(180, 255, 159, 0.02) 10px,
    rgba(180, 255, 159, 0.02) 20px
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.xnprivate-plan-option:hover::before {
  opacity: 1;
}

.xnprivate-plan-option.active {
  background: rgba(180, 255, 159, 0.1);
  border-color: var(--private-primary);
  color: var(--private-primary);
}

.xnprivate-plan-icon {
  font-size: 0.8rem;
  opacity: 0.8;
  transition: transform 0.3s ease;
}

.xnprivate-plan-option:hover .xnprivate-plan-icon {
  transform: rotate(90deg);
  opacity: 1;
}

.xnprivate-savings {
  position: absolute;
  right: 2rem;
  font-size: 0.7rem;
  color: var(--private-accent);
  background: rgba(255, 51, 102, 0.1);
  padding: 0.2rem 0.4rem;
  border: 1px solid var(--private-accent);
}

/* Content */
.xnprivate-content {
  flex: 1;
  position: relative;
  padding: 2rem;
  background: rgba(10, 25, 47, 0.98);
  border: 1px solid var(--private-primary);
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0 98%);
}

.xnprivate-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 1px;
  background: linear-gradient(90deg, transparent, var(--private-primary), transparent);
  animation: scanLine 3s linear infinite;
}

/* Badge */
.xnprivate-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-family: var(--private-font-mono);
  font-size: 0.8rem;
  color: var(--private-primary);
  padding: 0.4rem 1rem;
  border: 1px solid var(--private-primary);
  background: rgba(180, 255, 159, 0.05);
  clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
}

.xnprivate-badge::before {
  content: '[ENCRYPTED]';
  opacity: 0.7;
}

/* Price */
.xnprivate-price-wrapper {
  position: relative;
  height: 5rem;
  margin: 2rem 0;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.xnprivate-price {
  position: absolute;
  width: 100%;
  font-family: var(--private-font-mono);
  font-size: 3.5rem;
  color: var(--private-primary);
  text-align: center;
  text-shadow: 0 0 20px rgba(180, 255, 159, 0.2);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.xnprivate-price.exiting {
  transform: translateY(-20px) rotateX(90deg) scale(0.9);
  opacity: 0;
  filter: blur(2px);
}

/* Benefits */
.xnprivate-benefits {
  display: grid;
  gap: 0.8rem;
  margin: 2rem 0;
}

.xnprivate-benefit {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  font-size: 0.9rem;
  background: rgba(180, 255, 159, 0.02);
  border: 1px solid transparent;
  transition: all 0.2s ease;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%);
}

.xnprivate-benefit:hover {
  border-color: var(--private-primary);
  transform: translateX(5px);
  background: rgba(180, 255, 159, 0.05);
}

/* Actions */
.xnprivate-actions {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px dashed var(--private-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.xnprivate-join {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  background: rgba(180, 255, 159, 0.1);
  border: 1px solid var(--private-primary);
  color: var(--private-primary);
  font-family: var(--private-font-mono);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
}

.xnprivate-join::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(180, 255, 159, 0.05) 10px,
    rgba(180, 255, 159, 0.05) 20px
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.xnprivate-join:hover::before {
  opacity: 1;
}

.xnprivate-join:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(180, 255, 159, 0.2);
}

.xnprivate-join-icon {
  transition: transform 0.3s ease;
}

.xnprivate-join:hover .xnprivate-join-icon {
  transform: translateX(5px);
}

.xnprivate-secure {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 0.8rem;
  color: var(--private-text);
  opacity: 0.7;
  font-family: var(--private-font-mono);
}

.xnprivate-secure-icon {
  color: var(--private-primary);
  font-size: 1rem;
}

/* Animations */
@keyframes scanLine {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

/* Mobile */
@media (max-width: 1024px) {
  .xnprivate-pricing {
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  .xnprivate-sidebar {
  width: 100%;
    border-right: none;
    border-bottom: 1px dashed var(--private-primary);
    padding: 0 0 1.5rem 0;
  }

  .xnprivate-title {
    font-size: 1rem;
  margin-bottom: 1.5rem;
    justify-content: center;
  }

  .xnprivate-title::before {
    top: -1.2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .xnprivate-plans {
    flex-direction: column;
  gap: 0.5rem;
}

  .xnprivate-plan-option {
    padding: 0.8rem;
    font-size: 0.85rem;
    justify-content: space-between;
  }

  .xnprivate-savings {
    position: static;
    margin-left: auto;
    font-size: 0.65rem;
  }

  .xnprivate-content {
  padding: 1.5rem;
  }

  .xnprivate-badge {
    width: 100%;
    justify-content: center;
    font-size: 0.75rem;
    padding: 0.3rem 0.8rem;
  }

  .xnprivate-price {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
  }

  .xnprivate-benefits {
    gap: 0.5rem;
  }

  .xnprivate-benefit {
    padding: 0.8rem;
    font-size: 0.85rem;
  }

  .xnprivate-actions {
  flex-direction: column;
  gap: 1rem;
    align-items: stretch;
    text-align: center;
  }

  .xnprivate-join {
    width: 100%;
    justify-content: center;
    padding: 0.7rem 1rem;
  }

  .xnprivate-secure {
    justify-content: center;
    text-align: center;
    font-size: 0.75rem;
  }

  .private-header {
    margin: 3rem auto;
    padding: 1.5rem;
  }

  .typing-text {
    font-size: 2.5rem;
  }

  .header-description {
    font-size: 1rem;
    padding: 1.5rem;
    margin-left: 0;
    transform: none;
  }

  .private-header-ascii {
    font-size: 0.7rem;
    top: -2rem;
    left: -1rem;
  }
}

@media (max-width: 480px) {
  .xnprivate-pricing {
    margin: 1rem;
    padding: 1rem;
  }

  .xnprivate-content {
    padding: 1rem;
  }

  .xnprivate-price {
    font-size: 2rem;
  }

  .xnprivate-plan-option {
    padding: 0.7rem;
  font-size: 0.8rem;
  }

  .xnprivate-benefit {
    padding: 0.7rem;
    font-size: 0.8rem;
    gap: 0.5rem;
  }

  .xnprivate-join {
    font-size: 0.85rem;
    padding: 0.6rem 1rem;
  }

  .private-header {
    margin: 2rem auto;
  padding: 1rem;
  }

  .typing-text {
  font-size: 2rem;
  }

  .header-description {
    padding: 1rem;
    font-size: 0.9rem;
  }

  .header-description::before {
    font-size: 0.7rem;
    padding: 0.2rem 0.8rem;
  }
}

/* Custom Node Scripts Section */
.xnprivate-scripts {
  position: relative;
  max-width: 1200px;
  margin: 6rem auto;
  padding: 3rem;
  background: rgba(10, 25, 47, 0.98);
  border: 1px solid var(--private-primary);
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.xnprivate-scripts::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 1px solid var(--private-primary);
  opacity: 0.3;
  clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 98% 98%, 96% 100%, 2% 100%, 0 98%);
}

.xnprivate-scripts-title {
  font-family: var(--private-font-mono);
  font-size: 2rem;
  color: var(--private-primary);
  margin-bottom: 3rem;
  position: relative;
  padding-left: 2rem;
}

.xnprivate-scripts-title::before {
  content: '[SCRIPTS]';
  position: absolute;
  top: -1.5rem;
  left: 2rem;
  font-size: 0.8rem;
  opacity: 0.6;
}

/* Script Grid */
.xnprivate-scripts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.xnprivate-script-card {
  position: relative;
  padding: 1.5rem;
  background: rgba(180, 255, 159, 0.02);
  border: 1px solid rgba(180, 255, 159, 0.1);
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 95%);
}

.xnprivate-script-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(180, 255, 159, 0.01) 10px,
    rgba(180, 255, 159, 0.01) 20px
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.xnprivate-script-card:hover {
  transform: translateY(-5px);
  border-color: var(--private-primary);
  box-shadow: 0 5px 20px rgba(180, 255, 159, 0.1);
}

.xnprivate-script-card:hover::before {
  opacity: 1;
}

.xnprivate-script-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.xnprivate-script-icon {
  font-size: 1.2rem;
  color: var(--private-primary);
  opacity: 0.8;
}

.xnprivate-script-name {
  font-family: var(--private-font-mono);
  font-size: 1.1rem;
  color: var(--private-primary);
  margin: 0;
}

.xnprivate-script-description {
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  padding-left: 2rem;
  border-left: 2px solid rgba(180, 255, 159, 0.1);
}

.xnprivate-script-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.xnprivate-script-tech {
  display: flex;
  gap: 0.5rem;
}

.xnprivate-tech-tag {
  font-family: var(--private-font-mono);
  font-size: 0.7rem;
  color: var(--private-accent);
  padding: 0.2rem 0.5rem;
  background: rgba(255, 51, 102, 0.1);
  border: 1px solid var(--private-accent);
}

.xnprivate-script-preview {
  font-family: var(--private-font-mono);
  font-size: 0.8rem;
  color: var(--private-primary);
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
}

.xnprivate-script-preview:hover {
  color: var(--private-accent);
  transform: translateX(5px);
}

/* Mobile Styles */
@media (max-width: 1024px) {
  .xnprivate-scripts {
    margin: 3rem 1rem;
    padding: 2rem;
  }

  .xnprivate-scripts-title {
    font-size: 1.5rem;
    padding-left: 1rem;
    text-align: center;
  }

  .xnprivate-scripts-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

@media (max-width: 480px) {
  .xnprivate-scripts {
    margin: 2rem 1rem;
  padding: 1.5rem;
  }

  .xnprivate-script-card {
    padding: 1rem;
  }

  .xnprivate-script-name {
    font-size: 1rem;
  }

  .xnprivate-script-description {
    font-size: 0.85rem;
    padding-left: 1rem;
  }
}

/* Node Installation Menu */
.xnprivate-node-menu {
  position: relative;
  max-width: 800px;
  margin: 6rem auto;
  font-family: var(--private-font-mono);
}

.xnprivate-menu-window {
  background: #1a1b26;
  border: 1px solid #414868;
    padding: 1rem;
  font-family: 'Courier New', monospace;
  color: #c0caf5;
  min-width: 600px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.xnprivate-menu-header {
  color: #7aa2f7;
  font-size: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid #414868;
}

.xnprivate-menu-status {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #9ece6a;
}

.xnprivate-status-icon[data-status="running"] {
  color: #9ece6a;
}

.xnprivate-status-icon[data-status="stopped"] {
  color: #f7768e;
}

.xnprivate-status-icon[data-status="restarting"] {
  color: #e0af68;
  animation: spin 1s linear infinite;
}

.xnprivate-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.xnprivate-menu-item {
  padding: 0.4rem 1rem;
  color: #c0caf5;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.xnprivate-menu-item.active {
  background: #283457;
  color: #7aa2f7;
}

.xnprivate-menu-item:hover {
  background: #1f2335;
}

.xnprivate-menu-item-icon {
  color: #bb9af7;
  width: 20px;
  text-align: center;
}

.xnprivate-menu-controls {
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #414868;
  display: flex;
  justify-content: space-between;
  color: #7aa2f7;
}

/* Добавляем стили для логов */
.xnprivate-logs {
  margin: 1rem 0;
  padding: 0.5rem;
  background: #1f2335;
  max-height: 200px;
  overflow-y: auto;
  font-size: 0.9rem;
  color: #9ece6a;
}

/* Добавляем стили для системной информации */
.xnprivate-system-info {
  margin: 1rem 0;
  padding: 0.5rem;
  background: #1f2335;
  color: #7aa2f7;
}

.xnprivate-system-info-item {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0;
}

/* Status animations */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.xnprivate-status-icon[data-status="running"] {
  animation: blink 2s infinite;
}

.xnprivate-status-icon[data-status="restarting"] {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Menu Animation */
.xnprivate-menu-window::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 1px;
  background: linear-gradient(90deg, transparent, var(--private-primary), transparent);
  animation: scanLine 3s linear infinite;
}

@media (max-width: 768px) {
  .xnprivate-node-menu {
    margin: 3rem 1rem;
  }
  
  .xnprivate-menu-window {
  padding: 1.5rem;
  }
  
  .xnprivate-menu-item {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

/* Добавляем стили для активного пункта меню */
.xnprivate-menu-item.active {
  background: rgba(180, 255, 159, 0.1);
  border: 1px solid var(--private-primary);
  transform: translateX(10px);
}

/* Улучшаем доступность */
.xnprivate-menu-window:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--private-primary);
}

.xnprivate-menu-item.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

/* Modal Styles */
.xnprivate-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(10, 25, 47, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease;
}

.xnprivate-node-menu {
  width: 100%;
  max-width: 800px;
  margin: 2rem;
  animation: slideIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Добавляем стили для кнопки в карточке скрипта */
.xnprivate-script-preview {
  position: relative;
  overflow: hidden;
}

.xnprivate-script-preview::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(180, 255, 159, 0.05) 10px,
    rgba(180, 255, 159, 0.05) 20px
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.xnprivate-script-preview:hover::before {
  opacity: 1;
}

/* Sync Manager Styles */
.xnprivate-sync-window {
  background: #1a1b26;
  border: 1px solid #414868;
  padding: 1.5rem;
  width: 100%;
  max-width: 800px;
  color: #c0caf5;
  font-family: 'Courier New', monospace;
}

.xnprivate-sync-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #414868;
}

.xnprivate-sync-status {
  color: #9ece6a;
}

.xnprivate-sync-progress {
  height: 2px;
  background: #1f2335;
  margin: 2rem 0;
  position: relative;
  overflow: hidden;
}

.xnprivate-sync-bar {
  position: absolute;
  height: 100%;
  background: #7aa2f7;
  transition: width 0.3s ease;
}

.xnprivate-sync-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 2rem 0;
}

.xnprivate-sync-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background: #1f2335;
}

/* Analytics Styles */
.xnprivate-analytics-window {
  background: #1a1b26;
  border: 1px solid #414868;
  padding: 1.5rem;
  width: 100%;
  max-width: 1000px;
  color: #c0caf5;
  font-family: 'Courier New', monospace;
}

.xnprivate-analytics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
}

.xnprivate-metric-card {
  background: #1f2335;
  padding: 1.5rem;
  border: 1px solid #414868;
}

.xnprivate-metric-value {
    font-size: 2rem;
  color: #7aa2f7;
  margin: 1rem 0;
}

.xnprivate-metric-details {
  color: #9ece6a;
  font-size: 0.9rem;
}

/* Общие стили для кнопок */
.xnprivate-sync-btn,
.xnprivate-analytics-btn {
  background: none;
  border: 1px solid #7aa2f7;
  color: #7aa2f7;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.xnprivate-sync-btn:hover,
.xnprivate-analytics-btn:hover {
  background: rgba(122, 162, 247, 0.1);
}

.xnprivate-sync-btn.cancel,
.xnprivate-analytics-btn.cancel {
  border-color: #f7768e;
  color: #f7768e;
}

.xnprivate-sync-btn.cancel:hover,
.xnprivate-analytics-btn.cancel:hover {
  background: rgba(247, 118, 142, 0.1);
}

/* Support Chat Styles */
.xnprivate-support {
  position: relative;
  max-width: 1200px;
  margin: 6rem auto;
  padding: 3rem;
}

.xnprivate-support-header {
  text-align: center;
  margin-bottom: 2rem;
}

.xnprivate-support-ascii {
  font-family: var(--private-font-mono);
  color: var(--private-primary);
  white-space: pre;
  margin-bottom: 2rem;
  opacity: 0.8;
}

.xnprivate-support-toggle {
  font-family: var(--private-font-mono);
  background: none;
  border: 1px solid var(--private-primary);
  color: var(--private-primary);
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.xnprivate-support-toggle::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(180, 255, 159, 0.05) 10px,
    rgba(180, 255, 159, 0.05) 20px
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.xnprivate-support-toggle:hover::before {
  opacity: 1;
}

/* Chat Modal */
.xnprivate-chat-modal {
  position: fixed;
  inset: 0;
  background: rgba(10, 25, 47, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease;
}

.xnprivate-chat-window {
  width: 100%;
  max-width: 800px;
  height: 600px;
  background: #1a1b26;
  border: 1px solid #414868;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease;
}

.xnprivate-chat-titlebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #1f2335;
  border-bottom: 1px solid #414868;
}

.xnprivate-chat-title {
  color: #7aa2f7;
  font-family: var(--private-font-mono);
}

.xnprivate-chat-controls span {
  margin-left: 1rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.xnprivate-chat-controls span:hover {
  opacity: 1;
}

.xnprivate-chat-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background: #1a1b26;
}

.xnprivate-chat-welcome {
  text-align: center;
  margin-bottom: 2rem;
}

.xnprivate-ascii-logo {
  color: #7aa2f7;
  font-family: var(--private-font-mono);
  margin: 0;
}

.xnprivate-chat-message {
  margin-bottom: 1rem;
  font-family: var(--private-font-mono);
}

.message-timestamp {
  color: #565f89;
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}

.message-system {
  color: #9ece6a;
  font-style: italic;
}

.message-content {
  color: #c0caf5;
}

.message-prefix {
  color: #7aa2f7;
  margin-right: 0.5rem;
}

.xnprivate-chat-typing {
  color: #565f89;
  font-style: italic;
}

.xnprivate-chat-typing span {
  animation: blink 1s infinite;
}

.xnprivate-chat-typing span:nth-child(2) {
  animation-delay: 0.2s;
}

.xnprivate-chat-typing span:nth-child(3) {
  animation-delay: 0.4s;
}

.xnprivate-chat-input {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #1f2335;
  border-top: 1px solid #414868;
}

.input-prefix {
  color: #9ece6a;
  margin-right: 0.5rem;
  font-family: var(--private-font-mono);
}

.xnprivate-chat-input input {
  flex: 1;
  background: none;
  border: none;
  color: #c0caf5;
  font-family: var(--private-font-mono);
  font-size: 1rem;
}

.xnprivate-chat-input input:focus {
  outline: none;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* Community Chat Styles */
.xnprivate-community {
  max-width: 1200px;
  margin: 4rem auto;
  background: var(--private-dark);
  border: 1px solid var(--private-primary);
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.xnprivate-community::after {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid var(--private-accent);
  opacity: 0.3;
  pointer-events: none;
}

.xnprivate-community-header {
  background: linear-gradient(90deg, 
    rgba(13, 13, 13, 0.98),
    rgba(180, 255, 159, 0.05),
    rgba(13, 13, 13, 0.98)
  );
  padding: 2rem;
  border-bottom: 1px solid rgba(180, 255, 159, 0.2);
  position: relative;
}

.xnprivate-community-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--private-primary);
  font-size: 1.4rem;
  font-family: var(--private-font-mono);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.title-icon {
  font-size: 1.4rem;
  text-shadow: 0 0 10px var(--private-primary);
}

.online-indicator {
  margin-left: auto;
  color: var(--private-primary);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(180, 255, 159, 0.1);
  border: 1px solid rgba(180, 255, 159, 0.2);
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
}

.xnprivate-chat-feed {
  height: 600px;
  overflow-y: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: linear-gradient(180deg,
    rgba(13, 13, 13, 0.95),
    rgba(13, 13, 13, 0.98)
  );
}

.chat-message {
  display: flex;
  gap: 1.5rem;
    opacity: 0;
  transform: translateY(20px) scale(0.98);
  animation: messageSlideIn 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  position: relative;
}

.message-avatar {
  width: 48px;
  height: 48px;
  background: rgba(180, 255, 159, 0.1);
  border: 1px solid rgba(180, 255, 159, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  position: relative;
  transform: skew(-5deg);
  transition: all 0.3s ease;
  clip-path: polygon(30% 0, 100% 0, 100% 70%, 70% 100%, 0 100%, 0 30%);
}

.message-content {
  flex: 1;
  background: rgba(180, 255, 159, 0.05);
  padding: 1.2rem;
  position: relative;
  border: 1px solid rgba(180, 255, 159, 0.1);
  transform: skew(-1deg);
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
}

.author-name {
  color: var(--private-primary);
  font-weight: 500;
  font-family: var(--private-font-mono);
  letter-spacing: 1px;
}

.admin-name {
  color: var(--private-accent);
  position: relative;
}

.admin-name::after {
  content: '[ADMIN]';
  margin-left: 0.8rem;
  font-size: 0.8rem;
  color: var(--private-accent);
  font-family: var(--private-font-mono);
  text-shadow: 0 0 10px rgba(255, 51, 102, 0.5);
}

.message-time {
  color: rgba(180, 255, 159, 0.5);
  font-size: 0.8rem;
  font-family: var(--private-font-mono);
}

.message-text {
  color: var(--private-text);
  line-height: 1.6;
  font-size: 0.95rem;
}

.system-message {
  justify-content: center;
  margin: 2rem 0;
}

.system-content {
  color: var(--private-primary);
  font-family: var(--private-font-mono);
  text-align: center;
  padding: 1rem 2rem;
  background: rgba(180, 255, 159, 0.05);
  border: 1px solid rgba(180, 255, 159, 0.2);
  position: relative;
  transform: skew(-2deg);
  letter-spacing: 2px;
  text-transform: uppercase;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
}

.xnprivate-chat-input {
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  background: linear-gradient(90deg,
    rgba(180, 255, 159, 0.05),
    rgba(180, 255, 159, 0.1),
    rgba(180, 255, 159, 0.05)
  );
  border-top: 1px solid rgba(180, 255, 159, 0.2);
  position: relative;
}

.input-avatar {
  width: 48px;
  height: 48px;
  background: rgba(180, 255, 159, 0.1);
  border: 1px solid rgba(180, 255, 159, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  transform: skew(-5deg);
  clip-path: polygon(30% 0, 100% 0, 100% 70%, 70% 100%, 0 100%, 0 30%);
}

.chat-input-field {
  flex: 1;
  background: rgba(13, 13, 13, 0.95);
  border: 1px solid rgba(180, 255, 159, 0.2);
  padding: 1rem 1.5rem;
  color: var(--private-text);
  font-family: var(--private-font-mono);
  transform: skew(-1deg);
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.chat-input-field:focus {
  outline: none;
  border-color: var(--private-primary);
  box-shadow: 0 0 15px rgba(180, 255, 159, 0.2);
}

.send-button {
  background: var(--private-primary);
  color: var(--private-dark);
  border: none;
  padding: 0 2rem;
  font-family: var(--private-font-mono);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: skew(-5deg);
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
  z-index: 10;
}

.send-button:hover {
  background: var(--private-accent);
  transform: skew(-5deg) translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 51, 102, 0.3);
}

@keyframes messageSlideIn {
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* FAQ Styles */
.xnprivate-faq {
  max-width: 1200px;
  margin: 6rem auto;
  padding: 0 2rem;
  position: relative;
}

.xnprivate-faq::before {
  content: '';
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg,
    transparent,
    var(--private-primary),
    transparent
  );
}

.xnprivate-faq-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
  position: relative;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
}

.faq-icon {
  font-size: 2rem;
  color: var(--private-accent);
  animation: cursorBlink 1s infinite;
  font-family: var(--private-font-mono);
}

.faq-title {
  color: var(--private-primary);
  font-size: 2rem;
  font-family: var(--private-font-mono);
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 0 0 10px rgba(180, 255, 159, 0.3);
}

.faq-header-line {
  flex: 1;
  height: 1px;
  background: linear-gradient(90deg,
    var(--private-primary),
    transparent
  );
  margin-left: 2rem;
}

.xnprivate-faq-grid {
  display: grid;
  gap: 1.5rem;
}

.faq-item {
  background: rgba(180, 255, 159, 0.02);
  border: 1px solid rgba(180, 255, 159, 0.1);
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.faq-item::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(180, 255, 159, 0.02) 10px,
    rgba(180, 255, 159, 0.02) 20px
  );
    opacity: 0;
  transition: opacity 0.3s ease;
}

.faq-item:hover::before {
  opacity: 1;
}

.faq-question {
  padding: 1.5rem;
    position: relative;
}

.faq-question-header {
    display: flex;
    justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.faq-marker {
  color: var(--private-primary);
  font-size: 0.9rem;
  font-family: var(--private-font-mono);
  opacity: 0.8;
}

.faq-status {
  font-family: var(--private-font-mono);
  font-size: 0.8rem;
  color: var(--private-accent);
  padding: 0.2rem 0.5rem;
  border: 1px solid var(--private-accent);
  background: rgba(255, 51, 102, 0.1);
}

.faq-question-content {
  color: var(--private-text);
  font-family: var(--private-font-mono);
  font-size: 1.1rem;
  margin-right: 2rem;
}

.faq-toggle {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--private-primary);
  font-family: var(--private-font-mono);
  transition: transform 0.3s ease;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background: rgba(13, 13, 13, 0.95);
}

.faq-item.expanded .faq-answer {
  max-height: 1000px;
}

.faq-answer-content {
  padding: 1.5rem;
  color: var(--private-text);
  line-height: 1.6;
  font-family: var(--private-font-mono);
  border-top: 1px solid rgba(180, 255, 159, 0.1);
  position: relative;
}

.answer-prefix {
  display: block;
  color: var(--private-primary);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  opacity: 0.8;
}

.faq-code {
  margin: 1.5rem;
  background: rgba(180, 255, 159, 0.05);
  border: 1px solid rgba(180, 255, 159, 0.1);
  overflow: hidden;
  position: relative;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  background: rgba(180, 255, 159, 0.1);
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
}

.code-title {
  color: var(--private-primary);
  font-family: var(--private-font-mono);
  font-size: 0.9rem;
  opacity: 0.8;
}

.copy-btn {
  background: none;
  border: 1px solid var(--private-primary);
  color: var(--private-primary);
  padding: 0.3rem 1rem;
  font-family: var(--private-font-mono);
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
}

.copy-btn:hover {
  background: var(--private-primary);
  color: var(--private-dark);
  transform: translateY(-1px);
}

.code-content {
  margin: 0;
  padding: 1.2rem;
  color: var(--private-text);
  font-family: var(--private-font-mono);
  font-size: 0.9rem;
  line-height: 1.5;
  overflow-x: auto;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 28px,
    rgba(180, 255, 159, 0.03) 28px,
    rgba(180, 255, 159, 0.03) 29px
  );
}

@keyframes cursorBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* Обновляем стили кнопок действия с уникальным префиксом */
.xnprivate-action-button {
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  background: rgba(13, 13, 13, 0.95);
  border: 1px solid var(--private-primary);
  color: var(--private-primary);
  font-family: var(--private-font-mono);
  text-decoration: none;
  margin: 1rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%);
  z-index: 10;
}

.xnprivate-action-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg,
    transparent,
    rgba(180, 255, 159, 0.2),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.xnprivate-action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(180, 255, 159, 0.3);
  background: rgba(180, 255, 159, 0.1);
}

.xnprivate-action-button:hover::before {
  transform: translateX(100%);
}

.xnprivate-action-button.pulsing {
  animation: buttonPulse 2s infinite;
  background: rgba(13, 13, 13, 0.95);
  border-width: 2px;
}

/* Обновляем стили для оповещения */
.subscription-alert-overlay {
  position: fixed;
  inset: 0;
  background: rgba(13, 13, 13, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: overlayAppear 0.3s ease;
}

.subscription-alert {
  position: relative;
  background: rgba(13, 13, 13, 0.98);
  border: 1px solid var(--private-primary);
  padding: 2.5rem;
  max-width: 400px;
  text-align: center;
  animation: alertAppear 0.3s ease;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

.subscription-alert::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 1px solid var(--private-accent);
  opacity: 0.5;
  clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 100% 98%, 98% 100%, 2% 100%, 0 98%);
  pointer-events: none;
}

.alert-close-btn {
  position: absolute;
    top: 1rem;
    right: 1rem;
  background: none;
  border: none;
  color: var(--private-accent);
  font-family: var(--private-font-mono);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  transition: all 0.3s ease;
  opacity: 0.8;
  z-index: 1;
}

.alert-close-btn:hover {
  opacity: 1;
  transform: scale(1.1);
  color: var(--private-primary);
}

.alert-title {
  color: var(--private-accent);
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  font-family: var(--private-font-mono);
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(255, 51, 102, 0.3);
}

.alert-message {
  color: var(--private-text);
  margin-bottom: 2rem;
  line-height: 1.6;
  font-family: var(--private-font-mono);
  font-size: 0.95rem;
}

.alert-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: var(--private-primary);
  color: var(--private-dark);
  text-decoration: none;
  font-family: var(--private-font-mono);
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
  border: none;
  cursor: pointer;
}

.alert-button:hover {
  background: var(--private-accent);
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(255, 51, 102, 0.3);
}

@keyframes overlayAppear {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes alertAppear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Обновляем стили админских сообщений */
.admin-message {
  position: relative;
  background: rgba(255, 51, 102, 0.05);
}

.admin-name {
  color: var(--private-accent);
  position: relative;
}

.admin-name::after {
  content: '[ADMIN]';
  font-size: 0.8em;
  margin-left: 0.5rem;
  opacity: 0.8;
}

/* Новые анимации */
@keyframes statusPulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes adminGlow {
  0%, 100% { box-shadow: 0 0 5px var(--private-accent); }
  50% { box-shadow: 0 0 15px var(--private-accent); }
}

.faq-footer {
  text-align: center;
  margin-top: 3rem;
  padding: 2rem;
  background: linear-gradient(180deg,
    transparent,
    rgba(180, 255, 159, 0.05)
  );
}

/* Улучшаем анимации статусов */
.faq-status {
  position: relative;
  overflow: hidden;
}

.faq-status::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg,
    transparent,
    rgba(255, 51, 102, 0.2),
    transparent
  );
  animation: statusScan 2s linear infinite;
}

@keyframes statusScan {
  to { transform: translateX(100%); }
}

/* Обновляем стили для секции гайдов */
.xnprivate-guides {
  position: relative;
  max-width: 1200px;
  margin: 6rem auto;
  padding: 3rem;
}

.xnprivate-guides-content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 450px; /* Разделяем на две колонки */
  gap: 2rem;
}

.xnprivate-guides-main {
  padding: 2rem;
  background: rgba(10, 25, 47, 0.98);
  border: 1px solid var(--private-primary);
  clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
}

/* Стили для списка кнопок */
.guides-features-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.guide-feature-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid transparent;
  background: var(--guides-bg-secondary);
  margin-bottom: 0.5rem;
  position: relative;
  font-family: 'Share Tech Mono', monospace;
  overflow: hidden;
}

.guide-feature-item:hover {
  border-color: var(--guides-border);
  background: var(--guides-bg-hover);
  transform: translateX(5px);
  padding-left: 2rem;
}

.guide-feature-item.active {
  border-color: var(--category-color);
  background: rgba(0, 255, 102, 0.1);
  box-shadow: var(--neon-glow);
}

.guide-feature-item::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg,
    transparent,
    rgba(0, 255, 102, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.guide-feature-item:hover::before {
  transform: translateX(100%);
}

.guide-feature-item.active::after {
  content: '>';
  position: absolute;
  right: 1rem;
  color: var(--category-color);
  animation: blink 1s infinite;
}

/* Стили для окна справа */
.xnprivate-guides-window {
  width: 450px;
  background: var(--guides-bg-primary);
  border: 1px solid var(--guides-border);
  box-shadow: var(--neon-glow), 0 10px 30px rgba(0, 0, 0, 0.5);
  position: sticky; /* Делаем окно прилипающим */
  top: 2rem;
  height: fit-content;
  animation: windowAppear 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.xnprivate-guides-main::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 1px solid var(--private-primary);
  opacity: 0.3;
  clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 98% 98%, 96% 100%, 2% 100%, 0 98%);
}

.xnprivate-guides-info {
  max-width: 600px;
}

.guides-info-description {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--private-text);
  margin: 2rem 0;
  padding-left: 1rem;
  border-left: 2px solid var(--private-primary);
}

.guides-features-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  font-size: 0.95rem;
  color: var(--private-text);
  border-bottom: 1px solid rgba(180, 255, 159, 0.1);
}

.guides-access-btn {
  display: inline-block;
  padding: 1rem 2rem;
  background: var(--private-primary);
  color: var(--private-dark);
  text-decoration: none;
  font-family: var(--private-font-mono);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2rem;
  transition: all 0.3s ease;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
}

.guides-access-btn:hover {
  background: var(--private-accent);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 51, 102, 0.3);
}

.xnprivate-guides-window::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    0deg,
    transparent 0%,
    rgba(0, 255, 102, 0.03) 50%,
    transparent 100%
  );
  background-size: 100% 4px;
  pointer-events: none;
  animation: scanlines 10s linear infinite;
}

.xnprivate-guides-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1rem;
  background: var(--guides-bg-secondary);
  border-bottom: 1px solid var(--guides-border);
  font-family: 'Share Tech Mono', monospace;
  text-shadow: var(--neon-text-glow);
}

.guides-status {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: var(--guides-text-secondary);
}

.status-dot {
  width: 8px;
  height: 8px;
  background: var(--guides-text-secondary);
  border-radius: 50%;
  animation: blink 2s infinite;
}

/* Стили для разных категорий */
.xnprivate-guides-window[data-category="setup"] {
  --category-color: var(--guides-accent);
}

.xnprivate-guides-window[data-category="performance"] {
  --category-color: var(--guides-warning);
}

.xnprivate-guides-window[data-category="security"] {
  --category-color: var(--guides-error);
}

.xnprivate-guides-window[data-category="troubleshooting"] {
  --category-color: var(--guides-text-secondary);
}

/* Терминал для Setup */
.terminal-window {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--category-color);
  padding: 1rem;
  font-family: 'Share Tech Mono', monospace;
  margin: 1rem;
}

.terminal-header {
  color: var(--category-color);
  border-bottom: 1px solid var(--category-color);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.command-line {
  color: var(--guides-text-primary);
  margin-bottom: 0.5rem;
}

.progress-line {
  color: var(--category-color);
  margin: 0.5rem 0;
}

/* Метрики для Performance */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.metric-box {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid var(--category-color);
  padding: 1rem;
  text-align: center;
}

.metric-chart {
  color: var(--category-color);
  font-family: monospace;
  margin-top: 0.5rem;
}

/* Чеклист для Security */
.security-checklist {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--category-color);
  margin: 1rem;
}

.checklist-header {
  background: rgba(255, 51, 102, 0.1);
  padding: 0.8rem;
  color: var(--category-color);
  border-bottom: 1px solid var(--category-color);
}

.checklist-item {
  padding: 0.8rem;
  border-bottom: 1px solid rgba(255, 51, 102, 0.2);
}

.checklist-item.success {
  color: #50FF50;
}

.checklist-item.warning {
  color: #FFD700;
}

.checklist-item.info {
  color: #00FFFF;
}

/* Консоль для Troubleshooting */
.error-console {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--category-color);
  margin: 1rem;
}

.error-header {
  background: rgba(80, 255, 80, 0.1);
  padding: 0.8rem;
  color: var(--category-color);
}

.error-content {
  padding: 1rem;
}

.error-line {
  color: #FF3366;
  margin-bottom: 0.5rem;
}

.solution-line {
  color: #50FF50;
  padding-left: 1rem;
}

.timestamp {
  color: var(--guides-text-dim);
  margin-right: 1rem;
}

/* Общие стили для всех окон */
.guides-action-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(0, 255, 102, 0.02);
  border: 1px solid var(--priv-accent);
}

.guides-action-btn {
  font-family: 'Share Tech Mono', monospace;
  background: transparent;
  color: var(--priv-text-primary);
  border: none;
  padding: 0.8rem 1.2rem;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
    font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.guides-action-btn::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--priv-accent);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.guides-action-btn:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.guides-action-btn::after {
  content: '→';
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.guides-action-btn:hover::after {
  opacity: 1;
  transform: translateX(5px);
}

.guides-action-btn:hover {
  color: var(--priv-accent);
  text-shadow: 0 0 8px rgba(0, 255, 102, 0.3);
}

.guides-action-btn.active {
  color: var(--priv-accent);
}

.guides-action-btn.active::before {
  transform: scaleX(1);
}

/* Анимация при нажатии */
.guides-action-btn:active {
  transform: translateY(1px);
}

/* Стили для иконки, если она есть */
.guides-action-btn .icon {
  transition: transform 0.3s ease;
}

.guides-action-btn:hover .icon {
  transform: translateX(3px);
}

.guides-stats {
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
  color: var(--guides-text-dim);
}

@keyframes scanlines {
  0% { background-position: 0 0; }
  100% { background-position: 0 100%; }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Анимация переключения окон */
.xnprivate-guides-window.changing {
  animation: windowChange 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes windowChange {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Улучшаем эффект сканлайнов */
.xnprivate-guides-window::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    transparent 50%,
    rgba(0, 255, 102, 0.025) 50%
  );
  background-size: 100% 4px;
  animation: scanlines 10s linear infinite;
  pointer-events: none;
  z-index: 2;
}

/* Стили для терминального окна Setup */
.setup-guide-preview {
  padding: 1rem;
}

.terminal-window {
  background: rgba(0, 0, 0, 0.95);
  border: 1px solid var(--category-color);
  border-radius: 4px;
  font-family: 'Share Tech Mono', monospace;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 255, 102, 0.1);
}

/* Эффект сканлайнов */
.terminal-window::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 2px,
    rgba(0, 255, 102, 0.02) 3px,
    rgba(0, 255, 102, 0.02) 4px
  );
  pointer-events: none;
  animation: scanlines 60s linear infinite;
}

/* Заголовок терминала */
.terminal-header {
  background: var(--guides-bg-secondary);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--category-color);
  position: relative;
  overflow: hidden;
}

.terminal-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--category-color),
    transparent
  );
}

.terminal-title {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.terminal-title-main {
  color: #50FF50;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(80, 255, 80, 0.5);
}

.terminal-title-sub {
  color: var(--guides-text-dim);
  font-size: 0.8rem;
  letter-spacing: 3px;
}

.terminal-status {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.4rem 0.8rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid var(--category-color);
  border-radius: 4px;
}

/* Адаптивная верстка */
@media (max-width: 768px) {
  .xnprivate-guides-content {
    grid-template-columns: 1fr; /* Меняем на одну колонку */
    gap: 1rem;
  }

  .xnprivate-guides-window {
    position: relative;
    width: 100%;
    right: 0;
    top: 0;
  }

  .terminal-window {
    margin-top: 1rem;
  }

  .terminal-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 0.8rem;
  }

  .terminal-status {
    align-self: flex-end;
  }

  .progress-line {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .progress-bar {
    width: 100%;
  }

  .progress-label,
  .progress-percentage {
    min-width: auto;
  }

  .output-line {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .output-details {
    width: 100%;
    margin-left: 2rem;
    margin-top: 0.2rem;
  }
}

/* Дополнительные медиа-запросы для очень маленьких экранов */
@media (max-width: 480px) {
  .setup-guide-preview {
    padding: 0.5rem;
  }

  .terminal-title-main {
    font-size: 1.2rem;
  }

  .terminal-content {
    padding: 0.8rem;
    min-height: 250px;
  }

  .command-line {
    font-size: 0.9rem;
  }
}

/* Контент терминала */
.terminal-content {
  padding: 1rem;
  min-height: 300px;
  position: relative;
  background: rgba(0, 0, 0, 0.95);
}

.command-line {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: var(--guides-text-primary);
  font-family: 'Share Tech Mono', monospace;
}

.prompt {
  color: #50FF50;
}

.command {
  color: var(--guides-text-primary);
}

.cursor {
  width: 8px;
  height: 15px;
  background: var(--guides-text-primary);
  margin-left: 2px;
  animation: blink 1s infinite;
}

/* Прогресс-бар */
.progress-line {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--category-color);
}

.progress-label {
  min-width: 180px;
  color: var(--guides-text-secondary);
}

.progress-bar {
  flex: 1;
  height: 20px;
  background: rgba(0, 255, 102, 0.1);
  border: 1px solid var(--category-color);
  position: relative;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--category-color),
    #50FF50
  );
  position: relative;
  transition: width 0.3s ease;
}

.progress-bar-fill::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3)
  );
  animation: progressGlow 1s infinite;
}

.progress-percentage {
  min-width: 50px;
  text-align: right;
  color: #50FF50;
}

/* Строки вывода */
.output-line {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.4rem 0;
  color: var(--guides-text-secondary);
  transition: all 0.2s ease;
}

.output-line:hover {
  background: rgba(0, 255, 102, 0.05);
  padding-left: 0.5rem;
  transform: translateX(5px);
}

.output-icon {
  color: inherit;
  font-size: 1.1rem;
}

.output-text {
  flex: 1;
}

.output-details {
  font-size: 0.8rem;
  color: var(--guides-text-dim);
  margin-left: auto;
}

.output-line.success {
  color: #50FF50;
}

.output-line.info {
  color: #00FFFF;
}

/* Анимации */
@keyframes pulse {
  0% { opacity: 1; box-shadow: 0 0 10px currentColor; }
  50% { opacity: 0.5; box-shadow: 0 0 5px currentColor; }
  100% { opacity: 1; box-shadow: 0 0 10px currentColor; }
}

@keyframes progressGlow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes scanlines {
  0% { background-position: 0 0; }
  100% { background-position: 0 100%; }
}

/* Стили для блока Performance Optimization */
.xnpriv-performance-block {
  position: relative;
  padding: 1.5rem;
  background: rgba(10, 27, 10, 0.8);
  border: 1px solid var(--priv-accent);
  min-height: 320px;
  display: grid;
  grid-template-rows: auto 1fr auto; /* Это обеспечит правильное распределение пространства */
}

/* Восстанавливаем и улучшаем стили заголовка */
.xnpriv-performance-header {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  background: rgba(0, 255, 102, 0.03);
}

.xnpriv-performance-title {
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  color: var(--priv-text-primary);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.xnpriv-performance-title::after {
  content: 'VERIFIED';
  font-size: 0.8rem;
  color: #00FF00;
  margin-left: auto;
}

.xnpriv-performance-title::before {
  content: 'PERFORMANCE_DOCS v2.1.4';
}

.xnpriv-performance-icon {
  font-family: 'Share Tech Mono', monospace;
  color: var(--priv-accent);
  font-size: 1.2rem;
}

.xnpriv-metrics-list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1rem 0;
}

.xnpriv-metric-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Share Tech Mono', monospace;
  color: var(--priv-text-primary);
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.xnpriv-metric-label {
  color: var(--priv-text-primary);
}

.xnpriv-metric-value {
  color: var(--priv-text-primary);
  font-family: 'Share Tech Mono', monospace;
  position: relative;
  padding: 0 0.5rem;
}

.xnpriv-metric-value::before {
  content: '[';
  margin-right: 0.5rem;
  opacity: 0.7;
}

.xnpriv-metric-value::after {
  content: ']';
  margin-left: 0.5rem;
  opacity: 0.7;
}

/* Стили для футера */
.xnpriv-performance-footer {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 255, 102, 0.2);
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
}

.xnpriv-footer-link {
  color: #00BFFF;
  text-decoration: none;
  font-family: 'Share Tech Mono', monospace;
}

.xnpriv-footer-stats {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  color: #00BFFF;
}

.xnpriv-footer-text {
  color: #00BFFF;
  font-family: 'Share Tech Mono', monospace;
}

/* Стили для Security Guidelines */
.xnpriv-security-block {
  position: relative;
  padding: 1.5rem;
  background: rgba(10, 27, 10, 0.8);
  border: 1px solid var(--priv-accent);
  min-height: 320px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.xnpriv-security-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.xnpriv-security-title {
  font-family: 'Share Tech Mono', monospace;
  color: var(--priv-text-primary);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.xnpriv-security-title::before {
  content: '[🔒]';
  color: var(--priv-accent);
}

.xnpriv-security-version {
  margin-left: auto;
  color: #00FF00;
  font-size: 0.8rem;
}

.xnpriv-security-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.xnpriv-security-item {
  padding: 0.8rem;
  border-left: 2px solid transparent;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
}

.xnpriv-security-item:hover {
  background: rgba(0, 255, 102, 0.05);
  border-left-color: var(--priv-accent);
  padding-left: 1.2rem;
}

.xnpriv-security-item::before {
  content: '>';
  color: var(--priv-accent);
  position: absolute;
  left: -1rem;
  opacity: 0;
  transition: all 0.3s ease;
}

.xnpriv-security-item:hover::before {
  left: 0.3rem;
  opacity: 1;
}

.xnpriv-security-level {
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.xnpriv-security-level.critical {
  color: #FF4444;
  border: 1px solid #FF4444;
}

.xnpriv-security-level.high {
  color: #FFB700;
  border: 1px solid #FFB700;
}

.xnpriv-security-level.medium {
  color: #00BFFF;
  border: 1px solid #00BFFF;
}

.xnpriv-security-details {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: var(--priv-text-secondary);
  padding-left: 1rem;
  border-left: 1px dashed var(--priv-text-dim);
  display: none;
}

.xnpriv-security-item.active .xnpriv-security-details {
  display: block;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.xnpriv-security-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.3rem;
  font-size: 0.8rem;
  color: var(--priv-text-dim);
}

.xnpriv-security-status::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--priv-accent);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
}

/* Стили для Troubleshooting Manuals */
.xnpriv-trouble-block {
  position: relative;
  padding: 1.5rem;
  background: rgba(10, 27, 10, 0.8);
  border: 1px solid var(--priv-accent);
  min-height: 320px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.xnpriv-trouble-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  background: rgba(0, 255, 102, 0.03);
}

.xnpriv-trouble-title {
  font-family: 'Share Tech Mono', monospace;
  color: var(--priv-text-primary);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.xnpriv-trouble-title::before {
  content: '[⚡]';
  color: var(--priv-accent);
}

.xnpriv-trouble-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.xnpriv-trouble-item {
  border: 1px solid rgba(0, 255, 102, 0.2);
  background: rgba(0, 255, 102, 0.02);
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.xnpriv-trouble-item:hover {
  background: rgba(0, 255, 102, 0.05);
  transform: translateX(5px);
}

.xnpriv-trouble-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.xnpriv-trouble-code {
  font-family: 'Share Tech Mono', monospace;
  color: #FF4444;
  font-size: 0.9rem;
}

.xnpriv-trouble-status {
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2px;
}

.xnpriv-trouble-status.resolved {
  color: #00FF00;
  border: 1px solid #00FF00;
}

.xnpriv-trouble-status.pending {
  color: #FFB700;
  border: 1px solid #FFB700;
}

.xnpriv-trouble-solution {
  display: none;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px dashed rgba(0, 255, 102, 0.2);
}

.xnpriv-trouble-item.active .xnpriv-trouble-solution {
  display: block;
  animation: fadeIn 0.3s ease;
}

.xnpriv-trouble-steps {
  list-style: none;
  padding: 0;
  margin: 0;
}

.xnpriv-trouble-step {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
  color: var(--priv-text-secondary);
  font-family: 'Share Tech Mono', monospace;
}

.xnpriv-trouble-step::before {
  content: '>';
  color: var(--priv-accent);
}

.xnpriv-trouble-command {
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-left: 2px solid var(--priv-accent);
  font-family: 'Share Tech Mono', monospace;
  color: #00BFFF;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Стили для кнопки guides-action-btn */
.guides-action-btn {
  font-family: 'Share Tech Mono', monospace;
  background: transparent;
  border: 1px solid var(--priv-accent);
  color: var(--priv-text-primary);
  padding: 0.8rem 1.2rem;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
    font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.guides-action-btn::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--priv-accent);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.guides-action-btn:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.guides-action-btn::after {
  content: '→';
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.guides-action-btn:hover::after {
  opacity: 1;
  transform: translateX(5px);
}

.guides-action-btn:hover {
  color: var(--priv-accent);
  text-shadow: 0 0 8px rgba(0, 255, 102, 0.3);
}

.guides-action-btn.active {
  color: var(--priv-accent);
}

.guides-action-btn.active::before {
  transform: scaleX(1);
}

/* Анимация при нажатии */
.guides-action-btn:active {
  transform: translateY(1px);
}

/* Стили для иконки, если она есть */
.guides-action-btn .icon {
  transition: transform 0.3s ease;
}

.guides-action-btn:hover .icon {
  transform: translateX(3px);
}

/* Добавляем анимацию курсора для кнопки */
.guides-action-btn .cursor {
  display: inline-block;
  width: 8px;
  height: 15px;
  background: var(--priv-accent);
  animation: blink 1s step-end infinite;
  margin-left: 0.5rem;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* Добавляем эффект свечения при фокусе */
.guides-action-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 255, 102, 0.2);
}

/* Стили для иконки внутри кнопки, если она есть */
.guides-action-btn .icon {
  color: var(--priv-accent);
  font-size: 1.1em;
  transition: transform 0.3s ease;
}

.guides-action-btn:hover .icon {
  transform: translateX(3px);
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .guides-action-btn {
    padding: 0.6rem 1.2rem;
  font-size: 0.8rem;
  }
}

/* Обновленные стили для кнопки доступа к документации */
.xnpriv-docs-link {
  font-family: 'Share Tech Mono', monospace;
  color: #00BFFF;
  text-decoration: none;
  padding: 0.5rem 1rem;
  background: rgba(0, 191, 255, 0.05);
  border: 1px solid rgba(0, 191, 255, 0.2);
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  overflow: hidden;
}

.xnpriv-docs-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 191, 255, 0.1),
    transparent
  );
  transition: left 0.5s ease;
}

.xnpriv-docs-link:hover::before {
  left: 100%;
}

.xnpriv-docs-link::after {
  content: '→';
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.xnpriv-docs-link:hover::after {
  transform: translateX(5px);
}

.xnpriv-docs-link:hover {
  background: rgba(0, 191, 255, 0.1);
  border-color: rgba(0, 191, 255, 0.4);
}

.xnpriv-join-btn {
  font-family: 'Share Tech Mono', monospace;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  background: linear-gradient(
    90deg,
    rgba(0, 255, 102, 0.1) 0%,
    rgba(0, 255, 102, 0.2) 50%,
    rgba(0, 255, 102, 0.1) 100%
  );
  border: 1px solid #00ff66;
  color: #00ff66;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  font-size: 1.1rem;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(0, 255, 102, 0.5);
  clip-path: polygon(
    10px 0,
    100% 0,
    calc(100% - 10px) 100%,
    0 100%
  );
}

.xnpriv-join-btn::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #00ff66, #00ffcc, #00ff66);
  z-index: -1;
  animation: borderGlow 3s linear infinite;
  opacity: 0.5;
}

.xnpriv-join-btn::after {
  content: '→';
  position: relative;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.xnpriv-join-btn:hover {
  background: linear-gradient(
    90deg,
    rgba(0, 255, 102, 0.2) 0%,
    rgba(0, 255, 102, 0.3) 50%,
    rgba(0, 255, 102, 0.2) 100%
  );
  transform: translateY(-2px) scale(1.02);
  box-shadow: 
    0 0 30px rgba(0, 255, 102, 0.4),
    inset 0 0 15px rgba(0, 255, 102, 0.3);
  color: #fff;
  text-shadow: 
    0 0 10px rgba(0, 255, 102, 0.8),
    0 0 20px rgba(0, 255, 102, 0.4);
  letter-spacing: 3px;
}

.xnpriv-join-btn:hover::after {
  transform: translateX(5px);
}

.xnpriv-join-btn:active {
  transform: translateY(1px) scale(0.98);
  background: rgba(0, 255, 102, 0.35);
  box-shadow: 
    0 0 15px rgba(0, 255, 102, 0.3),
    inset 0 0 10px rgba(0, 255, 102, 0.2);
}

@keyframes borderGlow {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
}

/* Добавим эффект пульсации */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 102, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 255, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 102, 0);
  }
}

.xnpriv-join-btn {
  animation: pulse 2s infinite;
}
