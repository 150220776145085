.admin-telegram {
    padding: 20px;
  }
  
  .post-form {
    background: #1a1a1a;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #888;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    background: #2a2a2a;
    border: 1px solid #333;
    border-radius: 4px;
    color: #fff;
  }
  
  .form-group textarea {
    min-height: 150px;
  }
  
  .submit-btn,
  .publish-btn {
    padding: 8px 16px;
    background: #2a2a2a;
    border: 1px solid #333;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover,
  .publish-btn:hover {
    background: #333;
  }
  
  .posts-list {
    display: grid;
    gap: 20px;
  }
  
  .post-item {
    background: #1a1a1a;
    padding: 20px;
    border-radius: 8px;
  }
  
  .post-meta {
    margin-top: 10px;
    color: #888;
    font-size: 0.9em;
  }
  
  .post-meta span {
    margin-right: 15px;
  }
  
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .create-btn {
    padding: 8px 16px;
    background: #2a2a2a;
    border: 1px solid #333;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .create-btn:hover {
    background: #333;
  }
  
  .checkbox {
    display: flex;
    align-items: center;
  }
  
  .checkbox input {
    margin-right: 8px;
    width: auto;
  }
  
  .post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .status-select {
    padding: 4px 8px;
    background: #2a2a2a;
    border: 1px solid #333;
    color: #fff;
    border-radius: 4px;
  }
  
  .post-meta {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    color: #888;
    font-size: 0.9em;
  }
  
  .publish-btn {
    padding: 8px 16px;
    background: #2a2a2a;
    border: 1px solid #333;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .publish-btn:hover {
    background: #333;
    transform: translateY(-1px);
  }
  
  .post-actions {
    display: flex;
    gap: 10px;
  }
  
  .edit-btn {
    padding: 8px 16px;
    background: #2a2a2a;
    border: 1px solid #333;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .edit-btn:hover {
    background: #333;
    transform: translateY(-1px);
  }
  
  .post-form h2 {
    margin-bottom: 20px;
    color: #fff;
  }
  
  .delete-btn {
    padding: 8px 16px;
    background: #2a2a2a;
    border: 1px solid #333;
    color: #ff4444;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .delete-btn:hover {
    background: #333;
    color: #ff6666;
    transform: translateY(-1px);
  }
  
  .telegram-editor__md-editor {
    background: #1a1a1a !important;
    border: 1px solid #333 !important;
    border-radius: 4px;
  }
  
  .telegram-editor__md-editor .w-md-editor-toolbar {
    background: #2a2a2a !important;
    border-bottom: 1px solid #333;
  }
  
  .telegram-editor__md-editor .w-md-editor-toolbar li > button {
    color: #00ff00 !important;
  }
  
  .telegram-editor__md-editor .w-md-editor-toolbar li > button:hover {
    background: #333 !important;
    color: #00ff00 !important;
  }
  
  .telegram-editor__md-editor .w-md-editor-content {
    background: #1a1a1a !important;
  }
  
  .telegram-editor__md-editor .w-md-editor-text-pre > code,
  .telegram-editor__md-editor .w-md-editor-text-input {
    color: #00ff00 !important;
    font-size: 16px !important;
    line-height: 1.6 !important;
    font-family: 'Courier New', monospace !important;
  }
  
  .telegram-editor__md-editor .w-md-editor-preview {
    background: #2a2a2a !important;
    box-shadow: inset 1px 0 0 0 #333;
  }
  
  .telegram-editor__md-editor .wmde-markdown {
    background: #2a2a2a !important;
    color: #d4d4d4 !important;
  }
  
  .telegram-editor__md-editor .wmde-markdown-color {
    color: #d4d4d4 !important;
  }
  
  .telegram-editor__md-editor .token.comment {
    color: #6a9955 !important;
  }
  
  .telegram-editor__md-editor .token.string {
    color: #ce9178 !important;
  }
  
  .telegram-editor__md-editor .token.number {
    color: #b5cea8 !important;
  }
  
  .telegram-editor__md-editor .token.keyword {
    color: #569cd6 !important;
  }
  
  .post-content {
    padding: 15px;
    background: #1a1a1a;
    border-radius: 4px;
    margin: 10px 0;
  }
  
  .post-content .wmde-markdown {
    background: transparent;
    color: #fff;
  }
  
  .telegram-editor__md-editor .w-md-editor-text-input {
    background-color: transparent !important;
    color: #fff !important;
    caret-color: #00ff00 !important;
    -webkit-text-fill-color: #fff !important;
  }
  
  .telegram-editor__md-editor .w-md-editor-text {
    -webkit-text-fill-color: #fff !important;
    background-color: #2a2a2a !important;
  }
  
  .telegram-editor__md-editor .w-md-editor-text-pre {
    color: #fff !important;
    background-color: #2a2a2a !important;
  }
  
  .telegram-editor__md-editor .w-md-editor-text-pre > code {
    color: #fff !important;
    background-color: #2a2a2a !important;
  }
  
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
    background-color: white;
  }
  
  .form-group select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .form-group input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
  }
  
  .form-group input[type="number"]:focus {
    outline: none;
    border-color: #007bff;
  }