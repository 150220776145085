:root {
  --infra-yellow: #FFC107;
  --infra-cyan: #00BCD4;
  --infra-green: #4CAF50;
  --infra-red: #f44336;
}

.xinfra-container {
  padding: 80px 40px 40px;
  max-width: 1600px;
  margin: 0 auto;
  min-height: 100vh;
}

.xinfra-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.xinfra-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
  color: var(--home-accent);
}

.xinfra-prompt {
  color: var(--infra-yellow);
}

.xinfra-command {
  color: var(--home-accent-cyan);
}

.xinfra-cursor {
  animation: blink 1s step-end infinite;
}

.xinfra-system-status {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.xinfra-status-metric {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.xinfra-metric-bar {
  width: 150px;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.xinfra-metric-fill {
  height: 100%;
  background: var(--infra-cyan);
  transition: width 0.3s ease;
}

.xinfra-metric-label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Anonymous Pro', monospace;
  text-transform: uppercase;
}

.xinfra-metric-value {
  font-family: 'Anonymous Pro', monospace;
  color: var(--home-accent-cyan);
  font-size: 1.1rem;
}

.xinfra-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2.5rem;
}

.xinfra-section-title {
  font-family: 'Anonymous Pro', monospace;
  color: var(--infra-green);
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.xinfra-section-title:before {
  content: '>';
  color: var(--infra-yellow);
}

/* Globe Styles */
.xinfra-globe-container {
  background: transparent;
  border: none;
  border-radius: 4px;
  padding: 1rem;
}

.xinfra-globe-legend {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.xinfra-legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
  color: rgba(255, 255, 255, 0.7);
}

.xinfra-legend-dot {
  width: 8px;
  height: 8px;
  background: var(--infra-yellow);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(255, 193, 7, 0.5);
}

/* Datacenter Styles */
.xinfra-datacenter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.xinfra-datacenter-card {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 1.5rem;
}

.xinfra-datacenter-card:hover {
  border-color: rgba(0, 255, 255, 0.3);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
}

.xinfra-dc-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.1);
}

.xinfra-dc-status-dot {
  width: 10px;
  height: 10px;
  background: var(--infra-green);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
}

.xinfra-dc-location {
  font-family: 'Anonymous Pro', monospace;
  color: var(--home-accent-cyan);
  font-size: 1.2rem;
  font-weight: bold;
}

.xinfra-dc-region {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-left: auto;
  padding: 0.25rem 0.75rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: 1px solid rgba(0, 255, 255, 0.1);
}

.xinfra-dc-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem 0;
}

.xinfra-dc-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid rgba(0, 255, 255, 0.05);
}

.xinfra-dc-metric .xinfra-metric-icon {
  color: var(--infra-yellow);
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
}

.xinfra-dc-metric .xinfra-metric-value {
  color: var(--home-accent-cyan);
  font-size: 1.3rem;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

.xinfra-dc-metric .xinfra-metric-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  letter-spacing: 0.05em;
}

.xinfra-dc-details {
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 255, 255, 0.1);
}

.xinfra-dc-specs {
  display: grid;
  gap: 0.75rem;
}

.xinfra-specs-title {
  color: var(--infra-yellow);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.xinfra-specs-title:before {
  content: '⚡';
  color: var(--infra-yellow);
}

.xinfra-spec-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: 1px solid rgba(0, 255, 255, 0.05);
}

.xinfra-spec-key {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
  font-family: 'Anonymous Pro', monospace;
}

.xinfra-spec-value {
  color: var(--home-accent-cyan);
  font-size: 0.9rem;
  font-family: 'Anonymous Pro', monospace;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

/* Blockchain Styles */
.xinfra-blockchain-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.xinfra-blockchain-card {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.75rem;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
}

.xinfra-blockchain-card:hover {
  border-color: rgba(0, 255, 255, 0.3);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
}

.xinfra-chain-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.1);
  margin-bottom: 1rem;
}

.xinfra-chain-icon {
  color: var(--infra-yellow);
  font-size: 1.4rem;
  text-shadow: 0 0 10px rgba(255, 193, 7, 0.5);
}

.xinfra-chain-name {
  font-family: 'Anonymous Pro', monospace;
  color: var(--home-accent-cyan);
  font-size: 1.2rem;
  font-weight: bold;
}

.xinfra-chain-status {
  margin-left: auto;
  font-size: 0.9rem;
  padding: 0.25rem 0.75rem;
  border-radius: 2px;
  font-family: 'Anonymous Pro', monospace;
  color: var(--infra-green);
  background: rgba(76, 175, 80, 0.1);
  border: 1px solid rgba(76, 175, 80, 0.2);
}

.xinfra-chain-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  padding: 1rem 0;
}

.xinfra-chain-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  border: 1px solid rgba(0, 255, 255, 0.1);
}

.xinfra-chain-metric .xinfra-metric-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  letter-spacing: 0.05em;
}

.xinfra-chain-metric .xinfra-metric-value {
  color: var(--home-accent-cyan);
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

/* Terminal Styles */
.xinfra-terminal-window {
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 255, 255, 0.15);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.xinfra-terminal-header {
  background: rgba(0, 0, 0, 0.6);
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(0, 255, 255, 0.15);
}

.xinfra-terminal-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}

.xinfra-terminal-title {
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Anonymous Pro', monospace;
}

.xinfra-terminal-content {
  padding: 1rem;
  max-height: 300px;
  overflow-y: auto;
}

.xinfra-log-entry {
  font-family: 'Anonymous Pro', monospace;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.xinfra-log-timestamp {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
}

.xinfra-log-type {
  font-size: 0.8rem;
}

.xinfra-log-type.info { color: var(--infra-cyan); }
.xinfra-log-type.success { color: var(--infra-green); }
.xinfra-log-type.warning { color: var(--infra-yellow); }
.xinfra-log-type.error { color: var(--infra-red); }

.xinfra-log-message {
  color: rgba(255, 255, 255, 0.7);
}

/* Updates Styles */
.xinfra-updates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.xinfra-update-card {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 255, 255, 0.1);
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.xinfra-update-card:hover {
  border-color: rgba(0, 255, 255, 0.3);
  transform: translateY(-2px);
}

.xinfra-update-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.xinfra-update-type {
  font-size: 0.8rem;
  color: var(--infra-cyan);
  font-family: 'Anonymous Pro', monospace;
}

.xinfra-update-date {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
}

.xinfra-update-title {
  color: var(--home-accent);
  margin-bottom: 0.5rem;
  font-family: 'Anonymous Pro', monospace;
}

.xinfra-update-description {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.5rem;
}

/* Network Activity Styles */
.xinfra-network-map {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 255, 255, 0.15);
  border-radius: 8px;
}

.xinfra-network-connection {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'Anonymous Pro', monospace;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  border: 1px solid rgba(0, 255, 255, 0.1);
}

.xinfra-connection-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 150px;
}

.xinfra-connection-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: var(--home-accent-cyan);
}

.xinfra-status-indicator {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--infra-green);
}

.xinfra-status-indicator.high_load {
  background: var(--infra-yellow);
  box-shadow: 0 0 3px var(--infra-yellow);
}

.xinfra-connection-info {
  display: flex;
  gap: 1rem;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
}

.xinfra-connection-type {
  color: var(--infra-cyan);
}

.xinfra-connection-speed {
  color: var(--infra-yellow);
}

.xinfra-connection-latency {
  color: var(--home-accent-cyan);
}

.xinfra-connection-start,
.xinfra-connection-end {
  color: var(--home-accent-cyan);
  font-size: 0.8rem;
  min-width: 60px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.xinfra-connection-line {
  flex-grow: 1;
  color: var(--infra-cyan);
  opacity: 0.2;
  text-align: center;
  letter-spacing: 0.1em;
}

@keyframes pulse {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@media (max-width: 768px) {
  .xinfra-container {
    padding: 60px 10px 10px;
  }

  .xinfra-grid {
    grid-template-columns: 1fr;
  }

  .xinfra-metric-bar {
    width: 100px;
  }
}

.ascii-globe {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: monospace;
  border-radius: 8px;
  padding: 2.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  background: transparent;
}

.globe-content {
  color: var(--home-accent-cyan);
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
  margin: 0;
  white-space: pre;
  transform: scale(1.2);
}

.globe-line {
  height: 1em;
  margin: 0;
  padding: 0;
  text-align: center;
}

.globe-point {
  position: absolute;
  width: 8px;
  height: 8px;
  background: var(--infra-yellow);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  transition: all 0.3s ease;
  z-index: 10;
}

.globe-point.active {
  opacity: 1;
  background: var(--infra-yellow);
  box-shadow: 0 0 15px var(--infra-yellow);
  animation: pulse 1.5s ease-in-out infinite;
}

.globe-container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.globe-canvas-container {
  width: 100%;
  background: transparent;
}

.globe-legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  background: rgba(0, 0, 0, 0.8);
  padding: 15px 30px;
  border-radius: 8px;
  border: 1px solid rgba(51, 255, 51, 0.3);
  font-family: monospace;
  backdrop-filter: blur(5px);
  margin-top: 30px;
}

.legend-item {
  display: flex;
  align-items: center;
  color: #33ff33;
  font-size: 16px;
  white-space: nowrap;
  animation: fadeIn 0.5s ease-out forwards;
  animation-delay: calc(var(--i) * 0.1s);
}

.legend-dot {
  width: 12px;
  height: 12px;
  background: #ff3333;
  border-radius: 50%;
  margin-right: 15px;
  box-shadow: 0 0 20px #ff0000;
  position: relative;
}

.legend-text {
  text-shadow: 0 0 8px rgba(51, 255, 51, 0.8);
  color: #4fff4f;
  transition: all 0.3s ease;
}

.legend-item:hover .legend-text {
  color: #ffffff;
  text-shadow: 0 0 15px rgba(51, 255, 51, 1);
  transform: translateX(5px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulseRing {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }
}

.section-title {
  color: #33ff33;
  font-family: monospace;
  font-size: 24px;
  margin-bottom: 30px;
  text-shadow: 0 0 10px rgba(51, 255, 51, 0.5);
  opacity: 0;
  transform: translateY(-20px);
  animation: titleSlideDown 0.5s ease-out forwards;
}

@keyframes titleSlideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
