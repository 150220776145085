.not-found-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0a0a;
  padding: 20px;
}


.error-code {
  font-size: 48px;
  margin-bottom: 20px;
  color: #ff3333;
}

.error-message {
  font-size: 24px;
  margin-bottom: 30px;
}

.cursor {
  animation: blink 1s infinite;
}

.error-details {
  margin-bottom: 30px;
}

.error-details ul {
  list-style: none;
  padding-left: 20px;
}

.error-details li {
  margin: 5px 0;
  color: #888;
}

.system-response {
  margin-bottom: 30px;
  color: #00aaff;
}

.countdown {
  color: #ffbd2e;
}

.manual-actions {
  display: flex;
  gap: 20px;
}

.action-button {
  background-color: #333;
  border: 1px solid #00ff00;
  color: #00ff00;
  padding: 10px 20px;
  cursor: pointer;
  font-family: monospace;
  transition: all 0.3s;
}

.action-button:hover {
  background-color: #00ff00;
  color: #000;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.error-terminal {
  max-width: 800px;
  animation: glowPulse 2s infinite;
}

.error-content {
  font-family: 'Anonymous Pro', monospace;
}

.error-code {
  font-size: 4rem;
  color: #ff3333;
  text-shadow: 0 0 10px rgba(255, 51, 51, 0.5);
  margin-bottom: 2rem;
  text-align: center;
  font-weight: bold;
  animation: errorBlink 1s infinite;
}

.error-message {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 51, 51, 0.3);
}

.error-line {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.error-marker {
  color: #ff3333;
  opacity: 0.8;
  font-weight: bold;
  white-space: nowrap;
}

.error-section {
  margin: 1.5rem 0;
}

.error-list {
  list-style: none;
  padding-left: 2rem;
  margin: 1rem 0;
}

.error-list li {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: rgba(0, 255, 0, 0.8);
}

.system-response {
  margin: 2rem 0;
  padding: 1rem;
  background: rgba(0, 255, 0, 0.05);
  border-radius: 4px;
}

.countdown-line {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  color: #ffbd2e;
}

.error-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

@keyframes errorBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

@keyframes glowPulse {
  0% { box-shadow: 0 0 20px rgba(255, 51, 51, 0.1); }
  50% { box-shadow: 0 0 30px rgba(255, 51, 51, 0.2); }
  100% { box-shadow: 0 0 20px rgba(255, 51, 51, 0.1); }
}

@media (max-width: 768px) {
  .error-code {
    font-size: 3rem;
  }

  .error-actions {
    flex-direction: column;
  }

  .terminal-button {
    width: 100%;
    justify-content: center;
  }
}
