.telegram-access-section {
  padding: 1rem;
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.1);
  border-radius: 4px;
}

.access-block {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  font-family: 'Anonymous Pro', monospace;
}

.access-block.locked {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: #ff0000;
}

.access-block.warning {
  background: rgba(255, 165, 0, 0.1);
  border: 1px solid rgba(255, 165, 0, 0.2);
  color: #ffa500;
}

.access-block.success {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
}

.access-block.invite {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
}

.access-block.default {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  color: #00ff00;
}

.status-icon, .info-icon, .error-icon {
  margin-right: 0.5rem;
}

.status-info {
  margin-bottom: 1rem;
}

.error-message {
  margin-top: 1rem;
  padding: 0.5rem;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 4px;
  color: #ff0000;
}

.terminal-section {
  margin-top: 2rem;
  font-family: 'Anonymous Pro', monospace;
}

.terminal-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: #00ff00;
  font-size: 1.2rem;
}

.terminal-block {
  background: rgba(0, 255, 0, 0.05);
  border: 1px solid rgba(0, 255, 0, 0.2);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.terminal-line {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00ff00;
  margin-bottom: 0.5rem;
}

.terminal-line.success {
  color: #00ff00;
}

.terminal-icon {
  opacity: 0.8;
}

.terminal-action {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.terminal-button {
  background: transparent;
  border: 1px solid #00ff00;
  color: #00ff00;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Anonymous Pro', monospace;
  text-decoration: none;
  transition: all 0.3s ease;
}

.terminal-button:hover:not(:disabled) {
  background: rgba(0, 255, 0, 0.1);
  transform: translateY(-1px);
}

.terminal-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.terminal-error {
  margin-top: 1rem;
  padding: 0.8rem;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 4px;
  color: #ff0000;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
