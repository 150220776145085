.admin-layout {
  display: flex;
  min-height: 100vh;
  background: #1a1a1a;
  color: #00ff00;
}

.admin-sidebar {
  width: 250px;
  background: #0f0f0f;
  border-right: 1px solid rgba(0, 255, 0, 0.2);
  padding: 2rem 0;
}

.admin-logo {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  font-family: monospace;
}

.admin-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.admin-nav-item {
  padding: 0.75rem 1.5rem;
  color: #00ff00;
  text-decoration: none;
  font-family: monospace;
  transition: background-color 0.2s;
}

.admin-nav-item:hover {
  background: rgba(0, 255, 0, 0.1);
}

.admin-nav-item.active {
  background: rgba(0, 255, 0, 0.15);
  border-right: 2px solid #00ff00;
}

.admin-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
}
