@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(-10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scanline {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes pulseBox {
  0% { box-shadow: 0 0 20px rgba(0, 255, 0, 0.1); }
  50% { box-shadow: 0 0 30px rgba(0, 255, 0, 0.2); }
  100% { box-shadow: 0 0 20px rgba(0, 255, 0, 0.1); }
}

@keyframes cursorBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes typeIn {
  from { width: 0; }
  to { width: 100%; }
}
