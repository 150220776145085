.ascii-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', monospace;
  z-index: 1000;
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease;
  transform: translateY(-20%);
}

.ascii-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}


.ascii-art {
  color: #00ff00;
  font-size: 20px;
  line-height: 1.2;
  text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00;
  margin: 0;
  transform-origin: center;
  transition: all 0.15s ease-out;
  letter-spacing: 1px;
  font-family: 'Courier New', monospace;
  opacity: 0.9;
  white-space: pre;
  text-align: center;
  display: inline-block;
}

.loader-text {
  color: #00ff00;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
  opacity: 0.8;
  letter-spacing: 2px;
  white-space: nowrap;
}

.system-text {
  font-weight: normal;
  letter-spacing: 2px;
  font-family: 'Courier New', monospace;
}

.bracket {
  opacity: 0.6;
}

.loading-dots {
  display: flex;
  gap: 4px;
  margin-left: 4px;
}

.dot {
  animation: blink 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: scale(0.98) translateY(-20%);
  }
  to { 
    opacity: 1;
    transform: scale(1) translateY(-20%);
  }
}

@keyframes fadeOut {
  from { 
    opacity: 1;
    transform: scale(1) translateY(-20%);
  }
  to { 
    opacity: 0;
    transform: scale(0.98) translateY(-20%);
  }
}

.ascii-loader.fade-out {
  animation: fadeOut 0.3s ease forwards;
}
  