@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/App.css */

@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

body, html, #root, .App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #000000; /* Черный фон */
  position: relative;
  font-family: 'Anonymous Pro', monospace;
}

@media (max-width: 768px) {
  body, html, #root, .App {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.ascii-canvas {
  display: block;
}

.top-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #00ff00;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  letter-spacing: 0.5rem;
}

.bottom-text {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFFFFF;
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  pointer-events: none;
  z-index: 5;
}

@media (max-width: 768px) {
  .top-text {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
  }

  .bottom-text {
    font-size: 24px;
    padding: 0 20px;
    bottom: 40px;
  }
}

@media (max-width: 480px) {
  .top-text {
    font-size: 2rem;
    letter-spacing: 0.2rem;
  }

  .bottom-text {
    font-size: 18px;
    bottom: 20px;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  padding-top: 60px;
}

.main-content {
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 2rem 1rem;
  width: 100%;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .App {
    padding-top: 80px;
  }

  .main-content {
    align-items: flex-start;
    padding-top: 1rem;
  }

  .sections-container {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }
}

.cube-wrapper {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 20;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.cube-wrapper:hover {
  opacity: 1;
}

.sections-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 100;
  position: relative;
}

@media (max-width: 768px) {
  .sections-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-top: 1rem;
  }
  
  .section-card {
    width: 100%;
    max-width: none;
  }
}

.section-card {
  flex: 1;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  background: rgba(0, 20, 0, 0.3);
  border: 1px solid rgba(0, 255, 0, 0.2);
  padding: 2rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.section-card:hover {
  background: rgba(0, 40, 0, 0.4);
  border-color: rgba(0, 255, 0, 0.4);
  transform: translateY(-5px);
}

.section-icon {
  font-size: 2rem;
  color: #00ff00;
  margin-bottom: 1rem;
}

.section-title {
  font-size: 1.5rem;
  color: #00ff00;
  margin-bottom: 1rem;
}

.section-description {
  color: rgba(0, 255, 0, 0.8);
  margin-bottom: 1.5rem;
}

.features-list {
  margin-bottom: 1.5rem;
}

.feature-item {
  color: rgba(0, 255, 0, 0.9);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-bullet {
  color: #00ff00;
}

.section-button {
  width: 100%;
  padding: 1rem;
  background: transparent;
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  cursor: pointer;
  transition: all 0.3s ease;
}

.section-button:hover {
  background: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 0.5);
}

.section-divider {
  width: 1px;
  background: linear-gradient(to bottom, #00ff00, #000000);
  margin: 0 2rem;
}

@media (max-width: 768px) {
  .sections-container {
    flex-direction: column;
    padding: 1rem;
  }

  .section-card {
    width: 100%;
    margin-bottom: 1rem;
  }

  .section-divider {
    width: 80%;
    height: 1px;
    margin: 2rem auto;
    background: linear-gradient(to right, transparent, rgba(0, 255, 0, 0.3), transparent);
  }
}

.ascii-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.BackgroundMatrix {
  z-index: 0;
}

.animated-slogan-container {
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 30;
  pointer-events: none;
}

@media (max-width: 768px) {
  .main-content {
    min-height: calc(100vh - 150px);
  }
  
  .animated-slogan-container {
    bottom: 20px;
  }
}

.section-content {
  flex-grow: 1;
}

.section-footer {
  margin-top: 2rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #000000;
}
