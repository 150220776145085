.code-block-wrapper {
  background: #121212;
  border-radius: 8px;
  overflow: hidden;
  font-family: 'Fira Code', monospace;
  transition: all 0.3s ease;
  margin: 1em 0;
}

.code-block-wrapper.hovered {
  border-color: rgba(0, 255, 0, 0.4);
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.1);
}

.code-block-header {
  background: #1a1a1a;
  padding: 8px 15px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
}

.code-block-controls {
  display: flex;
  gap: 6px;
}

.code-block-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.code-block-dot.red { background: #ff5f56; }
.code-block-dot.yellow { background: #ffbd2e; }
.code-block-dot.green { background: #27c93f; }

.code-block-title {
  color: #00ff0066;
  font-family: monospace;
  font-size: 13px;
}

.code-block-content {
  position: relative;
  display: flex;
  padding: 12px 15px;
  background: #1a1a1a;
  border-radius: 0 0 6px 6px;
}

.command-line {
  flex: 1;
  display: flex;
  align-items: center;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #666 #1a1a1a;
}

.command-line::-webkit-scrollbar {
  height: 6px;
}

.command-line::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.command-line::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 3px;
}

.prompt {
  color: #00ff00;
  margin-right: 10px;
  user-select: none;
}

.command-text {
  margin: 0;
  white-space: nowrap;
  color: #fff;
}

.copy-command-btn {
  position: sticky;
  right: 0;
  background: rgba(26, 26, 26, 0.9);
  backdrop-filter: blur(4px);
  border: 1px solid #333;
  border-radius: 4px;
  color: #00ff00;
  padding: 4px 8px;
  margin-left: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  height: fit-content;
}

.btn-icon {
  font-size: 12px;
}

.btn-text {
  font-family: 'Fira Code', monospace;
}

.copy-command-btn:hover {
  background: rgba(40, 40, 40, 0.95);
  border-color: #00ff00;
}

.copy-command-btn.copied {
  background: rgba(0, 255, 0, 0.1);
}

@media (max-width: 768px) {
  .code-block-content {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
  
  .copy-command-btn {
    margin-left: 0;
    justify-content: center;
  }
}
